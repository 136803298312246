import * as React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import NotificationViewModel from '../ViewModels/NotificationViewModel';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import authService from '../api-authorization/AuthorizeService';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

interface IProps {
    item: NotificationViewModel;
    close: () => void;
}

export default function NotificationItem(props: IProps) {
    const { item, close } = props;

    const onClick = async () => {
        const token = await authService.getAccessToken();

        fetch(`Notification/MarkAsRead?id=${item.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            method: 'POST'
        })
            .then(response => {
                close();
            })
            .catch(error => {
            });

    }

    const renderLink = React.useMemo(
        () => {
            let url = '';
            let state = {};

            switch (item.type) {
                case 'Account':
                    url = `/Accounts/Account`;
                    state = {
                        accountId: item.typeId
                    };
                    break;
                case 'Campaign':
                    url = `/Campaigns/Campaign`;
                    state = {
                        campaignId: item.typeId
                    }
                    break;
                default:
                    break;
            }

            return React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={{
                    pathname: url,
                    state: state
                }} ref={ref} {...itemProps} />
            ))
        },
        [item],
    );

    return (
        <React.Fragment>
            <Grid container style={{justifyContent: "space-between", alignItems: "center"}}>
                <Grid item>
                    <ListItem button component={renderLink}>
                        <ListItemText primary={item.message} />
                    </ListItem>
                </Grid>
                <Grid>
                    <ListItemIcon onClick={onClick}>
                        <CloseIcon />
                    </ListItemIcon>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}