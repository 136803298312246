import Typography from '@mui/material/Typography';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import ExecutiveAvailabilityTable from './ExecutiveAvailabilityTable';
import ExecutiveAvailabilityFilter from './ExecutiveAvailabiltyFilters';
import TeamAvailabilityViewModel from './ViewModels/TeamAvailabilityViewModel';
import ExecutiveAvailabilityFilterViewModel from "./ViewModels/ExecutiveAvailabilityFilterViewModel";
import authService from '../api-authorization/AuthorizeService';



export default function ExecutiveAvailabilityContainer() {

    const [teams, setTeams] = useState<TeamAvailabilityViewModel[]>([]);
    const [filters, setFilters] = useState<ExecutiveAvailabilityFilterViewModel>(new ExecutiveAvailabilityFilterViewModel());
    const [teamsList, setTeamsList] = useState<string[]>([]);


    useEffect(() => {
        initialise();

    }, []);

    const initialise = async () => {
        fetch(`DataAnalysis/GetTeamsAvailability`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setTeams(data.value);
                    setTeamsList(data.value.map(s => s.teamName));
                }
            });
    }

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    }

    const submitFilter = async () => {
        const token = await authService.getAccessToken();
        fetch('DataAnalysis/FilterAvailability', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(filters)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setTeams(data.value)
                };
            });
    }

    return (
        <Fragment>
            <Typography variant="h6">Executive Availability</Typography>
            <ExecutiveAvailabilityFilter filters={filters} handleChange={handleFilterChange} teamsList={teamsList} onFilter={submitFilter} />
            <ExecutiveAvailabilityTable teams={teams} />
        </Fragment>
    );
}