import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ChangeEvent, Fragment } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import SaleHandoverQuestionsViewModel from "./ViewModels/SaleHandoverQuestionsViewModel";

interface ISaleHandoverQuestionsProps {
    saleHandoverQuestions: SaleHandoverQuestionsViewModel;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function SaleHandoverQuestionsWizard(props: ISaleHandoverQuestionsProps) {
    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body2">Brief overview of the clients buisness</Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextValidator
                        name="overview"
                        variant="outlined"
                        fullWidth
                        value={props.saleHandoverQuestions.overview}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">Discussed goal of the campaign</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="goal"
                        variant="outlined"
                        fullWidth
                        value={props.saleHandoverQuestions.goal}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">Current LinkedIn presence: have they done outreach before?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="linkedInPresence"
                        variant="outlined"
                        fullWidth
                        value={props.saleHandoverQuestions.linkedInPresence}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">Any upsell opportunities?</Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextValidator
                        name="upsellOpportunities"
                        variant="outlined"
                        fullWidth
                        value={props.saleHandoverQuestions.upsellOpportunities}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">Any customisations to the package?</Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextValidator
                        name="packageCustomisation"
                        variant="outlined"
                        fullWidth
                        value={props.saleHandoverQuestions.packageCustomisation}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={props.handleChange}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}