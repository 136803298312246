import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";

export default function ClientDiscoveryInitial({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (

        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h5">Initial Form</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={8}>*/}
                {/*    <Typography variant="h6">Introduction: *</Typography>*/}
                {/*    <Typography variant="body1">Hi ____*/}

                {/*        How are you doing today?*/}

                {/*        Thanks for joining me on the call, like I said it will probably last around half an hour to 45 minutes depending on questions - is that alright?*/}

                {/*        So first of all, we'll confirm the details we have for you and from there we'll go through</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">A business fact-find so I can learn more about your goals</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextValidator*/}
                {/*        name="projectGoals"*/}
                {/*        value={data.projectGoals}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">Your LinkedIn page to ensure it's as optimised as it can be</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextValidator*/}
                {/*        name="linkedInUrl"*/}
                {/*        value={data.linkedInUrl}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">Your LinkedIn Advertising objective</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextValidator*/}
                {/*        name="linkedInAdvertisingObj"*/}
                {/*        value={data.linkedInAdvertisingObj}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">Your target audience</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextValidator*/}
                {/*        name="targetAudience"*/}
                {/*        value={data.targetAudience}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    />*/}
                {/*</Grid>*/}

                {/*{data.isAds &&*/}
                {/*    <>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <Typography variant="h6">The Ad Format we'll be using</Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <TextValidator*/}
                {/*                name="adFormat"*/}
                {/*                value={data.adFormat}*/}
                {/*                onChange={handleInputChange}*/}
                {/*                fullWidth*/}
                {/*                validators={['required']}*/}
                {/*                errorMessages={['This field is required']}*/}
                {/*                size="small"*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*    </>*/}
                {/*}*/}

                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">And lastly, the tracking we'll be implementing on your website.</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextValidator*/}
                {/*        name="websiteTracking"*/}
                {/*        value={data.websiteTracking}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    />*/}

                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Typography variant="h6">Briefed By</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="briefedBy"
                        value={data.briefedBy}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Client Email</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="clientEmail"
                        value={data.clientEmail}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                        type="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Client Website Url</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="clientURL"
                        value={data.clientURL}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Looked at client website</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="websiteViewed"
                        value={data.websiteViewed}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">Payment Link Completed</Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <SelectValidator*/}
                {/*        name="paymentLinkCompleted"*/}
                {/*        value={data.paymentLinkCompleted}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        fullWidth*/}
                {/*        validators={['required']}*/}
                {/*        errorMessages={['This field is required']}*/}
                {/*        size="small"*/}
                {/*    >*/}
                {/*        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>*/}
                {/*        <MenuItem key={1} value={"No"}>No</MenuItem>*/}
                {/*    </SelectValidator>*/}
                {/*</Grid>*/}

            </Grid>
        </Paper>
    );
}