import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CampaignListViewModel from './ViewModels/CampaignListViewModel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import TeamLeaderDialog from './TeamLeaderDialog';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authService from '../api-authorization/AuthorizeService';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import { PermissionCheck } from '../Contexts/PermissionCheck';
import {PermissionsContext} from '../Contexts/PermissionsContext';

interface ICampaignListProps {
    teamLeaders: DropdownViewModel[];
    campaign: CampaignListViewModel;
    roles: string | string[];
    refresh: () => void;
}

export default function CampaignTableRow(props: ICampaignListProps) {
    const id = props.campaign.id;
    const [openTLeaderDialog, setDialogOpen] = React.useState(false);
    const [openWarningDialog, setWarningOpen] = React.useState(false);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const addAccount = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts/Create',
                state: { campaignId: id }
            }}
            {...props} />
    ));

    const viewAccounts = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts',
                state: { campaignId: id }
            }}
            {...props} />
    ));

    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Client',
                state: { campaignId: id }
            }}
            {...props} />
    ));

    const openDialog = () => {
        setDialogOpen(true);
    }

    const openArchiveDialog = () => {
        setWarningOpen(true);
    }

    const handleWarningClose = () => {
        setWarningOpen(false);
    }

    const handleClose = (refresh: boolean) => {
        if (refresh) {
            props.refresh();
        }
        setDialogOpen(false);
    }

    const archiveClient = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/ArchiveCampaign?id=${props.campaign.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    props.refresh();
                    setWarningOpen(false);
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    }

    let teamLeader = <TableCell />;
    if (props.campaign.teamLeader && props.campaign.teamLeader.length > 0) {
        teamLeader = <TableCell>
            {props.campaign.teamLeader}
            <PermissionCheck permission="">
                <IconButton size="small" onClick={openDialog} style={{ marginLeft: 8 }}><Edit color="secondary" /></IconButton>
            </PermissionCheck>
        </TableCell>;
    }
    else if (checkHasPermission("")) {
        teamLeader = <TableCell><Button size="small" variant="outlined" color="secondary" onClick={openDialog}>Set Client Manager</Button></TableCell>;
    } else {
        teamLeader = <TableCell>No Team Leader</TableCell>;
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.campaign.campaignName}</TableCell>
                <TableCell>{props.campaign.campaignType}</TableCell>
                <TableCell>{props.campaign.industry}</TableCell>
                <TableCell>{new Date(props.campaign.startDate).toLocaleDateString("en-GB")}</TableCell>
                {teamLeader}
                <TableCell align="right"><Button variant="contained" style={{ textAlign: 'center' }} color="primary" size="small" component={viewButton} >View Client</Button></TableCell>
                <TableCell align="right">
                    {props.roles !== "Creator" &&
                        <Button size="small" variant="contained" color="primary" style={{ textAlign: 'center' }} component={viewAccounts}>View Accounts</Button>
                    }
                </TableCell>
                <TableCell align="right">
                    <PermissionCheck permission="Actions.CreateAccount">
                        <Button size="small" variant="contained" color="secondary" style={{ textAlign: 'center' }} component={addAccount}>Add Account</Button>
                    </PermissionCheck>
                </TableCell>
                <TableCell align="right">
                    <PermissionCheck permission="Actions.ArchiveClient">
                        <Button size="small" variant="outlined" color="secondary" style={{ textAlign: 'center' }} onClick={openArchiveDialog}>Archive Client</Button>
                    </PermissionCheck>
                </TableCell>
            </TableRow>
            <TeamLeaderDialog users={props.teamLeaders} campaignId={props.campaign.id} open={openTLeaderDialog} close={handleClose} />
            <Dialog
                open={openWarningDialog}
                onClose={handleWarningClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently archive this client and its accounts.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWarningClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={archiveClient} color="primary" autoFocus>
                        Archive
                    </Button>

                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}