import ReturnViewModel from '../ViewModels/ReturnViewModel';
import GetContactsViewModel from './ViewModels/GetContactsViewModel';
import authService from '../api-authorization/AuthorizeService';
import { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CreateContactViewModel from './ViewModels/CreateContactViewModel';
import ContactDataEntry from './ContactDataEntry';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import ContactsTable from './ContactTable';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

interface IManageContactsProps {
    clientId: number;
    permission: string;
    accountId?: number;
    close: () => void;
}

export default function ManageContacts(props: IManageContactsProps) {
    const [contacts, setContacts] = useState<GetContactsViewModel[]>([]);
    const [contact, setContact] = useState(new CreateContactViewModel());
    const [editing, setEditing] = useState(false);
    const [isAccount, setIsAccount] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);


    useEffect(() => {
        initialise();
    }, []);

    const editContact = (id: number) => {
        fetch(`Contact/GetContact?id=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setContact(data.value);
                    setEditing(true);
                }
            });
    }

    const cancelEditing = () => {
        setContact(new CreateContactViewModel());
        setEditing(false);
    }
    const initialise = () => {
        if (props.accountId) {
            GetAccountContacts();
            setIsAccount(true);
        }
        else {
            GetClientContacts();
        }
    }
    const GetAccountContacts = () => {
        fetch(`Contact/GetAccountContacts?accountId=${props.accountId}&clientId=${props.clientId}`)
            .then(response => response.json())
            .then(data => {
                let ren: ReturnViewModel = data;
                setContacts(ren.value);
            });
    }
    const GetClientContacts = () => {
        fetch(`Contact/GetClientContacts?clientId=${props.clientId}`)
            .then(response => response.json())
            .then(data => {
                let ren: ReturnViewModel = data;
                setContacts(ren.value);
            });
    }
    const updateContact = (contact) => {
        setContact(contact);
    }
    const SetAccountPrimary = (id: number) => {
        fetch(`Contact/SetAccountPrimary?contactId=${id}&accountId=${props.accountId}`)
            .then(response => response.json())
            .then(data => {
                initialise();
            });
    }
    const SetClientPrimary = (id: number) => {
        fetch(`Contact/SetClientPrimary?contactId=${id}`)
            .then(response => response.json())
            .then(data => {
                initialise();
            });
    }
    const setDelete = (id: number) => {
        setDeleteId(id)
        setDeleteModal(true);
    }
    const closeDelete = () => {
        setDeleteModal(false);
    }
    const DeleteContact = () => {
        fetch(`Contact/DeleteContact?contactId=${deleteId}`)
            .then(response => response.json())
            .then(data => {
                initialise();
                closeDelete();
            });
    }
    const submit = async () => {
        const token = await authService.getAccessToken();
        let ren = contact;
        ren.clientId = props.clientId;
        if (props.accountId) {
            ren.accountId = props.accountId;
        }
        fetch('Contact/AddContact', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(contact)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (data.success) {
                    setContact(new CreateContactViewModel())
                    setEditing(false);
                    initialise();
                };
            });
    }

    return (
        <Fragment>
            <Dialog open={deleteModal} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <ValidatorForm onSubmit={DeleteContact}>
                    <DialogTitle id="form-dialog-title">Delete Contact</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure sure you want to delete this contact?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDelete} color="secondary">Cancel</Button>
                        <Button type="submit" color="primary" >
                            Delete
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        <ValidatorForm onSubmit={submit}>
            <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ContactsTable contacts={contacts} editContact={editContact} isAccount={isAccount} setAccountPrimary={SetAccountPrimary} setClientPrimary={SetClientPrimary} deleteContact={setDelete} permission={props.permission} />
                </Grid>
                <Grid item xs={12} >
                    <ContactDataEntry contact={contact} setContact={updateContact} />
                </Grid>
                {editing ?
                    <Fragment>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" onClick={cancelEditing}>Cancel Editing</Button>
                        </Grid>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" type="submit">Save Changes</Button>
                        </Grid>
                    </Fragment>
                    :
                    <Fragment>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="outlined" onClick={props.close}>Close</Button>
                        </Grid>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" type="submit">Add Contact</Button>
                        </Grid>
                    </Fragment>
                }
            </Grid>
            </ValidatorForm>
        </Fragment>
    )
}
