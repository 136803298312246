import * as React from 'react';
import Grid from '@mui/material/Grid';
import ClientList from './ClientList';
import AccountListViewModel from './ViewModels/AccountListViewModel';
import authService from '../../api-authorization/AuthorizeService';
import ClientListViewModel from './ViewModels/ClientListViewModel';
interface IHoldingAreaProps {
    roles: string | string[];
}
export default function HoldingArea(props: IHoldingAreaProps) {
    const [pendingAccounts, setPendingAccounts] = React.useState<AccountListViewModel[]>([]);
    const [pendingClients, setPendingClients] = React.useState<ClientListViewModel[]>([]);
    React.useEffect(() => {
        const initialise = async () => {
            getData();
        }
        initialise();
    }, []);
    const getData = async () => {
        const token = await authService.getAccessToken();
        fetch('Onboarding/GetCampaignsWithoutTeamLeader', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setPendingClients(data);
            })
            .catch(error => { });
        fetch('Onboarding/GetAccountsWithoutMainExec', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setPendingAccounts(data);
            })
            .catch(error => { });
    }

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
                <ClientList roles={props.roles} pendingAccounts={pendingAccounts} pendingClients={pendingClients} refresh={getData} />
            </Grid>
        </Grid>
    );
}