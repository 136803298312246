import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { Fragment, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { ApproveRequestViewModel, GetRequestTableViewModel } from "./ViewModels/ApprovalRequestsViewModel";
import Dialog from "@mui/material/Dialog";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import authService from '../api-authorization/AuthorizeService';
import { GetUserListViewModel } from "../ViewModels/LinkedInAccountViewModel";
import UserNameMutliSelect from "../Shared/UserNameMultiSelect";
import Select from "@mui/material/Select";

export default function ApprovalRequestContainer() {
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState<GetRequestTableViewModel[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<ApproveRequestViewModel | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<GetUserListViewModel[]>([]);
    const [filterState, setFilterState] = useState("Pending");

    useEffect(() => {
        search();
    }, []);

    const search = () => {
        closeModal();
        setLoading(true);
        fetch('ApprovalRequest/GetRequestTable')
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                setRequests(data.value);
            });
    };
    const loadModal = (id: number) => () => {
        setLoading(true);
        fetch(`ApprovalRequest/GetRequest?id=${id}`)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                setSelectedRequest(data.value);
                setSelectedUsers(data.value.emails);
            });
    };
    const closeModal = () => {
        setSelectedRequest(null);
    };
    const submit = async () => {
        setLoading(true);
        var vm = selectedRequest!;
        vm.emails = selectedUsers;
        const token = await authService.getAccessToken();
        fetch('ApprovalRequest/SubmitApprovalResponse', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(vm)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    search();
                }
            })
    };
    const handleChangeState = (e) => {
        handleChange('approvalState', e.target.value);
    }
    const handleChangeNotes = (e) => {
        handleChange('approvalDetails', e.target.value);
    }
    const handleChange = (field: string, value: string) => {
        setSelectedRequest({
            ...selectedRequest!,
            [field]: value
        });
    }

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            {loading ?
                <Grid item>
                    <CircularProgress />
                </Grid>
                :
                <Fragment>
                    <Dialog open={selectedRequest !== null} maxWidth="md" fullWidth>
                        <ValidatorForm onSubmit={submit}>
                            <DialogTitle>Approval Request</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Request From"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={selectedRequest?.requestUserName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Client"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={selectedRequest?.campaignName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Category"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={selectedRequest?.category}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Request Date"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={selectedRequest?.requestTime}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Request Details"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={selectedRequest?.requestDetails}
                                        />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SelectValidator
                                            label="Accept/Reject"
                                            name="Accept/Reject"
                                            variant="outlined"
                                            fullWidth
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            value={selectedRequest?.approvalState}
                                            onChange={handleChangeState}
                                            margin="normal"
                                        >
                                            <MenuItem value="Accept">Accept</MenuItem>
                                            <MenuItem value="Reject">Reject</MenuItem>
                                            <MenuItem value="Pending">Pending</MenuItem>
                                        </SelectValidator>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <UserNameMutliSelect selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} multiSelect={true} label={"Additional Mentions"} required={false} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Details"
                                            name="details"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={selectedRequest?.approvalDetails}
                                            onChange={handleChangeNotes}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeModal} color="secondary">Cancel</Button>
                                <Button type="submit" color="primary">Submit</Button>
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>
                    <Grid item xs={6}>
                        <Typography>Approval Requests</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography textAlign="right">State Filter:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            name="state"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={filterState}
                            onChange={(e) => setFilterState(e.target.value)}
                        >
                            <MenuItem value="Accept">Accept</MenuItem>
                            <MenuItem value="Reject">Reject</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer sx={{ height: '700px' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Request From</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Request Date</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Approver</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requests.filter(f => f.approvalState == filterState).map(request =>
                                        <TableRow key={request.id} onClick={loadModal(request.id)}>
                                            <TableCell>{request.requestUserName}</TableCell>
                                            <TableCell>{request.campaignName}</TableCell>
                                            <TableCell>{request.category}</TableCell>
                                            <TableCell>{request.requestTime}</TableCell>
                                            <TableCell>{request.approvalState}</TableCell>
                                            <TableCell>{request.approvalUserName}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Fragment >
            }
        </Grid >
    );
}
