﻿import * as React from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "6px 8px"
        }
    })
);

export default function DenseTableCell(props: TableCellProps) {
    const classes = useStyles();

    const cell = props.children ?
        <TableCell {...props} classes={{
            root: classes.root
        }}>
            {props.children}
        </TableCell>
        :
        <TableCell {...props} classes={{
            root: classes.root
        }} />;

    return (
        <React.Fragment>
            {cell}
        </React.Fragment>
    );
}