import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { OpportunityViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionActions from '@mui/material/AccordionActions';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { PermissionCheck } from '../../Contexts/PermissionCheck';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        wordBreak: {
            wordBreak: 'break-all'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IListItemProps {
    lead: OpportunityViewModel;
    refresh: () => void;
}

export default function LeadListItem(props: IListItemProps) {
    const classes = useStyles();
    const { lead } = props;
    const [removing, setRemoving] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const removeLead = async () => {
        setRemoving(true);
        const token = await authService.getAccessToken();

        fetch(`Account/RemoveOpportunity?accountId=${lead.accountId}&id=${lead.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    handleClose();
                    props.refresh();
                }
                setRemoving(false);
            })
            .catch(error => {
                console.log('Lead removal error: ' + error);
                setRemoving(false);
            });
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove Lead?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This lead may have already been exported. Please remove it manually from the google sheet and copper.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={removing} onClick={removeLead} autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={lead.id + "-content"}
                    id={lead.id + "-header"}
                >
                    <Typography className={classes.heading}>{lead.name}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="caption">{new Date(lead.timestamp).toLocaleString()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Company</Typography>
                            <Typography>{lead.companyName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Size Of company</Typography>
                            <Typography>{lead.sizeOfCompany}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Industry</Typography>
                            <Typography>{lead.industry}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Link</Typography>
                            <Typography className={classes.wordBreak}>{lead.link}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Phone Number</Typography>
                            <Typography className={classes.wordBreak}>{lead.phoneNumber}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Email</Typography>
                            <Typography className={classes.wordBreak}>{lead.email}</Typography>
                        </Grid>
                        {lead.seniorityLevel &&
                            <Grid item xs={12}>
                                <Typography variant="overline">Seniority Level</Typography>
                                <Typography className={classes.wordBreak}>{lead.seniorityLevel}</Typography>
                            </Grid>
                        }
                        {lead.preferredContactMethod &&
                            <Grid item xs={12}>
                                <Typography variant="overline">Preferred Contact Method</Typography>
                                <Typography className={classes.wordBreak}>{lead.preferredContactMethod}</Typography>
                            </Grid>
                        }
                        {lead.leadSource &&
                            <Grid item xs={12}>
                                <Typography variant="overline">Lead Source</Typography>
                                <Typography className={classes.wordBreak}>{lead.leadSource}</Typography>
                            </Grid>
                        }
                        {lead.country &&
                            <Grid item xs={12}>
                                <Typography variant="overline">Country</Typography>
                                <Typography className={classes.wordBreak}>{lead.country}</Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="overline">Notes</Typography>
                            <Typography>{lead.notes}</Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <PermissionCheck permission="Actions.DeleteLeads">
                    <Button variant="outlined" size="small" color="secondary"  onClick={handleClickOpen}>
                        Remove
                        {removing && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                        </Button>
                    </PermissionCheck>
                </AccordionActions>
            </Accordion>
        </React.Fragment>
    );
}