export default class RegisterUserViewModel {
    public name: string = '';
    public email: string = '';
    public role: string = '';
}

export class UpdateUserViewModel {
    public id: string = '';
    public name: string = '';
    public email: string = '';
    public role: string = '';

    constructor(userId: string) {
        this.id = userId;
    }
}