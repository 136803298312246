import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { UserAccountListViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IListProps {
    exec: UserAccountListViewModel;
    refresh: () => void;
}

export default function ExecutiveListItem(props: IListProps) {
    const classes = useStyles();
    const { exec } = props;
    const [saving, setSaving] = React.useState(false);

    const removeExec = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Account/RemoveExecutive?id=${exec.id}&accountId=${exec.accountId}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    props.refresh();
                }
                setSaving(false);
            })
            .catch(error => {
                console.log('Remove exec error: ' + error);
                setSaving(false);
            });
    }
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${exec.id}/Avatar`} />
                            </Grid>
                            <Grid item>
                                <Typography>{exec.name}{exec.temp && " (Temp)"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Button variant="outlined" color="secondary" onClick={removeExec} disabled={saving}>
                                    Remove
                                    {saving && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}