import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccountTableRow from './AccountTableRow';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import DenseTableCell from '../../Utilities/DenseTableCell';
import { CampaignAccountListViewModel } from '../../ViewModels/CampaignViewModel';

interface IAccountTableProps {
    accounts: CampaignAccountListViewModel[];
    user:any;
}

export default function AccountTable(props: IAccountTableProps) {
    const items = props.accounts.map((item, index) =>
        <AccountTableRow key={index} account={item} user={ props.user} />
    );

    return (
        <ResponsiveTable aria-label="account list" style={{padding: 8}}>
            <TableHead>
                <TableRow>
                    <DenseTableCell>Accounts</DenseTableCell>    
                    <DenseTableCell>Executive</DenseTableCell> 
                    <DenseTableCell>Status</DenseTableCell> 
                    <DenseTableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {items}
            </TableBody>
        </ResponsiveTable>
    );
}