import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { OpportunityViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import OpportunityDialog from '../OpportunityDialog';
import LeadListItem from './LeadListItem';
import Grow from '@mui/material/Grow';
import Pagination from '@mui/material/Pagination';
interface IListProps {
    accountId: number;
    active: boolean;
    googleSheetId: string;
}

export default function LeadList(props: IListProps) {
    const [openLeadDialog, setLeadOpen] = React.useState(false);
    const [leads, setLeads] = React.useState<OpportunityViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pageMax, setPageMax] = React.useState(0);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetOpportunities?accountId=${props.accountId}&page=${page}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data) => {
                setLeads(data.rows);
                setLoaded(true);
                setPageMax((Math.trunc(data.count / 10) + (data.count % 10 > 0 ? 1 : 0)));
            })
            .catch(error => {
                console.log('Lead list error: ' + error);
                setLoaded(true);
            });
    };

    React.useEffect(() => {
        getData();
    }, [props.accountId, page]);

    const handleDialogClose = (refresh: boolean) => {
        setLeadOpen(false);

        if (refresh) {
            setPage(0);
        }
    }

    const openLead = () => {
        setLeadOpen(!openLeadDialog);
    }

    const items = leads.map((lead, index) => {
        return <LeadListItem key={index} lead={lead} refresh={getData} />;
    });

    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue - 1);
    };

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Opportunities</Typography>
                </Grid>
                {
                    props.googleSheetId != null &&
                    <Grid item>
                        <Button variant="outlined" color="secondary" target="_blank" href={"https://docs.google.com/spreadsheets/d/" + props.googleSheetId + "/edit#gid=0"}>
                            Google Sheet
                        </Button>
                    </Grid>
                }
                {props.active && loaded &&
                    <Grow in={loaded}
                        {...(loaded ? { timeout: 1000 } : {})}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={openLead}>Submit Opportunity</Button>
                        </Grid>
                    </Grow>
                }
            </Grid>
            {!loaded ? <LinearProgress /> :
                <Grow in={loaded}
                    {...(loaded ? { timeout: 1000 } : {})}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {items}
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                            <Pagination page={page + 1} count={pageMax} size="small" onChange={handlePageChange} showFirstButton showLastButton />
                        </Grid>
                    </Grid>
                </Grow>
            }
            <OpportunityDialog accountId={props.accountId} open={openLeadDialog} close={handleDialogClose} />
        </React.Fragment>
    );
}