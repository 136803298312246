import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import UserDropdown from "../Dropdowns/UserDropdown";
import DropdownViewModel from "../ViewModels/DropdownViewModel";
import PermissionTableViewModel from "./PermissionTableViewModel";
import SaveIcon from '@mui/icons-material/Save';

export default function PermissionsDashboard() {
    const [selectedUser, setSelectedUser] = useState('');
    const [users, setUsers] = useState<DropdownViewModel[]>([]);
    const [selectedUserRole, setSelectedUserRole] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [selectedUsersPermissions, setSelectedUsersPermissions] = useState<PermissionTableViewModel[]>([]);

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (selectedUser !== '') {
            getUserRole();
            getUserPermissions();
        }
        else {
            setSelectedUserRole('');
            setSelectedUsersPermissions([]);
        }
    }, [selectedUser])

    const getUserPermissions = async () => {
        const token = await authService.getAccessToken();
        fetch(`Permission/GetPermissionsTable?userId=${selectedUser}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setSelectedUsersPermissions(data);
            })
            .catch(error => {
                console.log('Users get error: ' + error);
            });
    }
    const saveUserPermissions = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`Permission/UpdateUserPermissions?userId=${selectedUser}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(selectedUsersPermissions)
        })
            .then(response => response.json())
            .then(data => {
                setSelectedUsersPermissions(data);
                setLoading(false);
            })
            .catch(error => {
                console.log('Users get error: ' + error);
                setLoading(false);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        fetch('User/GetUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Users get error: ' + error);
            });
    }

    const getUserRole = async () => {
        const token = await authService.getAccessToken();
        fetch(`User/GetUser?Id=${selectedUser}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setSelectedUserRole(data.role);
            })
            .catch(error => {
                console.log('User get error: ' + error);
            });
    }

    const handleChange = (value: number | string) => {
        setSelectedUser(value as string);
    }

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        let permss = [...selectedUsersPermissions];
        let pchange = permss.find(f => f.permission === event.target.name)!;
        pchange.overrideValue = event.target.checked ? false : null;
        pchange.changed = true;
        setSelectedUsersPermissions(permss);
    };
    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        let permss = [...selectedUsersPermissions];
        let pchange = permss.find(f => f.permission === event.target.name)!;
        pchange.overrideValue = event.target.checked;
        setSelectedUsersPermissions(permss);
        pchange.changed = true;
    };

    return (
        <Fragment>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h4">Permissions:</Typography>
                </Grid>
                <Grid item xs={2}>
                    <UserDropdown
                        users={users}
                        label="User"
                        name="user"
                        id={selectedUser}
                        onChange={handleChange}
                        required={false}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h4">Role: {selectedUserRole}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button fullWidth variant="contained" color="primary" onClick={getUserPermissions} disabled={loading}>Cancel Changes</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button fullWidth variant="contained" color="primary" onClick={saveUserPermissions} disabled={loading}>Save Changes</Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer sx={{ height: '700px' }}>
                        <Table padding="none" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Permission Name</TableCell>
                                    <TableCell>Role Access</TableCell>
                                    <TableCell>Override Access</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedUserRole == 'SuperAdmin' ?
                                    <TableRow>
                                        <TableCell>SuperAdmin</TableCell>
                                        <TableCell>-</TableCell>
                                        <TableCell>-</TableCell>
                                    </TableRow>

                                    :
                                    selectedUsersPermissions.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.permission} {row.changed && <SaveIcon color="warning" />}</TableCell>
                                            <TableCell>{row.defaultValue ? 'Allowed' : 'Denied'}</TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    name={row.permission}
                                                    checked={row.overrideValue != null}
                                                    onChange={handleCheck}
                                                />
                                                {row.overrideValue != null &&
                                                    <Fragment>
                                                        Denied <Switch
                                                            name={row.permission}
                                                            checked={row.overrideValue ?? false}
                                                            onChange={handleSwitch}
                                                        /> Allowed
                                                    </Fragment>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Fragment>
    );
}