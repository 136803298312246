import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

interface IProps {
    open: boolean;
    onSubmit: (note: string) => void;
    onClose: () => void;
}

export default function CreateInfoEditNote(props: IProps) {
    const { open, onSubmit, onClose } = props;
    const [note, setNote] = React.useState('');

    const submit = () => {
        onSubmit(note);
        setNote('');
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    }

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Add a Note</DialogTitle>
            <DialogContent>
                <TextField
                    label="Reason for change"
                    name="note"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    multiline
                    rows={4}
                    value={note}
                    onChange={handleChange}
                />
                <DialogActions>
                    <Button onClick={onClose} color="secondary">Cancel</Button>
                    <Button onClick={submit}>Add</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}