export class CustomerSatisfactionSurveyViewModel {
    public surveyId: string = '';
    public question1Score: number | null = null;
    public question1note: string = '';
    public question2Score: number | null = null;
    public question2note: string = '';
    public question3Score: number | null = null;
    public question3note: string = '';
    public question4Score: number | null = null;
    public question4note: string = '';
public notes: string = '';
    public constructor(id: string) {
        this.surveyId = id;
    }
}