import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { NoteViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Divider from '@mui/material/Divider';
import CreateNote from './CreateNote';
import Button from '@mui/material/Button';
import Flag from '@mui/icons-material/Flag';
import Grow from '@mui/material/Grow';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        notes: {
            whiteSpace: 'pre-wrap'
        }
    })
);

interface IListProps {
    accountId: number;
    clientId: number;
    checkRefreshNote: boolean;
    setCheckRefreshNote: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Notes(props: IListProps) {
    const classes = useStyles();
    const [notes, setNotes] = React.useState<NoteViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [openDialog, setDialog] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pageMax, setPageMax] = React.useState(0);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetNotes?clientId=${props.clientId}&accountId=${props.accountId}&page=${page}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setNotes(data.rows);
                setPageMax((Math.trunc(data.count / 10) + (data.count % 10 > 0 ? 1 : 0)));
                setLoaded(true);
            })
            .catch(error => {
                console.log('Note list error: ' + error);
                setLoaded(true);
            });
    };

    React.useEffect(() => {
        if ((props.clientId > 0 && props.accountId > 0) || props.checkRefreshNote) {
            if (page === 0)
                getData();

            setPage(0);
        }
        if (props.checkRefreshNote) {
            props.setCheckRefreshNote(false);
        }
    }, [props.clientId, props.accountId, props.checkRefreshNote]);

    React.useEffect(() => {
        if (props.clientId > 0 && props.accountId > 0) {
            getData();
        }
    }, [page]);

    const items = notes.map((note, index) => {
        const notesArray = note.content.split('\n');
        return (
            <Paper key={'note' + index} className={classes.paper}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">
                            {note.client &&
                                <Flag color="secondary" />
                            }
                            {note.content.startsWith("Customer Satifaction Survey Results for Week of") ?
                                <React.Fragment>{notesArray[0]}</React.Fragment>
                                :
                                <React.Fragment>{note.type}</React.Fragment>
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{new Date(note.timestamp).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">Created By: {note.author}</Typography>
                    </Grid>
                    {
                        note.subType != null &&
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{note.subType}</Typography>
                        </Grid>
                    }
                    {
                        note.applicationContent != null &&
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{note.applicationContent}</Typography>
                        </Grid>
                    }
                    {note.content.startsWith("Customer Satifaction Survey Results for Week of") ?
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{notesArray[2].slice(0, 9)}
                                <Typography variant='button'> {notesArray[2].slice(10)}</Typography>
                            </Typography>
                            <Typography className={classes.notes}>{notesArray[3].slice(0, 8)}
                                <Typography variant='button'> {notesArray[3].slice(9)}</Typography>
                            </Typography>
                            <Typography className={classes.notes}>{notesArray[4].slice(0, 14)}
                                <Typography variant='button'> {notesArray[4].slice(15)}</Typography>
                            </Typography>
                            <Typography className={classes.notes}>{notesArray[5].slice(0, 13)}
                                <Typography variant='button'> {notesArray[5].slice(14)}</Typography>
                            </Typography>
                            <Typography className={classes.notes}>  </Typography>
                            <Typography variant='button' >Comments: </Typography>
                            <Typography className={classes.notes}>{notesArray[7]}</Typography>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{note.content}</Typography>
                        </Grid>
                    }
                </Grid>
            </Paper >
        );
    });

    const handleDialog = () => {
        setDialog(!openDialog);
    }

    const closeDialog = (refresh: boolean) => {
        setDialog(false);

        if (refresh) {
            if (page === 0)
                getData();

            setPage(0);
        }
    }

    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue - 1);
    };

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Notes</Typography>
                </Grid>
                <Grow in={loaded}
                    {...(loaded ? { timeout: 1000 } : {})}>
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={handleDialog}>Add Note</Button>
                    </Grid>
                </Grow>
            </Grid>
            <Grow in={loaded} {...(loaded ? { timeout: 1000 } : {})}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Grid item xs={12} sx={{ maxHeight: 700, overflowY: 'scroll' }}>
                        {items}
                    </Grid>
                    <Grid item>
                        <Pagination page={page + 1} onChange={handlePageChange} count={pageMax} showFirstButton showLastButton />
                    </Grid>
                </Grid>
            </Grow>
            <CreateNote clientId={props.clientId} accountId={props.accountId} open={openDialog} close={closeDialog} />
        </React.Fragment>
    );
}