import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";

export default function ClientDiscoveryVelocity({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 3</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Velocity Questions:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        Please ignore if not a Velocity Client
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Process of how velocity works</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.velocityPurchase} name="velocityPurchase" onChange={handleInputChange} />} label="Purchase of email domain looking similar to your own." />
                        <FormControlLabel control={<Checkbox checked={data.velocityWarmUp} name="velocityWarmUp" onChange={handleInputChange} />} label="2 Week Warm Up Process to reach a good domain health and stop emails going into spam" />
                        <FormControlLabel control={<Checkbox checked={data.velocityForwarding} name="velocityForwarding" onChange={handleInputChange} />} label="All positive responses will be forwarded directly to your email address for you to reply to" />
                        <FormControlLabel control={<Checkbox checked={data.velocityAltEmail} name="velocityAltEmail" onChange={handleInputChange} />} label="Alternative email copy which is longer than LinkedIn and includes your email signature." />
                    </FormGroup>
                </Grid>
                <Grid>
                    <Typography variant="h6">
                        Purchase of email domain looking similar to your own.

                        2 Week Warm Up Process to reach a good domain health and stop emails going into spam

                        All positive responses will be forwarded directly to your email address for you to reply to

                        Alternative email copy which is longer than LinkedIn and includes your email signature.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What current email domain are you using?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="emailDomain"
                        value={data.emailDomain}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Anyone To Blacklist?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="blacklist"
                        value={data.blacklist}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Any Questions?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        ***NOTE FOR WHO IS BRIEFING***
                        Common objections tend to be about buying a new domain or the warm up process.

                        They can't use one of their own domains - we need to purchase a new one for the following reasons:
                        1) Monitor and Maintain domain health; stop emails hitting spam
                        2) Ensure their regular business email doesn't get clogged by the amount of outreach we're doing
                        3) Be certain is will work on our custom email software and abide by data protection laws (GDPR)

                        The 2-week warm up gives us a chance to us out the best messaging and prospecting for them before sending out to 2000 prospects, this means they get the best results as no messages go into spam from a well warmed email and every prospect is close to perfect.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="velocityQuestions"
                        value={data.velocityQuestions}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}