import * as React from 'react';
import ExecutiveDropdown from '../Dropdowns/ExecutiveDropdown';
import AccountDropdown from '../Dropdowns/AccountDropdown';
import Button from '@mui/material/Button';
import AdjustmentViewModel from '../ViewModels/AdjustmentViewModel';
import { SelectValidator, ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

interface IProps {
    setReload: (event: boolean) => void;
    open: boolean;
    setComplete: (event: boolean) => void;
    onClose: () => void;
}

export default function AdjustmentDialog(props: IProps) {
    const classes = useStyles();
    const { setReload, open, setComplete, onClose } = props;
    const thisMonth = new Date().getMonth();
    const [adjustment, setAdjustment] = React.useState(new AdjustmentViewModel());
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",]
    const lastMonth = thisMonth > 0 ? new Date().getMonth() - 1 : new Date().getMonth() + 11;
    const nextMonth = thisMonth < 11 ? new Date().getMonth() + 1 : new Date().getMonth() - 11;
    const year = new Date().getFullYear();
    const [submitting, setSubmitting] = React.useState(false);
    const [month, setMonth] = React.useState(-1);

    ValidatorForm.addValidationRule('validMonth', (value) => {
        if (value>-1) {
            return true;
        }
        else {
            return false;
        }
    });
    ValidatorForm.addValidationRule('notNone', (value) => {
        if (month !== 0) {
            return true;
        }
        else {
            return false;
        }
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdjustment({
            ...adjustment,
            [event.target.name]: event.target.value
        });
    }
    const onChangeDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ? parseInt(event.target.value, 10) : event.target.valueAsNumber
        setMonth(value);
    }

    const addAdjustment = async () => {
        setSubmitting(true);
        var today = new Date();
        var year = 0;
        if (thisMonth === 0 && month === 11) {
            year = today.getFullYear() - 1;
        } else if (thisMonth === 11 && month === 0) {
            year = today.getFullYear() + 1;
        } else {
            year = today.getFullYear();
        }
        var lastDayOfMonth = new Date(year, month! + 1, 0);
        adjustment.type = "Adjustment";
        adjustment.period = "Monthly";
        adjustment.endOfPeriodDate = lastDayOfMonth;
        if (adjustment.accountId === "") {
            adjustment.accountId = null;
        }
        fetch('Commission/AddAdjustment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(adjustment)
        })
            .then((response) => {
                if (response.ok) {
                    setSubmitting(false);
                    setComplete(true);
                    setAdjustment({
                        ...adjustment,
                        execId: null,
                        accountId: null,
                        amount: 0,
                        notes: null
                    });
                    setMonth(-1);
                    setReload(true);
                    onClose();
                }
                else {
                    setSubmitting(false);
                    onClose();
                }
            }).catch((e) => { setSubmitting(false) });
    }

    return (
        <Dialog
            open={open}
            aria-titled-by="adjustment-title"
        >
            <DialogTitle id="adjustment-title">Adjustment</DialogTitle>
            <DialogContent>
                <ValidatorForm onSubmit={addAdjustment}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                                <Grid item xs={6}>
                                    <ExecutiveDropdown id={adjustment.execId} onChange={onChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <AccountDropdown id={adjustment.execId} onChange={onChange} account={adjustment.accountId} />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectValidator
                                        type="string"
                                        label="Month"
                                        name="month"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        multiline
                                        validators={['validMonth']}
                                        errorMessages={['Required']}
                                        value={month}
                                        onChange={onChangeDropdown}>
                                        <MenuItem key={12} value={-1}>None</MenuItem>
                                        <MenuItem key={0} value={thisMonth === 0 ? 12 : thisMonth-1}>{months[lastMonth]} {thisMonth === 0 ? year - 1 : year}</MenuItem>
                                        <MenuItem key={1} value={thisMonth}>{months[thisMonth]} {year}</MenuItem>
                                        <MenuItem key={2} value={thisMonth === 11 ? 0 : thisMonth+1}>{months[nextMonth]} {thisMonth === 11 ? year + 1 : year}</MenuItem>
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidator
                                        type="number"
                                        name="amount"
                                        label="Amount"
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        value={adjustment.amount}
                                        onChange={onChange}
                                        validators={['required']}
                                        errorMessages={['This field is required.']}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="notes"
                                        margin="dense"
                                        label="Notes"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        value={adjustment.notes}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                        <Button color="primary" variant="contained" type="submit" disabled={submitting}>
                            Add Adjustment
                            {submitting && <CircularProgress color='secondary' size={24} className={classes.buttonProgress} />}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </DialogContent>
        </Dialog>
    );
}