import * as React from 'react';
import TextField from '@mui/material/TextField';
import authService from '../api-authorization/AuthorizeService';
import Autocomplete from '@mui/material/Autocomplete';

interface IDropdownProps {
    id: number;
    onChange: (value: number, name: string, client: string) => void;
    isOpportunity?: boolean;
    disabled: boolean;
}

class DropdownViewModel {
    id: number = 0;
    value: string = '';
}

export default function ClientDropdown(props: IDropdownProps) {
    const [list, setList] = React.useState<DropdownViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [value, setvalue] = React.useState('');

    React.useEffect(() => {

        const getData = async () => {
            const token = await authService.getAccessToken();

            const response = await fetch('Campaign/GetCampaignDropdown', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setList(data);

            if (props.isOpportunity && (data !== undefined && data.length > 0)) {
                props.onChange(data[0].id, "clientId", data[0].value);
            }
        }
        getData();
    }, []);

    const handleChange = (event: any, newValue: any) => {
        const client = list.find(f => f.value === newValue);
        props.onChange(client?.id ?? -1, "clientId", client?.value ?? '');
        setvalue(client?.value ?? '');
    }

    const items = list.map((item, index) => item.value);
    return (
        <Autocomplete
            id="combo-box-demo"
            disabled={props.disabled}
            options={items}
            value={value}
            onChange={handleChange}
            inputValue={search}
            onInputChange={(event: any, newInputValue: any) => {
                setSearch(newInputValue);
            }}
            renderInput={(params: any) =>
                <TextField
                    {...params}
                    disabled={props.disabled}
                    label="Clients"
                    name="clientId"
                    variant="outlined"
                    margin="normal"
                    fullWidth                    
                />
            }
        />
    );
}