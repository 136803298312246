import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CampaignListViewModel from './ViewModels/CampaignListViewModel';
import CampaignTableRow from './CampaignTableRow';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import authService from '../api-authorization/AuthorizeService';
import TableContainer from '@mui/material/TableContainer';
import { PermissionCheck } from '../Contexts/PermissionCheck';

interface ICampaignTableProps {
    campaigns: CampaignListViewModel[];
    roles: string | string[];
    refresh: () => void;
}

export default function CampaignTable(props: ICampaignTableProps) {
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => { getData(); }, []);

    const items = props.campaigns.map((item, index) =>
        <CampaignTableRow key={index} teamLeaders={users} campaign={item} roles={props.roles} refresh={props.refresh} />
    );

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }

    return (
        <TableContainer style={{ maxHeight: "75vh" }}>
            <Table aria-label="campaign list" stickyHeader >
                <TableHead >
                    <TableRow>
                        <TableCell>Client</TableCell>
                        <TableCell>Client Type</TableCell>
                        <TableCell>Client Industry</TableCell>
                        <TableCell>Onboarded Date</TableCell>
                        <TableCell>Client Manager</TableCell>
                        <TableCell />
                        <TableCell />
                        <PermissionCheck permission="Actions.CreateAccount">
                            <TableCell />
                        </PermissionCheck>
                        <PermissionCheck permission="Actions.ArchiveClient">
                            <TableCell />
                        </PermissionCheck>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </Table>
        </TableContainer>
    );
}