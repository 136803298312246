﻿import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { InMailViewModel } from '../ViewModels/LinkedInAccountViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IProps {
    accountId: number;
    pageId?: number;
    date: Date;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function FreeInMailDialog(props: IProps) {
    const classes = useStyles();
    const [page, setPage] = React.useState<InMailViewModel>({ id: props?.pageId, accountId: props.accountId, date: props.date, freeInMails: 0, paidInMails: 0 });
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage({
            ...page,
            [event.target.name]: event.target.valueAsNumber || event.target.value
        });
    }

    const onClose = () => {
        if (!submitting) {
            setPage({ id: props.pageId, accountId: props.accountId, date: props.date, freeInMails: 0, paidInMails:0 });
            props.close(false);
        }
    }

    const submit = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();
        const newPage = page;
        newPage.date = props.date;
        newPage.id = props.pageId;

        fetch('Account/CreateFreeInmail', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newPage)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                props.close(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <ValidatorForm onSubmit={submit}>
                <DialogTitle id="form-dialog-title">Log Page Invite</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Log your Free InMails on this account for {new Date(props.date).toLocaleDateString("en-GB")}.
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Free InMails"
                                type="number"
                                name="freeInMails"
                                value={page.freeInMails}
                                onChange={onChange}
                                validators={['required', 'isNumber', 'minNumber: 0']}
                                errorMessages={['Required', 'Must be a positive number', 'Must be a positive number']}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                    <Button type="submit" color="primary" disabled={submitting}>
                        Submit
                    {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}