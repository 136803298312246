import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import GetRenewalsViewModel from './ViewModels/GetRenewalsViewModel';
import RenewalsTableRow from './RenewalsTableRow';

interface IRenewalsTable {
    renewals: GetRenewalsViewModel[]
    editRenewal: (id: string) => void;
}

export default function RenewalsTable(props: IRenewalsTable) {
    const items = props.renewals.map((item, index) =>
        <RenewalsTableRow key={item.id} renewal={item} editRenewal={props.editRenewal} />
    );

    return (
        <TableContainer >
            <Table aria-label="campaign list" stickyHeader >
                <TableHead >
                    <TableRow>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Renewal Date</TableCell>
                        <TableCell>Contract Length</TableCell>
                        <TableCell>Package</TableCell>
                        <TableCell>Price Offered</TableCell>
                        <TableCell>Contract Value</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Sales Person</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </Table>
        </TableContainer>
    )
}