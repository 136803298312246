import { Fragment, useContext } from "react";
import { PermissionsContext } from "./PermissionsContext";

type PermissionCheckProps = { children: any, permission?: string }
export function PermissionCheck(props: PermissionCheckProps) {
    const { checkHasPermission } = useContext(PermissionsContext);

    return (
        checkHasPermission(props.permission) ?
            props.children :
            <Fragment />
    )
}