import ReturnViewModel from '../ViewModels/ReturnViewModel';
import GetRenewalsViewModel from './ViewModels/GetRenewalsViewModel';
import RenewalsTable from './RenewalsTable';
import authService from '../api-authorization/AuthorizeService';
import { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CreateRenewalViewModel from './ViewModels/CreateRenewalViewModel';
import RenewalDataEntry from './RenewalDataEntry';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';

interface IManageRenewalsProps {
    accountId: number;
    close: () => void;
    refreshAccount: () => void;
}

export default function ManageRenewals(props: IManageRenewalsProps) {
    const [renewals, setRenewals] = useState<GetRenewalsViewModel[]>([]);
    const [renewal, setRenewal] = useState(new CreateRenewalViewModel());
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        initialise();
    }, []);

    const editRenewal = (id: string) => {
        fetch(`Renewal/GetRenewal?id=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setRenewal(data.value);
                    setEditing(true);
                }
            });
    }

    const cancelEditing = () => {
        setRenewal(new CreateRenewalViewModel());
        setEditing(false);
    }

    const initialise = () => {
        fetch(`Renewal/GetRenewalsForAccount?accountId=${props.accountId}`)
            .then(response => response.json())
            .then(data => {
                let ren: ReturnViewModel = data;
                setRenewals(ren.value);
            });
    }

    const updateRenewal = (renewal) => {
        setRenewal(renewal);
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        let ren = renewal;
        ren.accountId = props.accountId;
        fetch('Renewal/AddRenewalToAccount', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(renewal)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (data.success) {
                    setRenewal(new CreateRenewalViewModel())
                    setEditing(false);
                    initialise();
                    props.refreshAccount();
                };
            });
    }

    return (
        <ValidatorForm onSubmit={submit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <RenewalsTable renewals={renewals} editRenewal={editRenewal}  />
                </Grid>
                <Grid item xs={12} >
                    <RenewalDataEntry renewal={renewal} setRenewal={updateRenewal} />
                </Grid>
                {editing ?
                    <Fragment>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" onClick={cancelEditing}>Cancel Editing</Button>
                        </Grid>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" type="submit">Save Changes</Button>
                        </Grid>
                    </Fragment>
                    :
                    <Fragment>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="outlined" onClick={props.close}>Close</Button>
                        </Grid>
                        <Grid item xs={6} >
                            <Button fullWidth color="primary" variant="contained" type="submit">Report Renewal</Button>
                        </Grid>
                    </Fragment>
                }
            </Grid>
        </ValidatorForm>
    )
}
