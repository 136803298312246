import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { TextValidator } from "react-material-ui-form-validator";
import { IClientDiscoveryFormTabProps } from "../ClientDiscoveryForm";

export default function ClientDiscoveryAds({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 2 - Ads</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        As you can imagine the better your LinkedIn Company Page is, the more likely you are to generate new business on the platform.

                        As it says here 30% more views go to pages with complete information so I did a quick review before our call.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Linkedin Page Feedback: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="linkedInPageFeedback"
                        value={data.linkedInPageFeedback}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}