import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { UpdateUserViewModel } from '../ViewModels/RegisterUserViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { useLocation, useHistory } from 'react-router';
import Alert from '../Utilities/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    }),
);

interface LocationState {
    userId: string;
}

export default function UpdateUser() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { userId } = location.state || {
        userId: ''
    };

    const [changes, setChanges] = React.useState<UpdateUserViewModel>(new UpdateUserViewModel(userId));
    const [loaded, setLoaded] = React.useState(false);
    const [roles, setRoles] = React.useState<string[]>([]);
    const [showAlert, setShow] = React.useState(false);
    const [alert, setAlert] = React.useState(<React.Fragment />);
    const [updating, setUpdating] = React.useState(false);

    React.useEffect(() => {
        const getData = async () => {
            const token = await authService.getAccessToken();

            const userResponse = await fetch('User/GetUser?id=' + userId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const userData = await userResponse.json();
            setChanges(userData);

            const response = await fetch('User/GetRoles', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setRoles(data);
            setLoaded(true);
        }
        getData();
    }, [userId]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const openAlert = (message: string, error: boolean) => {
        setAlert(
            <Alert onClose={handleClose} severity={error ? "error" : "success"}>
                {message}
            </Alert>
        );
        setShow(true);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChanges({
            ...changes,
            [event.target.name]: event.target.value
        });
    }


    const updateName = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`User/UpdateName?id=${changes.id}&name=${changes.name}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User updated successfully", false);
                    setUpdating(false);
                } else {
                    openAlert("Failed to update user, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to update user, please try again.", true);
                setUpdating(false);
            });
    }

    const updateEmail = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`User/UpdateEmail?id=${changes.id}&email=${changes.email}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User updated successfully", false);
                    setUpdating(false);
                } else {
                    openAlert("Failed to update user, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to update user, please try again.", true);
                setUpdating(false);
            });
    }

    const updateRole = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`User/UpdateRole?id=${changes.id}&role=${changes.role}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User updated successfully", false);
                    setUpdating(false);
                } else {
                    openAlert("Failed to update user, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to update user, please try again.", true);
                setUpdating(false);
            });
    }

    const items = roles.map((item, index) =>
        <MenuItem key={index} value={item}>{item}</MenuItem>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h2" gutterBottom>Update User</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={history.goBack}>Back</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                    <ValidatorForm onSubmit={updateName}>
                        <TextValidator
                            fullWidth
                            label="Name"
                            name="name"
                            variant="outlined"
                            margin="normal"
                            value={changes.name}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                        <Button fullWidth variant="contained" color="primary" type="submit" disabled={updating}>Update</Button>
                    </ValidatorForm>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                    <ValidatorForm onSubmit={updateEmail}>
                        <TextValidator
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type="email"
                            label="Email"
                            name="email"
                            value={changes.email}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Invalid email format']}
                            onChange={onChange}
                        />
                        <Button fullWidth variant="contained" color="primary" type="submit" disabled={updating}>Update</Button>
                    </ValidatorForm>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper className={classes.paper}>
                    <ValidatorForm onSubmit={updateRole}>
                        <SelectValidator
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Role"
                            name="role"
                            value={changes.role}
                            onChange={onChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            InputProps={{
                                disabled: !loaded,
                                startAdornment: (
                                    <React.Fragment>
                                        {loaded ? null : <CircularProgress size={20} />}
                                    </React.Fragment>
                                )
                            }}>
                            {items}
                        </SelectValidator>
                        <Button fullWidth variant="contained" color="primary" type="submit" disabled={updating}>Update</Button>
                    </ValidatorForm>
                </Paper>
            </Grid>
            {showAlert &&
                <Grid item xs={12}>
                    {alert}
                </Grid>
            }
        </Grid>
    );
}