import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { SortReportViewModel } from '../ViewModels/ReportViewModel';
import ReportTableRow from './ReportTableRow';
import ResponsiveTable from '../Utilities/ResponsiveTable';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof SortReportViewModel;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Account' },
    { id: 'campaignName', numeric: false, disablePadding: true, label: 'Client' },
    { id: 'packageName', numeric: false, disablePadding: true, label: 'Package' },
    { id: 'country', numeric: false, disablePadding: true, label: 'Country' },
    { id: 'monthlyPrice', numeric: true, disablePadding: true, label: 'Monthly Price' },
    { id: 'connections', numeric: true, disablePadding: true, label: 'Connections' },
    { id: 'messages', numeric: true, disablePadding: true, label: 'Messages' },
    { id: 'acceptanceRate', numeric: true, disablePadding: true, label: 'Acceptance Rate' },
    { id: 'opportunitiesEmail', numeric: true, disablePadding: true, label: 'Email Ops.' },
    { id: 'opportunitiesPhone', numeric: true, disablePadding: true, label: 'Phone Ops.' },
    { id: 'followUps', numeric: true, disablePadding: true, label: 'Follow Ups' },
    { id: 'pageInvites', numeric: true, disablePadding: true, label: 'Page Invites' },
    { id: 'freeInMails', numeric: true, disablePadding: true, label: 'Open profile InMails' },
    { id: 'paidInMails', numeric: true, disablePadding: true, label: 'InMail Credit' }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        }
    })
);

interface ITableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof SortReportViewModel) => void;
    order: Order;
    orderBy: string;
    isWeekly: boolean;
}

function SortTableHead(props: ITableProps) {
    const { classes, order, orderBy, onRequestSort, isWeekly } = props;
    const createSortHandler = (property: keyof SortReportViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {
                    isWeekly &&
                    <TableCell
                        key='targetMet'
                        align='right'
                        padding='none'
                        sortDirection={orderBy === 'targetMet' ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === 'targetMet'}
                            direction={orderBy === 'targetMet' ? order : 'asc'}
                            onClick={createSortHandler('targetMet')}
                        >
                            {'Target Met'}
                            {orderBy === 'targetMet' ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>

                }
            </TableRow>
        </TableHead>
    );
}

interface IReportProps {
    accounts: SortReportViewModel[];
    isWeekly?: boolean;
}

export default function ReportTable(props: IReportProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof SortReportViewModel>('name');

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SortReportViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const connSum = props.accounts.map(({ connections }) => connections).reduce((sum, i) => sum + i, 0);
    const priceSum = props.accounts.map(({ monthlyPrice }) => monthlyPrice).reduce((sum, i) => sum + i, 0);
    const messSum = props.accounts.map(({ messages }) => messages).reduce((sum, i) => sum + i, 0);
    const oppSumEmail = props.accounts.map(({ opportunitiesEmail }) => opportunitiesEmail).reduce((sum, i) => sum + i, 0);
    const oppSumPhone = props.accounts.map(({ opportunitiesPhone }) => opportunitiesPhone).reduce((sum, i) => sum + i, 0);
    const rateSum = messSum === 0 ? 0 : Math.round((messSum / connSum * 100) * 100) / 100;
    const followSum = props.accounts.map(({ followUps }) => followUps).reduce((sum, i) => sum + i, 0);
    const inviteSum = props.accounts.map(({ pageInvites }) => pageInvites).reduce((sum, i) => sum + i, 0);
    const freeIMSum = props.accounts.map(({ freeInMails }) => freeInMails).reduce((sum, i) => sum + i, 0);
    const paidIMSum = props.accounts.map(({ paidInMails }) => paidInMails).reduce((sum, i) => sum + i, 0);
    const targetSum = props.accounts.map(({ targetMet }) => targetMet).reduce((sum, i) => sum + i, 0);


    return (
        <ResponsiveTable
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="report table"
        >
            <SortTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                isWeekly={props.isWeekly ? true:false}
            />
            <TableBody>
                {props.accounts && stableSort(props.accounts, getComparator(order, orderBy))
                    .map((row: SortReportViewModel) => {
                        return (<ReportTableRow key={row.id} account={row} isWeekly={props.isWeekly ? true : false} />);
                    })
                }
                <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell padding='none'>Summary</TableCell>
                    <TableCell padding='none' align="right">£{priceSum}</TableCell>
                    <TableCell padding='none' align="right">{connSum}</TableCell>
                    <TableCell padding='none' align="right">{messSum}</TableCell>
                    <TableCell padding='none' align="right">{rateSum}%</TableCell>
                    <TableCell padding='none' align="right">{oppSumEmail}</TableCell>
                    <TableCell padding='none' align="right">{oppSumPhone}</TableCell>
                    <TableCell padding='none' align="right">{followSum}</TableCell>
                    <TableCell padding='none' align="right">{inviteSum}</TableCell>
                    <TableCell padding='none' align="right">{freeIMSum}</TableCell>
                    <TableCell padding='none' align="right">{paidIMSum}</TableCell>
                    {props.isWeekly && < TableCell padding='none' align="right">{targetSum}/{props.accounts.length}</TableCell>}
                </TableRow>
            </TableBody>
        </ResponsiveTable>
    );
}
