import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CommissionGroupViewModel from './ViewModels/CommissionGroupViewModel';
import Switch from '@mui/material/Switch';

interface IProps {
    commissionGroup: CommissionGroupViewModel;
    editCommissionGroup: (id: number) => void;
    editActive: (id: number, active: boolean)=>void
}

export default function CommissionGroupTableRow(props: IProps) {


    const edit = () => {
        props.editCommissionGroup(props.commissionGroup.id);
    }
    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.editActive(props.commissionGroup.id, event.target.checked);
    }

    return (
        <TableRow >
            <TableCell>{props.commissionGroup.groupName}</TableCell>
            <TableCell>£{props.commissionGroup.performanceBonus.toFixed(2)}</TableCell>
            <TableCell>£{props.commissionGroup.retentionBonus.toFixed(2)}</TableCell>
            <TableCell>£{props.commissionGroup.lossBonus.toFixed(2)}</TableCell>
            <TableCell>£{props.commissionGroup.gainBonus.toFixed(2)}</TableCell>
            <TableCell>£{props.commissionGroup.billingTarget?.toFixed(2) ?? '-'}</TableCell>
            <TableCell>£{props.commissionGroup.renewalsTarget?.toFixed(2) ?? '-'}</TableCell>
            <TableCell>
                <Switch checked={props.commissionGroup.active} onChange={handleActiveChange} name="active" />
            </TableCell>
            <TableCell>
                <IconButton color="primary" onClick={edit}><EditIcon /></IconButton>
            </TableCell>
        </TableRow>
    );
}