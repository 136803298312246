export default class CreateContactViewModel {
    public id: number| null = null;
    public accountId: number | null = null;
    public clientId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public phone: string = '';
    public jobTitle: string = '';
    //public constructor(clientId: number, accountId: number) {
    //    this.clientId = clientId;
    //    this.accountId = accountId;
    //}
    //public constructor(clientId: number, firstName: string) {
    //    this.clientId = clientId;
    //}
}