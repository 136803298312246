import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationViewModel from '../ViewModels/NotificationViewModel';
import authService from '../api-authorization/AuthorizeService';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import NotificationItem from './NotificationItem';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import MarkAllViewModel from '../ViewModels/MarkAllViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: 1300,
            [theme.breakpoints.down('md')]: {
                top: '10px !important'
            }
        },
        button: {
            margin: "5px 5px 5px 5px",
            width: "97%"
        }
    }),
);

export default function Notifications() {
    const classes = useStyles();
    const [data, setData] = React.useState<NotificationViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [connection, setConnection] = React.useState<null | HubConnection>(null);

    React.useEffect(() => {
        getNotifications();
        getConnection();
    }, []);

    React.useEffect(() => {
        if (connection) {
            try {
                connection.start()
                    .then((result: any) => {
                        connection.on("NewAccountNotification", (message: any) => {
                            console.log("I get in here");
                            getNotifications();
                        });
                    })
                    .then((result: any) => {
                        connection.on("NewCampaignNotification", (message: any) => {
                            getNotifications();
                        });
                    })
                    .catch((error: any) => {
                        console.log('SignalR issue: ' + error);
                    });
            } catch (error) { }
        }
    }, [connection]);

    const getToken = async (): Promise<string> => {
        const token = await authService.getAccessToken();
        return token ?? '';
    }

    const getConnection = async () => {
        try {
            const newConnection = new HubConnectionBuilder()
                .withUrl('/hub/notifications', {
                    accessTokenFactory: () => (getToken())
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();

            setConnection(newConnection);
        } catch (error) {
        }
    }

    const getNotifications = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Notification/GetNotifications`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refresh = () => {
        handleClose();
        getNotifications();
    }

    const markAllAsRead = async () => {
        const token = await authService.getAccessToken();
        fetch(`Notification/MarkAllAsRead`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify(new MarkAllViewModel(data))
        }).then(response => {
            refresh();
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen} disabled={loading || data.length === 0} style={{ color: "white" }}>
                <Badge badgeContent={data.length}>
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <Popover
                anchorEl={anchorEl}
                id='menu'
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.popper}
                onClose={handleClose}
            >
                <Button onClick={markAllAsRead} className={classes.button} fullWidth color="secondary" variant="contained">Clear All Notifications</Button>
                <MenuList>
                    {data.map((item, index) =>
                        <div key={item.id}>
                            <NotificationItem item={item} close={refresh} />
                            {index < data.length - 1 && <Divider light variant="middle" component="li" />}
                        </div>
                    )}
                </MenuList>
            </Popover>

        </React.Fragment>
    );
}