import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import { Button, TextField, Grid } from '@mui/material';
import Dropzone from "react-dropzone";
import DeleteIcon from '@mui/icons-material/Delete';
import FileDataViewModel from '../../ViewModels/FileDataViewModel';

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    fileInput: {
        textAlign: 'center',
        border: '1px dashed grey',
        backgroundColor: 'lightgrey',
        padding: '30px 0px'
    }
});

interface IFileInputProps {
    files: File[];
    fileData: FileDataViewModel[];
    addFile: (fileData: FileDataViewModel[], file: File[]) => void;
    removeFile: (fileData: FileDataViewModel) => void;
    submitting: boolean;
}

interface IFileInputState {
    fileData: FileDataViewModel[];
    file: File[];
    fileTypes: string[];
    clearFilesKey: number; // necessary to force rerender and clear of file in dropzone

}

class MultiFileInput extends React.Component<IFileInputProps & WithStyles<typeof styles>, IFileInputState> {
    constructor(props: any) {
        super(props);

        this.state = {
            fileData: [],
            file: [],
            fileTypes: [],
            clearFilesKey: 0
        };
    }

    public componentDidMount() { }

    public render() {
        const onDrop = (currFile: any) => {
            const newFileData = this.state.fileData;
            const newFile = this.state.file;
            for (var i = 0; i < currFile.length; i++) {
                let currFileData = new FileDataViewModel();
                currFileData.fileName = currFile[i].name;
                newFileData.push(currFileData);
                newFile.push(currFile[i]);

            }
            this.setState({
                fileData: newFileData,
                file: newFile
            });
        }

        const fileNames = this.state.fileData.map(item => <React.Fragment><Grid item xs={9}>
            <TextField
                label="File Name"
                variant="outlined"
                margin="dense"
                fullWidth
                value={item.fileName}
                name="fileName"
                onChange={this.onChange}
            />
        </Grid>
            <Grid item xs={3}>
                <Button fullWidth variant="contained" color="primary" onClick={e => this.removeFile(item)}>
                    <DeleteIcon />
                </Button>
            </Grid >
        </React.Fragment >);
        return (
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                            <p className={this.props.classes.fileInput}>Drop the files here ...</p>
                                            :
                                            <p className={this.props.classes.fileInput}>Drag 'n' drop some files here, or click to select files</p>
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Grid>
                {
                    fileNames
                }
                <Grid item xs={12}>
                    <Button disabled={this.state.file === undefined || this.props.submitting} onClick={this.addFile} fullWidth variant="contained" color="primary">Submit</Button>
                </Grid>
            </Grid>
        );
    }

    private addFile = () => {
        this.props.addFile(this.state.fileData, this.state.file);

        this.setState({ fileData: [], clearFilesKey: this.state.clearFilesKey + 1 });
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setValue(e.target.name, e.target.value);
    }

    private setValue = (key: string, value: any) => {
        //this.setState({ fileData: { ...this.state.fileData, [key]: value } as any });
    }
    private removeFile = (file: FileDataViewModel) => {
        let files = this.state.file;
        let filesData = this.state.fileData;
        const dataindex = filesData.indexOf(file);
        if (dataindex > -1) {
            filesData.splice(dataindex, 1);
            files.splice(dataindex, 1);
        }
        this.setState({ file: files, fileData: filesData });
    }
}

export default withStyles(styles)(MultiFileInput);