import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import DeleteIcon from '@mui/icons-material/Delete';
interface ICategoryListProps {
    category: DropdownViewModel;
    deleteCategory: (id:string | number) => void;
}

export default function CategoryTableRow(props: ICategoryListProps) {

    const deleteCat = () => {
        props.deleteCategory(props.category.id);
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.category.value}</TableCell>
                <TableCell>
                    <IconButton size="small" onClick={deleteCat} style={{ marginLeft: 8 }}><DeleteIcon color="secondary" /></IconButton>
                </TableCell>
            </TableRow>


        </React.Fragment>
    );
}