export default class ClientCatchUpFormViewModel{
    public campaignName = '';
    public package = '';
    public complete = false;
    public accountId = 0;
    public accountName = '';

    public managerPresent = '';

    public introduction = '';
    public synopsis = '';
    public leadUpdates = '';
    public pastWeekLeads = '';
    public leadQualityCheckIn = '';
    public leadQuality = 10;
    public leadQuantity = '';
    public velocityAccount = '';

    public emailWarmUp = '';
    public emailLive = '';
    public leadUpdateVelocity = '';
    public leadQualityVelocity = 10;

    public communication = '';
    public concerns = '';
    public roundUp = '';
    public nextCall: string = '';
    public css = '';
    public extraNotes = '';
    public followUpEmail = '';
    
}