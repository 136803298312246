import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { TextValidator } from "react-material-ui-form-validator";
import { IClientDiscoveryFormTabProps } from "../ClientDiscoveryForm";

export default function ClientDiscoveryAds2({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 1 - Ads</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Fantastic! So now I've learnt a little bit more about the business it would be great to go over who we are going to target with this Ad Campaign. In my opinion, this is probably the most important part of the call as your audience is key to generating results so I'll be looking for as much detail as possible. </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Location: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Which locations would you like to target?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="targetLocations"
                        value={data.targetLocations}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Job Title *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">When you are selling your product or service, which job titles are you usually speaking with?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="targetJobTitle"
                        value={data.targetJobTitle}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Experience *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Do you have any preference on how long they've been doing this for?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="experienceLength"
                        value={data.experienceLength}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Company Size: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">How big are the company's you're looking to work with in terms of headcount?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="companySize"
                        value={data.companySize}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">On ads we can add in some other filter, how would you like to target on the following:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Company Annual Turnover *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Any preference for the company's annual turnover?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="companyTurnOver"
                        value={data.companyTurnOver}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Education: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">What about the individuals education, would they have a degree in anything in particular or a certain field of study?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="education"
                        value={data.education}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Skills: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">Would you like to prospect to be skilled in anything in particular?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="skills"
                        value={data.skills}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Hobbies or Interests: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">LinkedIn learns users' interests and hobbies by the content they engage with online - do you have a preference for what they may be interested in?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="hobbiesAndInterests"
                        value={data.hobbiesAndInterests}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Age or Gender: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">I doubt it, but in terms or age and gender do you have any preference for this?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="ageAndGender"
                        value={data.ageAndGender}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                {data.isAds && (!data.isBoost && !data.isContent) &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">Top 3 Competitors</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "And on the topic of companies to exclude, who would you say are your top 3 competitors and how do you differ from them?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="competitors"
                                value={data.competitors}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">So when you look at the current clients you have, what job title are you usually speaking with?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Is there only ever one job title? Or do they speak to a few?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="targetJobTitle"
                                value={data.targetJobTitle}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">What would be your dream client?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="dreamClient"
                                value={data.dreamClient}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                    </>
                }
            </Grid>
        </Paper>
    );
}