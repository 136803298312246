import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ApprovalIcon from '@mui/icons-material/Approval';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import ApprovalRequestViewModel from './ViewModels/ApprovalRequestsViewModel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ClientDropdown from '../Dropdowns/ClientDropdown';
import authService from '../api-authorization/AuthorizeService';
import UserNameMutliSelect from '../Shared/UserNameMultiSelect';
import { GetUserListViewModel } from '../ViewModels/LinkedInAccountViewModel';

class DropdownViewModel {
    id: number = 0;
    value: string = '';
}
export default function ApprovalRequests() {
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [teamName, setTeamName] = React.useState("");
    const [client, setClient] = React.useState(0);
    const [categoryList, setCategoryList] = React.useState<DropdownViewModel[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<GetUserListViewModel[]>([]);

    const [submitting, setSubmitting] = React.useState(false);
    const [apvm, setApvm] = React.useState<ApprovalRequestViewModel>(new ApprovalRequestViewModel);

    React.useEffect(() => {
        init();
    }, []);

    const init = () => {
        fetch('ApprovalRequest/GetUserName')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setName(data.value);
                }
            });
        fetch('ApprovalRequest/GetTeam')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setTeamName(data.value);
                }
            });
        fetch('ApprovalRequest/GetRequestCategories')
            .then(response => response.json())
            .then(data => {
                console.log("Approval request");
                console.log(data);
                if (data.success) {
                    setCategoryList(data.value);
                    setLoading(false);
                }
            });
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submit = async () => {
        setSubmitting(true);
        var vm = apvm;
        vm.emails = selectedUsers;
        const token = await authService.getAccessToken();
        fetch('ApprovalRequest/SubmitApprovalRequest', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(vm)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSubmitting(false);
                    setOpen(false);
                }
            })
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApvm({
            ...apvm,
            [event.target.name]: event.target.value
        });
    }
    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const catId = parseInt(event.target.value);
        setApvm({
            ...apvm,
            requestCategoryId: catId
        });
    }
    const handleClientChange = (value: number, name: string, client: string) => {
        setApvm({
            ...apvm,
            clientName: client
        });
        setClient(value);
    }
    const listItems = categoryList.map((item) =>
        <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
    );
    return (
        loading ?
            <React.Fragment />
            :
            <React.Fragment>
                <IconButton onClick={handleOpen} style={{ color: "white" }}>
                    <ApprovalIcon />
                </IconButton>
                <Dialog open={open} maxWidth="md" fullWidth>
                    <ValidatorForm onSubmit={submit}>
                        <DialogTitle id="form-dialog-title">Approval Request</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Submitted By"
                                        name="name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={name + ' - ' + teamName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UserNameMutliSelect selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} multiSelect={true} label={"Additional Mentions"} required={false}/>
                                </Grid>
                                <Grid item xs={6} >
                                    <SelectValidator
                                        label="Category of Request"
                                        name="requestCategory"
                                        variant="outlined"
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        value={apvm.requestCategoryId}
                                        onChange={handleCategoryChange}
                                        margin="normal"
                                    >
                                        {listItems}
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={6} >
                                    <ClientDropdown id={client} onChange={handleClientChange} disabled={ false} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Details"
                                        name="details"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={apvm.details}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" disabled={submitting}>Cancel</Button>
                            <Button type="submit" color="primary" disabled={submitting}>
                                Submit
                                {submitting && <CircularProgress color='primary' size={24} />}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </React.Fragment>
    );
}
