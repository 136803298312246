import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import AccountListViewModel from './ViewModels/AccountListViewModel';
import Dialog from '@mui/material/Dialog';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import UserDropdown from '../../Dropdowns/UserDropdown';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../../api-authorization/AuthorizeService';
import { UserAccountViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import { PermissionCheck } from '../../Contexts/PermissionCheck';
import { PermissionsContext } from '../../Contexts/PermissionsContext';

interface IAccountListProps {
    account: AccountListViewModel;
    refresh: (refresh: boolean) => void;
    roles: string | string[];
    users: DropdownViewModel[];
}

export default function AccountTableRow(props: IAccountListProps) {
    const clientId = props.account.clientId;
    const id = props.account.id;
    const [openExecDialog, setDialogOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [exec, setExec] = React.useState<UserAccountViewModel>({ accountId: props.account.id, userId: '', startDate: new Date(), endDate: new Date() });
    const [error, setError] = React.useState('');
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const viewAccount = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts/Account',
                state: { accountId: id }
            }}
            {...props} />
    ));
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Client',
                state: { campaignId: clientId }
            }}
            {...props} />
    ));
    const openDialog = () => {
        setDialogOpen(true);
    }
    const handleClose = (refresh: boolean) => {
        if (refresh) {
            props.refresh(true);
        }
        setDialogOpen(false);
    }
    const onChange = (value: number | string, name: string, client: string) => {
        setExec({
            ...exec,
            [name]: value
        });
    }
    const updateMainExecutive = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();

        
                fetch('Account/AssignMainExecutive', {
                    method: 'POST',
                    headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                    body: JSON.stringify(exec)
                })
                    .then(response => response.text())
                    .then(data => {
                        setSubmitting(false);
                        handleClose(true);
                    })
                    .catch(error => {
                        setError('A server error has occurred, please try again.');
                    });
    }

    let teamLeader = <TableCell />;
    if (props.account.mainExecutive && props.account.mainExecutive.length > 0) {
        teamLeader = <TableCell>
            {props.account.mainExecutive}
            <PermissionCheck permission="Actions.UpdateMainExec">
                <IconButton size="small" onClick={openDialog} style={{ marginLeft: 8 }}><Edit color="secondary" /></IconButton>
            </PermissionCheck>
        </TableCell>;
    }
    else if (checkHasPermission("Actions.UpdateMainExec")) {
        teamLeader = <TableCell><Button size="small" variant="outlined" color="secondary" onClick={openDialog}>Set Main Exec</Button></TableCell>;
    } else {
        teamLeader = <TableCell>No Main Exec</TableCell>;
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.account.clientName}</TableCell>
                <TableCell>{props.account.name}</TableCell>                
                {teamLeader}
                <TableCell align="right"><Button variant="contained" style={{ textAlign: 'center' }} color="primary" size="small" component={viewButton} >View Client</Button></TableCell>
                <TableCell align="right">{props.roles !== "Creator" && <Button size="small" variant="contained" color="primary" style={{ textAlign: 'center' }} component={viewAccount}>View Account</Button>}</TableCell>
                
            </TableRow>
            <Dialog open={openExecDialog} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <ValidatorForm onSubmit={updateMainExecutive}>
                    <DialogTitle id="form-dialog-title">Assign Executive</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {error}
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <UserDropdown users={props.users} id={exec.userId} label="Executive" name="userId" onChange={onChange} required={true} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>handleClose(false)} color="secondary" disabled={submitting}>Cancel</Button>
                        <Button type="submit" color="primary" disabled={submitting}>
                            Submit
                            {submitting && <CircularProgress color='primary' size={24} />}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </React.Fragment>
    );
}