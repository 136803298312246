import * as React from 'react';
import TextField from '@mui/material/TextField';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import Autocomplete from '@mui/material/Autocomplete';

interface IDropdownProps {
    client: number;
    clients: DropdownViewModel[];
    onChange: (clientId: number) => void;
}

export default function ClientFilterDropdown(props: IDropdownProps) {
    const [search, setSearch] = React.useState('');
    const items = props.clients.map((item, index) => item.value);

    const client = props.clients.find(f => f.id == props.client)?.value ?? '';

    const handleChange = (event: any, newValue: any) => {
        const client = props.clients.find(f => f.value === newValue);
        props.onChange(client?.id as number ?? -1);
    };

    return (
        <Autocomplete
            id="combo-box-demo"
            options={items}
            value={client}
            onChange={handleChange}
            inputValue={search}
            onInputChange={(event: any, newInputValue: any) => {
                setSearch(newInputValue);
            }}
            renderInput={(params: any) =>
                <TextField
                    {...params}
                    label="Clients"
                    name="client"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                />
            }
        />
    );
}