import {useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TeamAvailabilityViewModel from './ViewModels/TeamAvailabilityViewModel';
import TeamAvailabilityTableRow from './TeamAvailabilityTableRow';

interface IExecutiveAvailability {
    teams: TeamAvailabilityViewModel[]
}

export default function ExecutiveAvailabilityTable(props: IExecutiveAvailability) {

    const [listOfStartDates, setListOfStartDates] = useState<string[]>([])

    useEffect(() => {
        initialise();
    }, []);

    const initialise = async () => {
        fetch(`DataAnalysis/GetTwelveWeeks`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setListOfStartDates(data.value);
                }
            });
    }

    const weeks = listOfStartDates.map((item) =>
        <TableCell>{item}</TableCell>
    );

    const teamsRows = props.teams.map((item) =>
        <TeamAvailabilityTableRow team={item} />
    );

    return (
        <TableContainer >
            <Table aria-label="Executive Availablilty" stickyHeader >
                <TableHead >
                    <TableRow>
                        <TableCell>Executives</TableCell>
                        {weeks}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teamsRows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}