import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import { ActivitySummaryViewModel } from '../../ViewModels/ActivityDateViewModel';
import DenseTableCell from '../../Utilities/DenseTableCell';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2),

        },
        red: {
            color: red[400],
            fontWeight: "bold"
        },
        green: {
            color: green[400]
        }
    })
);

interface IActivityProps {
    activity: ActivitySummaryViewModel;
    loaded: boolean;
}

export default function MonthlyActivity(props: IActivityProps) {
    const classes = useStyles();
    const { activity } = props;

    return (
        <React.Fragment>
            <div className={classes.paper}>
                <Typography variant="h6">{(new Date()).toLocaleString('default', { month: 'long' })}</Typography>
                <ResponsiveTable aria-label="activity summary" size="small">
                    <TableHead>
                        <TableRow>
                            <DenseTableCell>Connections</DenseTableCell>
                            <DenseTableCell>Messages</DenseTableCell>
                            <DenseTableCell>Acceptance Rate</DenseTableCell>
                            <DenseTableCell>Opportunities</DenseTableCell>
                            <DenseTableCell>Follow Ups</DenseTableCell>
                            <DenseTableCell>Page Invites</DenseTableCell>
                            <DenseTableCell>Open Profile InMails</DenseTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Fade in={props.loaded}
                            {...(props.loaded ? { timeout: 1000 } : {})}>
                                <TableRow>
                                    <DenseTableCell align="right">{activity.connections}</DenseTableCell>
                                    <DenseTableCell align="right">{activity.messages}</DenseTableCell>
                                    <DenseTableCell align="right" className={activity.acceptanceRate >= 50 ? classes.green : classes.red}>{activity.acceptanceRate}%</DenseTableCell>
                                    <DenseTableCell align="right">{activity.opportunities}</DenseTableCell>
                                    <DenseTableCell align="right">{activity.followUps}</DenseTableCell>
                                    <DenseTableCell align="right">{activity.pageInvites}</DenseTableCell>
                                    <DenseTableCell align="right">{activity.freeInMails ? activity.freeInMails : 0}</DenseTableCell>
                                </TableRow>
                        </Fade>
                    </TableBody>
                </ResponsiveTable>
            </div>
        </React.Fragment>
    );
}