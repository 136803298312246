import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import ReturnViewModel from '../ViewModels/ReturnViewModel';
import AccountOnboardingViewModel from './ViewModels/AccountOnboardingViewModel';
import AccountOnboardingRow from './AccountOnboardingRow';
import { ChangeEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import DenseTableCell from '../Utilities/DenseTableCell';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { PermissionCheck } from '../Contexts/PermissionCheck';



export default function AccountOnboardingContainer() {
    const [accounts, setAccounts] = React.useState<AccountOnboardingViewModel[]>([]);
    const [accountExecList, setAccountExecList] = React.useState<DropdownViewModel[]>([]);
    const [accountExecFilter, setAccountExecFilter] = React.useState<string>("");
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);


    React.useEffect(() => {
        initialise();
    }, []);

    React.useEffect(() => {
        getAccounts();
    }, [page]);

    const initialise = async () => {
        getFilterLists();
    }

    const getAccounts = () => {
        fetch(`AccountOnboarding/GetOnboardingAccounts?filterUser=${accountExecFilter}&page=${page}`)
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setAccounts(data.value.rows);
                setCount(Math.trunc(data.value.count / 25) + (data.value.count % 25 > 0 ? 1 : 0));
            });
    }

    const getFilterLists = async () => {
        const token = await authService.getAccessToken();

        const response = await fetch('User/GetAccountExecutiveDropdown', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setAccountExecList(data);
    }

    const handleAccountChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const index = accounts.findIndex(f => f.id === id);
        let editAccount = { ...accounts[index] };
        editAccount[event.target.name] = event.target.value;
        let editAccounts = [...accounts];
        editAccounts[index] = editAccount;
        setAccounts(editAccounts);
    }

    const handleStatus = (id: number, status: string) => {
        const index = accounts.findIndex(f => f.id === id);
        let editAccount = { ...accounts[index] };
        editAccount.status = status;
        let editAccounts = [...accounts];
        editAccounts[index] = editAccount;
        setAccounts(editAccounts);
    }
    const search = () => {
        if (page === 0)
            getAccounts();

        setPage(0);
    }

    const handleAccountExecFilter = (event: ChangeEvent<HTMLInputElement>) => {
        setAccountExecFilter(event.target.value)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage - 1);
    };

    const items = accounts.map((item) =>
        <AccountOnboardingRow key={item.id} account={item} handleAccount={handleAccountChange} handleStatus={handleStatus} />
    );

    const accountExecs = accountExecList.map((item) =>
        <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
    );

    return (
        <div style={{ paddingTop: '8px' }}>
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Account Onboarding</Typography>
                </Grid>
                <PermissionCheck permission="Pages.Onboarding">
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <TextField
                                    select
                                    name="accountExecFilter"
                                    label="User Filter"
                                    value={accountExecFilter}
                                    onChange={handleAccountExecFilter}
                                    size="small"
                                    fullWidth
                                    sx={{ minWidth: '160px' }}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {accountExecs}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={search}
                                    color="secondary"
                                    size="small"
                                    variant="contained"
                                    fullWidth
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </PermissionCheck>
                <Grid item xs={12}>
                    <TableContainer >
                        <ResponsiveTable aria-label="Account List" size="small">
                            <TableHead>
                                <TableRow>
                                    <DenseTableCell>Client</DenseTableCell>
                                    <DenseTableCell>Account</DenseTableCell>
                                    <PermissionCheck permission="Pages.Onboarding">
                                        <DenseTableCell>Main Executive</DenseTableCell>
                                    </PermissionCheck>
                                    <DenseTableCell>Start Date</DenseTableCell>
                                    <DenseTableCell>Status</DenseTableCell>
                                    <DenseTableCell>Checklist</DenseTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items}
                            </TableBody>
                        </ResponsiveTable>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Pagination page={page + 1} onChange={handleChangePage} count={count} showFirstButton showLastButton />
                </Grid>
            </Grid>
        </div >
    );
}