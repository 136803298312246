import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { ChangeEvent } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CommissionGroupViewModel from "./ViewModels/CommissionGroupViewModel";

interface IProps {
    editorOpen: boolean
    editorClose: () => void
    commissionGroup: CommissionGroupViewModel
    handleCommissionGroupChange: (event: ChangeEvent<HTMLInputElement>) => void
    submit: () => void
}
export default function CommissionGroupEditorDialog(props: IProps) {

    return (
        <ValidatorForm onSubmit={props.submit}>
            <Dialog
                fullWidth
                maxWidth="md"
                open={props.editorOpen}
                onClose={props.editorClose}
            >
                <DialogTitle>Commission Group Editor</DialogTitle>
                <DialogContent >
                    <Grid container spacing={1} sx={{ paddingTop: 1 }}>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Group Name"
                                name="groupName"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.groupName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={props.handleCommissionGroupChange}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                type="number"
                                label="Performance Bonus"
                                name="performanceBonus"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.performanceBonus}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                type="number"
                                label="Retention Bonus"
                                name="retentionBonus"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.retentionBonus}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Loss Bonus"
                                name="lossBonus"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.lossBonus}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Gain Bonus"
                                name="gainBonus"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.gainBonus}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Billing Target"
                                name="billingTarget"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.billingTarget}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Renewals Target"
                                name="renewalsTarget"
                                variant="outlined"
                                fullWidth
                                value={props.commissionGroup.renewalsTarget}
                                validators={['required', 'isFloat']}
                                errorMessages={['This field is required', 'Needs to be a decmial']}
                                onChange={props.handleCommissionGroupChange}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.editorClose}>Cancel</Button>
                    <Button type="submit" onClick={props.submit}>Confirm</Button>
                </DialogActions>
            </Dialog>
            </ValidatorForm>

    );
}