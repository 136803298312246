import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import AccountDropdownViewModel from '../ViewModels/AccountDropdownViewModel';

interface IDropdownProps {
    id: string | null;
    account: string | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AccountExecutiveDropdown(props: IDropdownProps) {
    const [list, setList] = React.useState<AccountDropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setLoaded(false);
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const response = await fetch(`Commission/GetAccountDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setList(data);
        setLoaded(true);
    }



    const items = list.filter(f => f.execId === props.id).map((item, index) =>
        <MenuItem key={index} value={item.id}>{item.value}</MenuItem>);

    return (
        <TextField
            select
            label="Accounts"
            name="accountId"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={props.onChange}
            value={props.account}
            InputProps={{
                disabled: !loaded,
                
            }}
        >
            <MenuItem key={0} value={0}><em>None</em></MenuItem>
            {items}
        </TextField>
    );
}