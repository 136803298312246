import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import grey from '@mui/material/colors/grey';
import CreateStatusNote from './CreateStatusNote';
import Divider from '@mui/material/Divider';
import authService from '../../api-authorization/AuthorizeService';
import { PermissionsContext } from '../../Contexts/PermissionsContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        statusGrid: {
            flexGrow: 1
        },
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
            "&.Mui-disabled": {
                color: theme.palette.getContrastText(green[500]),
                backgroundColor: green[500],
            },
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            '&:hover': {
                backgroundColor: amber[700],
            },
            "&.Mui-disabled": {
                color: theme.palette.getContrastText(amber[500]),
                backgroundColor: amber[500],
            },
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            '&:hover': {
                backgroundColor: '#ff7961',
            },
            "&.Mui-disabled": {
                color: theme.palette.getContrastText('#ff7961'),
                backgroundColor: '#ff7961',
            },
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
            "&.Mui-disabled": {
                color: theme.palette.getContrastText(red[500]),
                backgroundColor: red[500],
            },
            width: '100%'
        },
        darkRed: {
            color: theme.palette.getContrastText(red[700]),
            backgroundColor: red[700],
            '&:hover': {
                backgroundColor: red[900],
            },
            "&.Mui-disabled": {
                color: theme.palette.getContrastText(red[700]),
                backgroundColor: red[700],
            },
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%',
            "&.Mui-disabled": {
                color: theme.palette.getContrastText(grey[500]),
                backgroundColor: grey[500],
            },
        },
        default: {
            color: theme.palette.getContrastText('#CECEEB'),
            backgroundColor: '#CECEEB',
            width: '100%',
            "&.Mui-disabled": {
                color: theme.palette.getContrastText('#CECEEB'),
                backgroundColor: '#CECEEB',
            },
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IListItemProps {
    status: string;
    onClick: (value: string) => void;
    disabled: boolean;
}

const StatusListItem = React.forwardRef((props: IListItemProps, ref: any) => {
    const onChange = () => {
        props.onClick(props.status);
    }

    return (
        <MenuItem onClick={onChange} ref={ref} disabled={props.disabled}>
            {props.status}
        </MenuItem>
    );
});

interface IStatusProps {
    accountId: number;
    status: string;
    clientId: number;
    refresh: () => void;
}

export default function Status(props: IStatusProps) {
    const classes = useStyles();
    const { checkHasPermission } = React.useContext(PermissionsContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newStatus, setNew] = React.useState(props.status);
    React.useEffect(() => { setNew(props.status); }, [props.status]);
    const [openDialog, setDialog] = React.useState(false);
    const [allowLive, setAllowLive] = React.useState<boolean>(false);
    const adminStatuses = [
        { status: "Live", class: classes.green },
        { status: "At Risk", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "Suspended", class: classes.red }
    ];

    React.useEffect(() => {
        getAllowLive();
    }, [])

    const getAllowLive = async () => {
        const token = await authService.getAccessToken();
        fetch(`Account/GetAccountAllowlive?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAllowLive(data.value);
            })
    }

    const onChange = async (value: string) => {
        setNew(value);
        handleClose();
        handleDialog();
    }

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);        
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDialog = () => {
        setDialog(!openDialog);
    }

    const closeDialog = (refresh: boolean) => {
        setDialog(false);

        if (!refresh) {
            setNew(props.status);
        } else {
            props.refresh();
        }
    }
    const statusClass = newStatus.length > 0 ? adminStatuses.find(f => f.status === newStatus)!.class : classes.default;
    const adminList = adminStatuses.map((status) => (
        <StatusListItem key={status.status} status={status.status} onClick={onChange} disabled={!(status.status === "Live" ? allowLive : true)} />
    ));
    return (
        <React.Fragment>
            <div className={classes.paper}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Status</Typography>
                    </Grid>
                    <Grid item className={classes.statusGrid}>
        
                        <Fab disabled={!checkHasPermission("Actions.UpdateStatus") } className={statusClass} variant='extended' onClick={openMenu}>
                            {newStatus}                            
                        </Fab>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.paper}/>
            <Menu onClose={handleClose} anchorEl={anchorEl} open={Boolean(anchorEl)}>
                {adminList}
            </Menu>
            <CreateStatusNote clientId={props.clientId} accountId={props.accountId} open={openDialog} close={closeDialog} from={props.status} to={newStatus} />
        </React.Fragment>
    );
}