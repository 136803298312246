import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { Filters, SortReportViewModel } from '../ViewModels/ReportViewModel';
import authService from '../api-authorization/AuthorizeService';
import ReportTable from './ReportTable';

interface IWeeklyProps {
    date: Date;
    filters: Filters;
    onAccLoaded: (accounts: SortReportViewModel[]) => void;
    reportLoaded: boolean;
    setReportLoaded: (loaded: boolean) => void;
}

export default function WeeklyReport(props: IWeeklyProps) {
    const [accounts, setAccounts] = React.useState<SortReportViewModel[]>([]);
    const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

    React.useEffect(() => {
        const getData = async () => {
            props.setReportLoaded(false);

            const token = await authService.getAccessToken();

            fetch(`Report/GetReports?type=weekly&date=${props.date.toJSON()}&client=${props.filters.client}&clientId=${props.filters.clientId}&executive=${props.filters.execId}&copyWriter=${props.filters.copyWriterId}&clientDirector=${props.filters.clientDirectorId}&teamLeader=${props.filters.teamLeaderId}&team=${props.filters.teamId}&status=${props.filters.statusId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setAccounts(data);
                    props.setReportLoaded(true);
                    props.onAccLoaded(data);
                })
                .catch(error => { props.setReportLoaded(true); });

        }
        if (!firstLoad) {
            getData();
        }
        else {
            setFirstLoad(false);
        }
    }, [props.date, props.filters]);


    return (
        <React.Fragment>
            {!props.reportLoaded ? <LinearProgress color="primary" /> :
                <Grid container>
                    <Grid item xs={12}>
                        <ReportTable accounts={accounts} isWeekly />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}