import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { Fragment } from "react";
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";



export default function ClientDiscoveryDeepDive({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Client Deep Dive</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">This is your chance to learn everything you need to know about The Client. The more detail, the better. REMEMBER; CONFIDENT, KNOWLEDGEABLE, UPBEAT</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Where is The Client based?*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">"Out of interest, where are you guys based" (Gets the client talking and breaks the ice)</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="clientBase"
                        value={data.clientBase}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">ONLY IF THE CLIENT IS BASED IN THE EU*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Great before we get into the briefing call itself, just so I can make sure you are charged correctly, can I just get your VAT number to pass on to our Finance team?"

                        This only applies to the following countries: Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France (exc Monaco), Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovak Republic, Slovenia, Spain, Sweden.

                        Please read the number back to them to confirm it is correct, it should be between 8 - 12 characters.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="vatNumber"
                        value={data.vatNumber}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Reason for the call:*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="reasonForCall"
                        value={data.reasonForCall}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Main Point of Contact: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Okay, so who is going to be the main point of contact on your end, and who do we need to copy into communications? Is there anyone we can contact if we can't get hold of these people?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="mainPointOfContact"
                        value={data.mainPointOfContact}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Client Deep Dive: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "So it would be great if you could tell me a little more about the business and what you do? Are there any frequently asked questions you get from potential prospects?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="clientDeepDive"
                        value={data.clientDeepDive}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                {data.campaignType !== "Key (More than 3 Accounts)" &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">Have you done any form of lead generation before & if so how did it go?*</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="leadGeneration"
                                value={data.leadGeneration}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Typography variant="h6">And if you don't mind me asking, what is your average order value and sales cycle?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Try to find out as much as you can - who takes those calls is it the CEO or the sales team? How big is the Sales team? Can you have their contact details to forward leads on? What is your conversion rate at the moment?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="orderValueAndCycle"
                        value={data.orderValueAndCycle}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>

                {((data.isBoost || data.isVelocity) && !data.isAds) || (data.isBoost && data.isVelocity && data.isAds && data.isContent) &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">When you are introducing the product/service to someone, what initial objections do you face?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "That all sounds great, so when you are introducing the product/service to someone then, what initial objections do you face?... And how do you deal with those?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="initialObjections"
                                value={data.initialObjections}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        {data.campaignType === "Key (More than 3 Accounts)" &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Segmentation: *</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        "Great, so moving onto target, we are of course using more than one channel here. Are we looking to segment the targets, or to use them as a combined strategy?"

                                        (Basically, are we hitting them once with either LI message or email or ad, or are we using them to target the same people in different ways to increase brand awareness and create familiarity with the prospects)

                                        IF they're wanting sep targets make sure you note which is for which. Advise that if they don't know how they want to segment but they do want them separate that we can do that ourselves. Make suggestions based upon the titles/locations/specialisms.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        name="segmentation"
                                        value={data.segmentation}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        multiline
                                        validators={['required']}
                                        errorMessages={['This field is required']}

                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Typography variant="h6">What is the desired outcome for this campaign?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "Great so what was the main reason for you signing up with us? I assume it is for you to get more clients is that right?"
                            </Typography>
                            <Typography variant="body1" sx={{ paddingTop: 2 }}>
                                "So I can see here you're on a 3 month term, as our minimum term is 6 months obviously you worked something out with our sales team so tell me, is there a particular reason you were only looking for a 3 month commitment? ... And how many leads do I need to get you by the end of month 2 for me to send over a renewal contract?"
                            </Typography>
                            <Typography variant="body1" sx={{ paddingTop: 2 }}>
                                *Make sure the goal is realistic. Be direct. You should then write this down and make it the topic of conversation on every catch up call you have*
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="desiredOutcome"
                                value={data.desiredOutcome}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Geography To Target:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "Right so I know you mentioned you are based in the US, are you looking to stay and grow your business there or expand a little?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="targetGeography"
                                value={data.targetGeography}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Industry To Target:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Attempt to make an assumption on industry eg; "I assume then you are targeting Healthcare then, is that right?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="targetIndustry"
                                value={data.targetIndustry}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Size of Companies To Target:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "So are you looking to target smaller, medium, or larger size companies?" [LET CLIENT ANSWER] " Great so what sort of company headcount would you consider to be smaller as I know this is a little different for everyone?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={data.targetSizeSelf} name="targetSizeSelf" onChange={handleInputChange} />} label="Self Employed" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize1_10} name="targetSize1_10" onChange={handleInputChange} />} label="1 - 10" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize11_50} name="targetSize11_50" onChange={handleInputChange} />} label="11 - 50" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize51_200} name="targetSize51_200" onChange={handleInputChange} />} label="51 - 200" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize201_500} name="targetSize201_500" onChange={handleInputChange} />} label="201 - 500" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize501_1000} name="targetSize501_1000" onChange={handleInputChange} />} label="501 - 1000" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize1001_5000} name="targetSize1001_5000" onChange={handleInputChange} />} label="1001 - 5000" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize5001_10000} name="targetSize5001_10000" onChange={handleInputChange} />} label="5001 - 10,000" />
                                <FormControlLabel control={<Checkbox checked={data.targetSize10000plus} name="targetSize10000plus" onChange={handleInputChange} />} label="10,000 +" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">What are some examples of current clients you work with?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "And just so I have a better idea of exactly what you are after, do you have some examples of current clientsI I can do some research on?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="exampleClients"
                                value={data.exampleClients}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">On that point, are there any particular companies or current clients you'd like to avoid?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                This is a good point to remind The Client we only reach out to people who are NOT in their current network
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="avoidClients"
                                value={data.avoidClients}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Top 3 Competitors</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "And on the topic of companies to exclude, who would you say are your top 3 competitors and how do you differ from them?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="competitors"
                                value={data.competitors}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">So when you look at the current clients you have, what job title are you usually speaking with?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Is there only ever one job title? Or do they speak to a few?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="targetJobTitle"
                                value={data.targetJobTitle}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">For those job titles you just mentioned, what pain point do you solve for each of them?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Pain Point = Something the client needs help with/solving. " Reverse Selling Point "
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="painPoint"
                                value={data.painPoint}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">What would be your dream client?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="dreamClient"
                                value={data.dreamClient}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Outcome for a lead</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "Great so in our messaging we always include a direct call to action at the end. Most of our clients choose to get on a call with the interested prospects, would this be the same for you too?"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="leadOutcome"
                                value={data.leadOutcome}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Calendly Link:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "On your package, we can book this directly into your calendar for you if you'd like, out of interest do you have a calendly link you use?'"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="calendarLink"
                                value={data.calendarLink}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Message Tone:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "For your messaging then, just to help me get it right first time, is there any particular language or tone you would like me to use?'
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="messageTone"
                                value={data.messageTone}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Relevant Package Elements:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={data.packageElementConversationManagement} name="packageElementConversationManagement" onChange={handleInputChange} />} label="Conversation Management" />
                                <FormControlLabel control={<Checkbox checked={data.packageElementCRMIntegration} name="packageElementCRMIntegration" onChange={handleInputChange} />} label="CRM Integration" />
                                <FormControlLabel control={<Checkbox checked={data.packageElementProspectEngagement} name="packageElementProspectEngagement" onChange={handleInputChange} />} label="Prospect Engagement" />
                                <FormControlLabel control={<Checkbox checked={data.packageElementCompanyPageFollowers} name="packageElementCompanyPageFollowers" onChange={handleInputChange} />} label="Company Page Followers" />
                                <FormControlLabel control={<Checkbox checked={data.packageElementArticle} name="packageElementArticle" onChange={handleInputChange} />} label="Article" />
                            </FormGroup>
                        </Grid>
                        {(data.isVelocity || data.isBoost) &&
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Monthly Article:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        "As part of your package we will write and post one article per month on your Linkedin page. After the call, I will introduce you to our marketing team who will be writing this for you however I wondered whether you would be the right contact for this or whether they will be working with someone else within your team?"

                                        If someone else - get their email address
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        name="monthlyArticleContact"
                                        value={data.monthlyArticleContact}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        multiline
                                        validators={['required']}
                                        errorMessages={['This field is required']}

                                    />
                                </Grid>
                            </Fragment>
                        }
                        {data.campaignType !== "Key (More than 3 Accounts)" &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Ads Referral?*</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Have you ever ran any forms of paid advertising for this yet such as LinkedIn or Google Ads?

                                        YES - Great, if you don't mind me asking how did they perform?... Well we have an advertising team here, if you'd like I can request a free audit for your Ads Account by one of our experts? We can highlight any wastage in there and explain what we'd do to improve it.

                                        NO - No problem, if it's ever of interest we have an advertising team here specialising in LinkedIn and Google Ads. If you ever want to learn more just let us know.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectValidator
                                        name="adsReferral"
                                        value={data.adsReferral}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        size="small"
                                        validators={['required']}
                                        errorMessages={['This field is required']}

                                    >
                                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                                        <MenuItem key={1} value={"No"}>No</MenuItem>
                                    </SelectValidator>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6">Email Campaign?*</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectValidator
                                        name="emailCampaign"
                                        value={data.emailCampaign}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                        size="small"
                                        validators={['required']}
                                        errorMessages={['This field is required']}

                                    >
                                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                                        <MenuItem key={1} value={"No"}>No</MenuItem>
                                    </SelectValidator>
                                </Grid>
                            </>
                        }
                        {(data.campaignType === "Key (More than 3 Accounts)" && data.isContent) &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">ARTICLE NOT CONTENT: *</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        "As part of your package we will write and post one article per month on your Linkedin page. After the call, I will introduce you to our marketing team who will be writing this for you however I wondered whether you would be the right contact for this or whether they will be working with someone else within your team?"

                                        If someone else - get their email address
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        name="articleNotContent"
                                        value={data.articleNotContent}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        multiline
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                            </>
                        }
                        {data.isContent &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Login Details: *</Typography>
                                    <Typography variant="caption">"Fantastic! So what we can do now, as we have some time left on our call and to save some back and forth over email, if you give me your log in details now, I'll make sure I can get logged in ok" </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Username:</Typography>
                                    <TextValidator
                                        name="logInUser"
                                        value={data.logInUser}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        multiline
                                        validators={['required']}
                                        errorMessages={['This field is required']}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Password:</Typography>
                                    <TextValidator
                                        name="logInPass"
                                        value={data.logInPass}
                                        onChange={handleInputChange}
                                        fullWidth
                                        size="small"
                                        multiline
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                            </>
                        }
                    </>
                }
            </Grid>
        </Paper>
    );
}