import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import { WeeklyMetricsViewModel } from '../../ViewModels/ActivityDateViewModel';
import DenseTableCell from '../../Utilities/DenseTableCell';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2),

        },
        red: {
            color: red[400],
            fontWeight: "bold"
        },
        green: {
            color: green[400]
        }
    })
);

interface IWeeklyMetricsProps {
    metrics: WeeklyMetricsViewModel;
    loaded: boolean;
}

export default function WeeklyMetics(props: IWeeklyMetricsProps) {
    const classes = useStyles();
    const { metrics } = props;

    return (
        <React.Fragment>
            <div className={classes.paper}>
                <Typography variant="h6">Weekly Metrics</Typography>
                <ResponsiveTable aria-label="activity summary" size="small">
                    <TableHead>
                        <TableRow>
                            <DenseTableCell></DenseTableCell>
                            <DenseTableCell>Connections</DenseTableCell>
                            <DenseTableCell>Messages And Inmails</DenseTableCell>
                            <DenseTableCell>Follow Ups</DenseTableCell>
                        </TableRow>
                    </TableHead><Fade in={props.loaded}>
                        <TableBody>
                            <TableRow>
                                <DenseTableCell align="right">Target Metrics</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetConnections}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetMessagesAndInmails}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetFollowUps}</DenseTableCell>
                            </TableRow>
                            <TableRow>
                                <DenseTableCell align="right">Current Metrics</DenseTableCell>
                                <DenseTableCell align="right">{metrics.currentConnections}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.currentMessagesandInmails}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.curentFollowUps}</DenseTableCell>
                            </TableRow>
                            <TableRow>
                                <DenseTableCell align="right">Remaining Metrics</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetConnections - metrics.currentConnections}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetMessagesAndInmails - metrics.currentMessagesandInmails}</DenseTableCell>
                                <DenseTableCell align="right">{metrics.targetFollowUps - metrics.curentFollowUps}</DenseTableCell>
                            </TableRow>
                        </TableBody>
                    </Fade>
                </ResponsiveTable>
            </div>
        </React.Fragment>
    );
}