import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ContactsTableRow from './ContactTableRow';
import GetContactsViewModel from './ViewModels/GetContactsViewModel';

interface IContactsTable {
    contacts: GetContactsViewModel[]
    editContact: (id: number) => void;
    deleteContact: (id: number) => void;
    isAccount: boolean;
    setAccountPrimary: (id: number) => void;
    setClientPrimary: (id: number) => void;
    permission: string;
}

export default function ContactsTable(props: IContactsTable) {
    const items = props.contacts.map((item, index) =>
        <ContactsTableRow key={item.id} contact={item} editContact={props.editContact} isAccount={props.isAccount} setAccountPrimary={props.setAccountPrimary} setClientPrimary={props.setClientPrimary} deleteContact={props.deleteContact} permission={props.permission} />
    );

    return (
        <TableContainer >
            <Table aria-label="contacts list" stickyHeader >
                <TableHead >
                    <TableRow>
                        <TableCell align="center">Primary Contact</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Job Title</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </Table>
        </TableContainer>
    )
}