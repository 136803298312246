import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { IClientDiscoveryFormTabProps } from "../ClientDiscoveryForm";

export default function ClientDiscoveryAds({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 5 - Ads</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Great! So following this call, I'll send you a confirmation of all of the points discussed today. You'll also have two separate emails, one with requirements I'll need from you and the other with the ad design brief. If you could action that ASAP that would be great.

                        When the campaign is built, we've designed the ad and we've set up tracking, I'll be in touch to conduct a launch call. This won't take longer than 5 working days on the basis that we have everything from you.

                        During the launch call, I'll be able to share my screen with you again, then we can review everything to make sure you're happy with it. If you are then we'll launch the campaign on that call.

                        Then following this, the campaign will initially go through a 14 day learning period. Our changes in the campaign will be very minimal at this time in order to collect some initial data. The more data we have, the better changes we can then make to improve performance.

                        I'll be in touch after the learning phase, so two weeks in, and from there we can arrange our catch-ups moving forward.

                        I think I've covered everything there, do you have any questions or want me to go over anything?
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}