import Grid from '@mui/material/Grid';
import ClientTable from './ClientTable';
import AccountListViewModel from './ViewModels/AccountListViewModel';
import ClientListViewModel from './ViewModels/ClientListViewModel';
interface IClientListProps {
    roles: string | string[];
    pendingAccounts: AccountListViewModel[];
    pendingClients: ClientListViewModel[];
    refresh: () => void;
}
export default function ClientList(props: IClientListProps) {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
                <ClientTable clients={props.pendingClients} refresh={props.refresh} roles={props.roles} pendingAccounts={ props.pendingAccounts} />
            </Grid>
        </Grid>
    );
}