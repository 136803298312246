import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import RenewalDataEntry from '../Renewals/RenewalDataEntry';
import CreateRenewalViewModel from '../Renewals/ViewModels/CreateRenewalViewModel';
import { CreateCampaignNewAccountViewModel } from '../ViewModels/CampaignViewModel';


interface IProps {
    newAccount: CreateCampaignNewAccountViewModel;
    editAccount: (newAccount: CreateCampaignNewAccountViewModel) => void;
    removeAccount: (id: number) => void;
}

export default function CreateCampaignNewAccount(props: IProps) {

    const remove = () => {
        props.removeAccount(props.newAccount.uid);
    }
    const name = (event: React.ChangeEvent<HTMLInputElement>) => {
        let acc: CreateCampaignNewAccountViewModel = { ...props.newAccount, accountName: event.target.value };
        props.editAccount(acc);
    }
    const updateRenewal = (renewal: CreateRenewalViewModel) => {
        let acc: CreateCampaignNewAccountViewModel = { ...props.newAccount, renewal };
        props.editAccount(acc);
    }

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <TextField
                    name="accountName"
                    fullWidth
                    variant="outlined"
                    value={props.newAccount.accountName}
                    onChange={name}
                />
            </Grid>
            <Grid item xs={3}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={remove}
                    size="large"
                >Remove Account</Button>
            </Grid>
            <Grid item xs={12} >
                <RenewalDataEntry renewal={props.newAccount.renewal} setRenewal={updateRenewal} />
            </Grid>
        </React.Fragment>
    );
}