import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import ClientListViewModel from './ViewModels/ClientListViewModel';
import TeamLeaderDialog from '../../Campaigns/TeamLeaderDialog';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import AccountListViewModel from './ViewModels/AccountListViewModel';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import AccountsList from './AccountsList';
import {PermissionsContext} from '../../Contexts/PermissionsContext';

interface IClientListProps {
    campaign: ClientListViewModel;
    teamLeaders: DropdownViewModel[];
    refresh: () => void;
    roles: string | string[];
    pendingAccounts: AccountListViewModel[];
}

export default function CampaignTableRow(props: IClientListProps) {
    const id = props.campaign.id;
    const [openTLeaderDialog, setDialogOpen] = React.useState(false);
    const[openAccountDialog, setAccountDialogOpen] = React.useState(false);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const viewAccounts = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts',
                state: { campaignId: id }
            }}
            {...props} />
    ));
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Client',
                state: { campaignId: id }
            }}
            {...props} />
    ));
    const openDialog = () => {
        setDialogOpen(true);
    }
    const openAccountsDialog = () => {
        setAccountDialogOpen(true);
    }
    const handleClose = (refresh: boolean) => {
        if (refresh) {
            props.refresh();
        }
        setDialogOpen(false);
        setAccountDialogOpen(false);
    }


    let teamLeader = <TableCell />;
    if (props.campaign.teamLeader && props.campaign.teamLeader.length > 0) {
        teamLeader = <TableCell>
            {props.campaign.teamLeader}
            {(props.roles !== "Executive" && props.roles !== "Creator") && <IconButton size="small" onClick={openDialog} style={{ marginLeft: 8 }}><Edit color="secondary" /></IconButton>}
        </TableCell>;
    }
    else if (checkHasPermission("Actions.UpdateClientManager")) {
        teamLeader = <TableCell><Button size="small" variant="outlined" color="secondary" onClick={openDialog}>Set Client Manager</Button></TableCell>;
    } else {
        teamLeader = <TableCell>No Team Leader</TableCell>;
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.campaign.name}</TableCell>
                {teamLeader}
                <TableCell>
                    {
                        <Button size="small" variant="outlined" color="secondary" onClick={openAccountsDialog}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>Onboarding Accounts</Grid>
                                <Grid item>
                                    <Chip color="warning" size="small" label={props.pendingAccounts.filter(f => f.mainExecutiveId == null || f.mainExecutiveId.length===0).length} />
                                </Grid>
                            </Grid>
                        </Button>
                    }
                </TableCell>
                <TableCell align="right"><Button variant="contained" style={{ textAlign: 'center' }} color="primary" size="small" component={viewButton} >View Client</Button></TableCell>
                <TableCell align="right">{props.roles !== "Creator" && <Button size="small" variant="contained" color="primary" style={{ textAlign: 'center' }} component={viewAccounts}>View Accounts</Button>}</TableCell>

            </TableRow>
            <TeamLeaderDialog users={props.teamLeaders} campaignId={props.campaign.id} open={openTLeaderDialog} close={handleClose} />
            <Dialog open={openAccountDialog} aria-labelledby="form-dialog-title" maxWidth={ false}>
                
                <DialogContent>
                    <AccountsList roles={props.roles} refresh={handleClose} pendingAccounts={props.pendingAccounts} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose(true)} color="secondary" >Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}