import * as React from 'react';
import Grid from '@mui/material/Grid';
import RegisterUser from './RegisterUser';
import ManageUsers from './ManageUsers';
import UpdatePackages from './UpdatePackages';
import { PermissionsContext } from '../Contexts/PermissionsContext';

export default function AdminDashboard() {
    const [Access, SetAccess] = React.useState(false);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    React.useEffect(() => {
        if (checkHasPermission("")) {
            SetAccess(true);
        }
    }, []);

    return (
        <React.Fragment>
            {Access &&
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <RegisterUser />
                    </Grid>
                    <Grid item xs={6}>
                        <ManageUsers />
                    </Grid>
                    <Grid item xs={6}>
                        <UpdatePackages />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}