import { GetUserListViewModel } from "../../ViewModels/LinkedInAccountViewModel";

export default class ApprovalRequestViewModel {
    clientName: string = '';
    requestCategoryId: number = 0;
    details: string = '';
    emails: GetUserListViewModel[] = [];
}

export class GetRequestTableViewModel {
    id: number = 0;
    requestUserName: string = '';
    campaignName: string = '';
    category: string = '';
    requestTime: string = '';
    approvalState: string = '';
    approvalUserName: string = '';
}
export class ApproveRequestViewModel {
    id: number = 0;
    requestUserName: string = '';
    campaignName: string = '';
    category: string = '';
    requestTime: string = '';
    requestDetails: string = '';
    approvalState: string = '';
    approvalDetails: string = '';
    emails: GetUserListViewModel[] = [];
}