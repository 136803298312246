import * as React from 'react';
import { Theme } from '@mui/material/styles';
import authService from '../api-authorization/AuthorizeService';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import UserManagementTable from './UserManagementTable';
import { UserListViewModel } from './UserManagementViewModels';
import {PermissionsContext} from '../Contexts/PermissionsContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 650,

        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        pagination: {
            paddingTop: theme.spacing(2),
        }
    }),

);

export default function UserManagementContainer() {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [users, setUsers] = React.useState<UserListViewModel[]>([]);
    const [filteredUsers, setFilteredUsers] = React.useState<UserListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [pageMax, setPageMax] = React.useState(0);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    React.useEffect(() => {
        initialise();
    }, []);

    React.useEffect(() => {
        const filter = () => {
            let filtered = [...users];
            if (search.length > 0) {
                filtered = filtered.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
            }
            setPageMax(Math.ceil(filtered.length / 25));
            if (page > Math.ceil(filtered.length / 25)) {
                setPage(1);
            }
            filtered = filtered.splice((page - 1) * 25, 25);
            setFilteredUsers(filtered);
        }
        filter();
    }, [search, users, page]);

    const initialise = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        
        if (checkHasPermission("")) {
            fetch('UserManagement/GetUsers', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setUsers(data);
                    setLoaded(true);
                })
                .catch(error => { setLoaded(true) });
        }
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue);
    };

    const list = <UserManagementTable users={filteredUsers} refresh={initialise} loadedUsers={loaded} />;

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h2">Users</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={search}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onFilterChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {!loaded && <LinearProgress />}
                {list}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.pagination}>
                    <Grid item>
                        <Pagination page={page} onChange={handlePageChange} count={pageMax} showFirstButton showLastButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}