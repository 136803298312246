import { useState, useEffect } from 'react';
import authService from '../api-authorization/AuthorizeService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { UserListViewModel } from './UserManagementViewModels';
import Switch from '@mui/material/Switch';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface IUserManagementTableProps {
    loadedUsers: boolean;
    users: UserListViewModel[];
    refresh: () => void;
}

export default function UserManagementTable(props: IUserManagementTableProps) {
    const [loading, setLoading] = useState(true);
    const [commissionGroups, setCommissionGroups] = useState<DropdownViewModel[]>([]);
    const [dropdown, setDropdown] = useState<JSX.Element[]>([<MenuItem value={-1} >Not Set (Default)</MenuItem>]);

    useEffect(() => {
        initialise();
    }, []);

    useEffect(() => {
        setDropdown([
            <MenuItem value={-1}>Not Set (Default)</MenuItem>,
            ...commissionGroups.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
            )]);

    }, [commissionGroups]);

    const initialise = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch('UserManagement/GetCommissionGroups', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCommissionGroups(data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const setActive = (user: string) => async (_: any, value: boolean) => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`UserManagement/UpdateShowCommissionToUser?UserId=${user}&Show=${value}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    props.refresh();
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }
    const setGroup = (user: string) => async (event: SelectChangeEvent<number | null>) => {
        if (event.target.value !== null && event.target.value > 0) {
            setLoading(true);
            const token = await authService.getAccessToken();
            fetch(`UserManagement/AddUserToCommissionGroup?UserId=${user}&CommissionGroupId=${event.target.value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        props.refresh();
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    const items = props.users.map((item, index) =>
        <TableRow>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.role}</TableCell>
            <TableCell>
                <Select
                    value={item.commissionGroup}
                    variant="outlined"
                    color="secondary"
                    onChange={setGroup(item.id)}
                >
                    {dropdown}
                </Select>
            </TableCell>
            <TableCell>
                <Switch checked={item.commissionActive} onChange={setActive(item.id)} /> </TableCell>
        </TableRow>
    );

    return (
        <TableContainer style={{ maxHeight: "75vh" }}>
            <Table aria-label="users list" stickyHeader >
                <TableHead >
                    <TableRow>
                        <TableCell>User Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Commission Group</TableCell>
                        <TableCell>Show Commissions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(props.loadedUsers && !loading) && items}
                </TableBody>
            </Table>
        </TableContainer>
    );
}