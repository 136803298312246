import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import { CreateArchiveNoteViewModel } from '../ViewModels/LinkedInAccountViewModel';
import TextField from '@mui/material/TextField';

interface IProps {
    open: boolean;
    onClose: () => void;
    accountId: number;
    redirect: (r: boolean) => void;
}

export default function ArchiveDialog(props: IProps) {
    const { open, onClose, accountId, redirect } = props;
    const [note, setNote] = React.useState(new CreateArchiveNoteViewModel(accountId));

    const disableAccount = async () => {
        const token = await authService.getAccessToken();

        fetch(`Account/DisableAccount?accountId=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                redirect(true);
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    }

    const createNote = async () => {
        const token = await authService.getAccessToken();

        const newNote = note;
        note.id = accountId;
        note.noteTypeId = 11;
        fetch('Campaign/CreateArchiveNote', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newNote)
        })
            .then(response => response.text())
            .then(data => {
                disableAccount();
            })
            .catch(error => {
            });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote({
            ...note,
            [event.target.name]: event.target.value
        });
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently archive the account.
                    </DialogContentText>
                    <TextField
                        label="Reason for archive"
                        name="content"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline
                        rows={4}
                        value={note.content}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={createNote} color="primary" autoFocus>
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}