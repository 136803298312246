import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import CommissionTable from './CommissionsTable';
import AdjustmentDialog from './AdjustmentDialog';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {PermissionsContext} from '../Contexts/PermissionsContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            maxHeight: "90vh"
        }
    }),
);

export default function Commissions() {
    const classes = useStyles();
    const [reload, setReload] = React.useState(false);
    const [openAdjustment, setOpenAdjustment] = React.useState(false);
    const [complete, setComplete] = React.useState(false);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const reloadPage = (event: boolean) => {
        setReload(event);
    }

    const showAlert = (event: boolean) => {
        setComplete(event);
    }

    const toggleOpenAdjustment = () => {
        setOpenAdjustment(!openAdjustment);
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CommissionTable reload={reload} reloadPage={reloadPage}/>
                        </Grid>
                        {
                            checkHasPermission("") &&
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" onClick={toggleOpenAdjustment}>Add Adjustment</Button>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Collapse in={complete} timeout={300}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                setComplete(false);
                                            }}><CloseIcon fontSize="inherit" /></IconButton>}>
                                    Adjustment Added.
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <AdjustmentDialog setReload={reloadPage} open={openAdjustment} setComplete={showAlert} onClose={toggleOpenAdjustment} />
        </React.Fragment>
    );
}