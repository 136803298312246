import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';

interface IDropdownProps {
    id: string | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

export default function TeamDropdown(props: IDropdownProps) {
    const [list, setList] = React.useState<string[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setLoaded(false);
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const response = await fetch('Team/GetTeamNames', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setList(data);
        setLoaded(true);
    }

    const items = list.map((item, index) =>
        <MenuItem key={index} value={item}>{item}</MenuItem>);

    return (
        <TextField
            select
            disabled={props.disabled}
            label="Team"
            name="teamId"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={props.onChange}
            value={props.id}
            InputProps={{
                disabled: !loaded || props.disabled,
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                )
            }}
        >
            <MenuItem value=""><em>None</em></MenuItem>
            {items}
        </TextField>
    );
}