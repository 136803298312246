import GetContactsViewModel from './ViewModels/GetContactsViewModel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PermissionCheck } from '../Contexts/PermissionCheck';
interface IContactsTableRow {
    contact: GetContactsViewModel;
    editContact: (id: number) => void;
    deleteContact: (id: number) => void;
    isAccount: boolean;
    setAccountPrimary: (id: number) => void;
    setClientPrimary: (id: number) => void;
    permission: string;
}

export default function ContactsTableRow(props: IContactsTableRow) {


    const edit = () => {
        props.editContact(props.contact.id);
    }
    const deleteContact = () => {
        props.deleteContact(props.contact.id);
    }
    const setAccountPrimary = () => {
        props.setAccountPrimary(props.contact.id);
    }
    const setClientPrimary = () => {
        props.setClientPrimary(props.contact.id);
    }
    return (
        <TableRow>
            {props.isAccount ?
                <TableCell align="center">
                    <Checkbox checked={props.contact.accountPrimary} onChange={setAccountPrimary}></Checkbox>
                </TableCell>
                :
                props.contact.account === "Client Contact" ?
                    <TableCell align="center">
                        <Checkbox checked={props.contact.clientPrimary} onChange={setClientPrimary}></Checkbox>
                    </TableCell>
                    :
                    <TableCell align="center">-</TableCell>
            }
            <TableCell>{props.contact.account}</TableCell>
            <TableCell>{props.contact.firstName}</TableCell>
            <TableCell>{props.contact.lastName}</TableCell>
            <TableCell>{props.contact.phone}</TableCell>
            <TableCell>{props.contact.email}</TableCell>
            <TableCell>{props.contact.jobTitle}</TableCell>
            <PermissionCheck permission={props.permission}>
                <TableCell>
                    <IconButton color="primary" onClick={edit}><EditIcon /></IconButton>
                    <IconButton color="secondary" onClick={deleteContact}><DeleteForeverIcon /></IconButton>
                </TableCell>
            </PermissionCheck>
        </TableRow>
    );
}