import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { ChangeEvent } from "react";
import ExecutiveAvailabilityFilterViewModel from "./ViewModels/ExecutiveAvailabilityFilterViewModel";

interface IExecutiveAvailabilityFilter {
    filters: ExecutiveAvailabilityFilterViewModel
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    teamsList: string[];
    onFilter: () => void; 
}

export default function ExecutiveAvailabilityFilter(props: IExecutiveAvailabilityFilter) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <TextField
                    id="filterTeam"
                    select
                    label="Filter Team"
                    value={props.filters.filterTeam}
                    onChange={props.handleChange}
                    fullWidth
                    size="small"
                    name="filterTeam"
                >
                    <MenuItem key={-1} value={""}>
                        <i>Clear Filter</i>
                    </MenuItem>
                    {props.teamsList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={2}>
                <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    onClick={props.onFilter}
                >
                    Filter
                </Button>
            </Grid>
        </Grid>
    );

}