import React, { Component } from 'react';
import Button from '@mui/material/Button';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Link as RouterLink } from 'react-router-dom';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        const link = React.forwardRef((props, ref) => (
            <RouterLink ref={ref} to={logoutPath} {...props} />
        ));

        return (
            <Button color="inherit" component={link}>Logout</Button>
        );

    }

    anonymousView(registerPath, loginPath) {
        const link = React.forwardRef((props, ref) => (
            <RouterLink ref={ref} to={loginPath} {...props} />
        ));

        return (
            <Button color="inherit" component={link}>Login</Button>
        );
    }
}
