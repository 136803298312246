import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Typography from '@mui/material/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import ClientCatchUpFormViewModel from "./ClientCatchUpFormViewModel";

export interface IProps {
    data: ClientCatchUpFormViewModel;
    handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSliderChange: (name: string, value: number) => void;
    isAds: boolean;
}

export default function ClientCatchUp2({ data, handleTextChange, handleSliderChange, isAds }: IProps) {
    const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        handleSliderChange("leadQuality", value as number);
    }
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">Section 2</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Catch Up Call</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Analysis of the previous week: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {isAds ? 'How many impressions have we had?' +

                            'What is the Click Through Rate (CTR)?' +

                            'What is the conversion rate?' +

                            'What is our current cost per click?' +

                            'Has there been an increase/decrease from the last time you spoke?'
                            :
                            'How do the stats differ from when you last spoke? How many leads did you generate? Are the leads from a particular message or campaign?'
                        }

                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="synopsis"
                        value={data.synopsis}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{isAds ? "Are we generating any leads yet? If so, get an update on these!*" : "Lead Quality*" }</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        If last time you spoke, they mentioned a lead has a call scheduled. Was that call sat? How did it go? What are the next steps from here?

                        If a call goes particularly well - Why? What is your follow up process?

                        If a call doesn't go well - why?

                        If they don't follow the lead up - why? Make it an action point for them
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="leadUpdates"
                        value={data.leadUpdates}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Lead Quality*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Great so based on what you've just said there and the updates on the leads you have given me. What would you say with 1 being the worst and 10 being the best, the lead quality is at the moment for the campaign?"

                        Unless it is a 10, it can always be improved. Mention what you are going to action to change it!

                        Has it increased or decreased since last time?
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Box sx={{ paddingTop: '32px' }}>
                        <Slider
                            aria-label="Lead Quality"
                            value={data.leadQuality}
                            onChange={onSliderChange}
                            step={1}
                            marks
                            min={1}
                            max={10}
                            valueLabelDisplay="on"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Comments about the lead quality:*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="leadQualityCheckIn"
                        value={data.leadQualityCheckIn}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Quantity of leads:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="leadQuantity"
                        value={data.leadQuantity}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Based on the above are there any amendments for the campaign:*</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="introduction"
                        value={data.introduction}
                        onChange={handleTextChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}