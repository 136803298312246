import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import CommissionViewModel from '../ViewModels/CommissionViewModel';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import CommissionTableRow from './CommissionTableRow';
import Datepicker from '../Utilities/DatePicker';
import AccountExecutiveDropdown from '../Dropdowns/AccountExecutiveDropdown';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {PermissionsContext} from '../Contexts/PermissionsContext';

interface IProps {
    reload: boolean;
    reloadPage: (event: any) => void;
}

type Order = 'asc' | 'desc';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        }
    })
);

export default function CommissionTable(props: IProps) {
    const classes = useStyles();
    const [commissions, setCommissions] = React.useState<CommissionViewModel[]>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState("ExecId");
    const [date, setDate] = React.useState<Date | null>(new Date());
    const [exec, setExec] = React.useState<string | null>(null);
    const [finalisedFilter, setFinalisedFilter] = React.useState<string>("");
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CommissionViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    React.useEffect(() => {
        getCommissions(true);
    }, [props.reload, orderBy, order, date, exec, finalisedFilter]);

    const getCommissions = (reloadEverything: boolean) => {
        var additionalParams = "";
        if (date != null) additionalParams += `&date=${date.toISOString()}`;
        if (exec != null) additionalParams += `&exec=${exec}`;
        if (finalisedFilter != "") additionalParams += `&finalised=${finalisedFilter}`;
        fetch(`Commission/GetCommissions?orderBy=${orderBy}&order=${order}` + additionalParams)
            .then((response) => {
                return response.json();
            })
            .then((data: CommissionViewModel[]) => {
                if (reloadEverything) {
                    setCommissions(data as CommissionViewModel[]);
                    props.reloadPage(false);
                }
            })
    }
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    }

    const onChangeDate = (date: Date | null) => {
        setDate(date);
    }
    const onChangeExec = (event: any) => {
        console.log(event.target.value)
        setExec(event.target.value);
    }
    const onChangeFinalised = (event: any) => {
        setFinalisedFilter(event.target.value);
    }
    const sum = commissions.reduce((sum, current) => sum + current.amount, 0);
    return (
        <React.Fragment>
            <Grid container spacing={2} alignItems="space-between">
                <Grid item xs={3}>
                    <Datepicker
                        monthOnly={true}
                        name="date"
                        label="Date"
                        date={date}
                        onChange={(date: Date | null) => onChangeDate(date)}
                        disableFuture={false}
                        disablePast={false}
                        disabled={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        select
                        label="Finalised"
                        name="finalised"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        onChange={onChangeFinalised}
                        value={finalisedFilter}
                    >
                        <MenuItem value={""}><em>None</em></MenuItem>
                        <MenuItem value={"true"}>True</MenuItem>
                        <MenuItem value={"false"}>False</MenuItem>
                    </TextField>
                </Grid>
                {checkHasPermission("") ?
                    <Grid item xs={6}>
                        <AccountExecutiveDropdown id={exec} onChange={onChangeExec} disabled={false} />
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid item xs={3} />
                        <Grid item xs={3}>
                            <TextField
                                label="Total"
                                name="total"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={sum}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </React.Fragment>
                }
                <Grid item xs>
                    <React.Fragment>
                        <TableContainer style={{ overflow: 'none', maxHeight: "75vh" }}>
                            <Table stickyHeader>
                                <TableHead >
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Exec" ? order : false}><TableSortLabel
                                            active={orderBy === "Exec"}
                                            direction={orderBy === "Exec" ? order : 'asc'}
                                            onClick={createSortHandler("Exec")}
                                        >
                                            Executive
                                            {orderBy === "Exec" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Account" ? order : false}><TableSortLabel
                                            active={orderBy === "Account"}
                                            direction={orderBy === "Account" ? order : 'asc'}
                                            onClick={createSortHandler("Account")}
                                        >
                                            Account
                                            {orderBy === "Account" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell align="right" style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Amount" ? order : false}><TableSortLabel
                                            active={orderBy === "Amount"}
                                            direction={orderBy === "Amount" ? order : 'asc'}
                                            onClick={createSortHandler("Amount")}
                                        >
                                            Amount
                                            {orderBy === "Amount" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Period" ? order : false}><TableSortLabel
                                            active={orderBy === "Period"}
                                            direction={orderBy === "Period" ? order : 'asc'}
                                            onClick={createSortHandler("Period")}
                                        >
                                            Period
                                            {orderBy === "Period" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "EndOfPeriodDate" ? order : false}><TableSortLabel
                                            active={orderBy === "EndOfPeriodDate"}
                                            direction={orderBy === "EndOfPeriodDate" ? order : 'asc'}
                                            onClick={createSortHandler("EndOfPeriodDate")}
                                        >
                                            End Of Period
                                            {orderBy === "EndOfPeriodDate" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Type" ? order : false}><TableSortLabel
                                            active={orderBy === "Type"}
                                            direction={orderBy === "Type" ? order : 'asc'}
                                            onClick={createSortHandler("Type")}
                                        >
                                            Type
                                            {orderBy === "Type" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>

                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Notes" ? order : false}><TableSortLabel
                                            active={orderBy === "Notes"}
                                            direction={orderBy === "Notes" ? order : 'asc'}
                                            onClick={createSortHandler("Notes")}
                                        >
                                            Notes
                                            {orderBy === "Notes" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} sortDirection={orderBy === "Finalised" ? order : false}><TableSortLabel
                                            active={orderBy === "Finalised"}
                                            direction={orderBy === "Finalised" ? order : 'asc'}
                                            onClick={createSortHandler("Finalised")}
                                        >
                                            Finalised
                                            {orderBy === "Finalised" ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {commissions && commissions.map((row: CommissionViewModel) => {
                                        return (<CommissionTableRow key={row.id} account={row} />);
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
