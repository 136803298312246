import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ConnectionDialog from '../ConnectionDialog';
import { ActivityListViewModel } from '../../ViewModels/ActivityDateViewModel';
import MessageDialog from '../MessageDialog';
import DenseTableCell from '../../Utilities/DenseTableCell';
import FollowUpDialog from '../FollowUpDialog';
import PageInviteDialog from '../PageInviteDialog';
import PaidInMailDialog from '../PaidInMailDialog';
import FreeInMailDialog from '../FreeInMailDialog';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import LockActivityDialog from '../LockActivityDialog';
import { PermissionCheck } from '../../Contexts/PermissionCheck';

interface IRowProps {
    activity: ActivityListViewModel;
    refreshList: () => void;
    active: boolean;
}

export default function ActivityRow(props: IRowProps) {
    const [openConnDialog, setConnOpen] = React.useState(false);
    const [openMessageDialog, setMessageOpen] = React.useState(false);
    const [openFollowDialog, setFollowOpen] = React.useState(false);
    const [openPaidInMailDialog, setPaidInMailOpen] = React.useState(false);
    const [openFreeInMailDialog, setFreeInMailOpen] = React.useState(false);
    const [openPageDialog, setPageOpen] = React.useState(false);
    const [lockDialogOpen, setLockDialogOpen] = React.useState(false);

    const today = new Date(props.activity.date).getDate() === new Date().getDate();
    

    const openConnActivity = () => {
        setConnOpen(!openConnDialog);
    }
    const openMessActivity = () => {
        setMessageOpen(!openMessageDialog);
    }
    const openFollow = () => {
        setFollowOpen(!openFollowDialog);
    }
    const openPage = () => {
        setPageOpen(!openPageDialog);
    }
    const openFreeInMail = () => {
        setFreeInMailOpen(!openFreeInMailDialog);
    }
    const openPaidInMail = () => {
        setPaidInMailOpen(!openPaidInMailDialog);
    }
    const openLockDialog = () => {
        setLockDialogOpen(!lockDialogOpen);
    }
    const handleDialogClose = (refresh: boolean) => {
        setConnOpen(false);
        setMessageOpen(false);
        setFollowOpen(false);
        setPageOpen(false);
        setFreeInMailOpen(false);
        setPaidInMailOpen(false);
        setLockDialogOpen(false);
        if (refresh) {
            props.refreshList();
        }
    }

    const connections = (props.activity.connections !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.connections}
                <PermissionCheck permission="Actions.UpdateLockedConnections">
                    <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openConnActivity}><Edit /></IconButton>
                </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openConnActivity}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const messages = (props.activity.messages !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.messages}
                <PermissionCheck permission="Actions.UpdateLockedMessages">
                    <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openMessActivity}><Edit /></IconButton>
                </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openMessActivity}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const followUps = (props.activity.followUps !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.followUps}
                <PermissionCheck permission="Actions.UpdateLockedFollowups">
                    <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openFollow}><Edit /></IconButton>
                </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openFollow}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const pageInvites = (props.activity.pageInvites !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.pageInvites}
            <PermissionCheck permission="Actions.UpdateLockedPageInvites">
                <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openPage}><Edit /></IconButton>
            </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openPage}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const freeInMails = (props.activity.freeInMails !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.freeInMails}
            <PermissionCheck permission="Actions.UpdateLockedFreeInMails">
                <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openFreeInMail}><Edit /></IconButton>
            </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openFreeInMail}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const paidInMails = (props.activity.paidInMails !== 0 || props.activity.locked) ?
        <DenseTableCell align="right">
            {props.activity.paidInMails}
            <PermissionCheck permission="Actions.UpdateLockedPaidInMails">
                <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openPaidInMail}><Edit /></IconButton>
            </PermissionCheck>
        </DenseTableCell>
        :
        <DenseTableCell align="right">
            -
            {props.active && <IconButton style={{ marginLeft: 8 }} color={today ? "primary" : "secondary"} size="small" onClick={openPaidInMail}><AddCircle /></IconButton>}
        </DenseTableCell>;

    const activityId = props.activity.id === 0 ? undefined : props.activity.id;
    const followId = props.activity.followId === 0 ? undefined : props.activity.followId;
    const pageId = props.activity.pageInviteId === 0 ? undefined : props.activity.pageInviteId;
    const lock = props.activity.locked ? <IconButton size="small"><LockIcon /></IconButton> : <IconButton size="small" onClick={openLockDialog}><LockOpenIcon /></IconButton>;
    return (
        <React.Fragment>
            {props.activity.submittedById !== "" ?
                <Tooltip title={props.activity.submittedBy}>
                    <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${props.activity.submittedById}/Avatar`} sx={{ width: 24, height: 24 }} />
                </Tooltip>
                :
                undefined
            }
            <TableRow>
                <DenseTableCell style={{ fontWeight: today ? "bold" : "normal" }}>{lock}{new Date(props.activity.date).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" })}</DenseTableCell>
                {connections}
                {messages}
                <DenseTableCell align="right">{props.activity.acceptanceRate}%</DenseTableCell>
                <DenseTableCell align="right">{props.activity.opportunities}</DenseTableCell>
                {followUps}
                {pageInvites}
                {freeInMails}
                {paidInMails}
            </TableRow>
            <ConnectionDialog activityId={activityId} accountId={props.activity.accountId} open={openConnDialog} close={handleDialogClose} date={props.activity.date} />
            <MessageDialog activityId={activityId} accountId={props.activity.accountId} open={openMessageDialog} close={handleDialogClose} date={props.activity.date} />
            <FollowUpDialog followUpId={followId} accountId={props.activity.accountId} open={openFollowDialog} close={handleDialogClose} date={props.activity.date} />
            <PageInviteDialog pageId={pageId} accountId={props.activity.accountId} open={openPageDialog} close={handleDialogClose} date={props.activity.date} />
            <PaidInMailDialog pageId={pageId} accountId={props.activity.accountId} open={openPaidInMailDialog} close={handleDialogClose} date={props.activity.date} />
            <FreeInMailDialog pageId={pageId} accountId={props.activity.accountId} open={openFreeInMailDialog} close={handleDialogClose} date={props.activity.date} />
            <LockActivityDialog accountId={props.activity.accountId} open={lockDialogOpen} close={handleDialogClose} date={props.activity.date} />
        </React.Fragment>
    );
}