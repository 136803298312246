import Autocomplete from "@mui/material/Autocomplete";
import {  useEffect, useState } from "react";
import { GetUserListViewModel } from "../ViewModels/LinkedInAccountViewModel";
import { TextValidator } from 'react-material-ui-form-validator';

interface IProps {
    selectedUsers: GetUserListViewModel[] | GetUserListViewModel | null;
    setSelectedUsers: React.Dispatch<React.SetStateAction<(GetUserListViewModel[])>> | React.Dispatch<React.SetStateAction<(GetUserListViewModel | null)>>;
    multiSelect: boolean;
    required: boolean;
    label: string
}


export default function UserNameMutliSelect(props: IProps) {
    const [userList, setUserList] = useState<GetUserListViewModel[]>([]);

    useEffect(() => {
        fetch('Notes/GetUserList', {
            /* headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }*/
        })
            .then(response => response.json())
            .then(data => {
                setUserList(data.value);
            })
            .catch(error => console.log(error));
        setUserList([]);
    }, []);

    const updateSelected = (_, newValue) => {
        console.log(newValue)
        props.setSelectedUsers(newValue);
    }

    return (
            <Autocomplete
            multiple={props.multiSelect}
            
                options={userList}
                getOptionLabel={(option) => option.name}
                value={props.selectedUsers}
                onChange={updateSelected}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextValidator
                        value={props.selectedUsers}
                        name="campaignName"
                        variant="outlined"
                        validators={props.required ? ['required'] : []}
                        errorMessages={props.required ? ['This field is required'] : []}
                        {...params}
                        label={props.label}
                        margin="normal"
                    />
                )}
            />
    );

}
