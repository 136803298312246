
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import authService from "../../api-authorization/AuthorizeService";
import Datepicker from "../../Utilities/DatePicker";
import LeadReportViewModel from "../../ViewModels/LeadReportViewModel";
import ReturnViewModel from "../../ViewModels/ReturnViewModel";

interface IProps {
    accountName: string;
    clientName: string;
    accountId: number;
    open: boolean;
    handleClose: () => void;
}

export default function ReportModal(props: IProps) {
    const [month, setMonth] = useState('');
    const [date, setDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [months, setMonths] = useState<string[]>([]);
    const [leads, setLeads] = useState<LeadReportViewModel[] | null>(null);

    useEffect(() => {
        const refMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let tempMonths: string[] = [];
        const curMonth = new Date().getMonth() + 1;
        for (let i = 0; i < 12; i++) {
            let index = i + curMonth;
            if (index >= 12) {
                index -= 12;
            }
            tempMonths.unshift(refMonths[index]);
        }
        setMonths(tempMonths);
    }, []);

    const onDateChange = (date: Date | null, name: string) => {
        if (name.includes("end")) {
            setEndDate(date as Date);
        } else {
            setDate(date as Date);
        }
    }

    //const lastLead = async () => {
    //    const token = await authService.getAccessToken();
    //    fetch(`Account/GetLeadReport?accountId=${props.accountId}&previous=true`, {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    })
    //        .then(response => response.json())
    //        .then((data: ReturnViewModel) => {
    //            if (data.success) {
    //                setLeads(data.value);
    //            };
    //        })
    //        .catch(error => {
    //            console.log(error);
    //        });
    //}
    const newLead = async () => {
        const token = await authService.getAccessToken();
        fetch(`Account/GetLeadReport?accountId=${props.accountId}&previous=false`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (data.success) {
                    setLeads(data.value);
                };
            })
            .catch(error => {
                console.log(error);
            });
    }

    const weeklyReport = () => {
        fetch(`PDFGenerator/CreateWeeklyReport?accountId=${props.accountId}&show=true`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const monthlyReport = () => {
        fetch(`PDFGenerator/CreateMonthlyReport?accountId=${props.accountId}&month=${month}&show=true`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const campaignReport = () => {
        fetch(`PDFGenerator/CreateCampaignReport?accountId=${props.accountId}&startDate=${date.toISOString()}&endDate=${endDate.toISOString()}&show=true`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const weeklyReportNoLogo = () => {
        fetch(`PDFGenerator/CreateWeeklyReport?accountId=${props.accountId}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const monthlyReportNoLogo = () => {
        fetch(`PDFGenerator/CreateMonthlyReport?accountId=${props.accountId}&month=${month}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const campaignReportNoLogo = () => {
        fetch(`PDFGenerator/CreateCampaignReport?accountId=${props.accountId}&startDate=${date.toISOString()}&endDate=${endDate.toISOString()}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type !== "application/problem+json") {
                    var dateTime = new Date();
                    var formattedTime = dateTime.toLocaleDateString();
                    var url = window.URL.createObjectURL(data);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = props.clientName + "_" + props.accountName + "_" + formattedTime + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
                else {
                    console.log('error', 'An internal server error occured');
                }
            });
    }
    const monthChange = (e) => {
        setMonth(e.target.value);
    }

    return (
        <Fragment>
            <Dialog
                open={props.open}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Reports</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button fullWidth variant="outlined" color="primary" onClick={weeklyReport}>Weekly Report</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="outlined" color="primary" onClick={weeklyReportNoLogo}>Weekly Report (No Logo)</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                size="small"
                                fullWidth
                                value={month}
                                onChange={monthChange}
                                placeholder="Select Month"
                            >
                                {months.map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Button disabled={month === ""} fullWidth variant="outlined" color="primary" onClick={monthlyReport}>Monthly Report</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button disabled={month === ""} fullWidth variant="outlined" color="primary" onClick={monthlyReportNoLogo}>Monthly Report (No Logo)</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Datepicker
                                date={date}
                                name="date"
                                label={"Start Date"}
                                disableFuture={false}
                                disablePast={false}
                                onChange={onDateChange}
                                disabled={false }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Datepicker
                                date={endDate}
                                name="endDate"
                                label="End Date"
                                disableFuture={false}
                                disablePast={false}
                                onChange={onDateChange}
                                disabled={ false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="outlined" color="primary" onClick={campaignReport}>Campaign Report</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="outlined" color="primary" onClick={campaignReportNoLogo}>Campaign Report (No Logo)</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="outlined" color="primary" onClick={newLead}>Today's Lead Email</Button>
                        </Grid>
                        {leads != null &&
                            <Grid item xs={12}>
                                <Paper sx={{ padding: '8px' }}>
                                    <Typography variant="body1" paragraph>Hi {props.accountName}</Typography>
                                    <Typography variant="body1" paragraph>Great news, I have generated you {leads.length} new opportunities!</Typography>
                                    <Typography variant="body1" paragraph>Please see below as well as your opportunity tracker for more details:</Typography>
                                    <Table size="small">
                                        <TableHead sx={{ backgroundColor: '#0077b5' }}>
                                            <TableRow>
                                                <TableCell sx={{ color: '#ffffff' }}>Opportunity</TableCell>
                                                <TableCell sx={{ color: '#ffffff' }}>Company</TableCell>
                                                <TableCell sx={{ color: '#ffffff' }}>Link</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {leads.map((lead, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{lead.name}</TableCell>
                                                    <TableCell>{lead.company}</TableCell>
                                                    <TableCell>{lead.link}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <br />
                                    <Typography variant="body1" paragraph>Do these leads look on target for you? Please let me know and I can make some amendments to the campaign as necessary.</Typography>
                                    <Typography variant="body1" paragraph>If you have any questions or would like any more information on the above leads please let me know.</Typography>
                                </Paper>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={props.handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}