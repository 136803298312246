import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "../ClientDiscoveryForm";

export default function ContentMarketing({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Content Marketing</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Which LinkedIn do you wish to utilise: *</Typography>
                    <Typography variant="caption">To confirm, which page do you wish to grow and utilise the company or the personal page?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="whichLinkedIn"
                        value={data.whichLinkedIn}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Admin Access: </Typography>
                    <Typography variant="caption">Only as this question, if they want to use the company page! To confirm, do you have admin access to your company Linkedin page? If they don't, I would recommend you ask them to get this ASAP or give them a quick tutorial on how they can get their CEO etc to do this.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="adminAccess"
                        value={data.adminAccess}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"
                    >
                        <MenuItem key={0} value={"Yes"}>Yes</MenuItem>
                        <MenuItem key={1} value={"No"}>No</MenuItem>
                    </SelectValidator>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Login Details: *</Typography>
                    <Typography variant="caption">"Fantastic! So what we can do now, as we have some time left on our call and to save some back and forth over email, if you give me your log in details now, I'll make sure I can get logged in ok" </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Username:</Typography>
                    <TextValidator
                        name="logInUser"
                        value={data.logInUser}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Password:</Typography>
                    <TextValidator
                        name="logInPass"
                        value={data.logInPass}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Cell/Mobile Number: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="telephone"
                        value={data.telephone}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Tone of Voice/ Writing style: *</Typography>
                    <Typography variant="caption">"The content's tone refers to the attitude you want each piece of content to convey (e.g., formal, informal, friendly, assertive, optimistic); while the writing style is related to the purpose of the content (e.g., inform, educate, persuade, describe)"</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="contentTone"
                        value={data.contentTone}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Who is your target audience?</Typography>
                    <Typography variant="caption">"Who are we trying to reach with this content"</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="targetAudience"
                        value={data.targetAudience}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What facts and emotional reasons will make people buy/convert?</Typography>
                    <Typography variant="caption">"What are the consumer pain points?"</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="factsAndReasons"
                        value={data.factsAndReasons}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What are the key points that need to be communicated to the consumer?</Typography>
                    <Typography variant="caption">"What will make buyers believe us? So have any specific points you would like to be included? This can be interesting news in your industry or highlight exciting new services you offer and their benefits to your clients/customers."</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="keyPoints"
                        value={data.keyPoints}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">What are the goals of the project? How will we measure success?</Typography>
                    <Typography variant="caption">"What are your goal (s) for this project? Increase brand awareness, activity, following?"</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="projectGoals"
                        value={data.projectGoals}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Content Exclusions</Typography>
                    <Typography variant="caption">"What is the campaign not going to cover? What messages, emotions, or features do you want to avoid?"</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="contentExclusions"
                        value={data.contentExclusions}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">American English vs British English *</Typography>
                    <Typography variant="caption">"We have clients all over the world, who have different preferences regarding spelling. Some prefer British English (e.g., Organisation, Colour, Defence); while others prefer American English (e.g., Organization, Color, Defense."</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SelectValidator
                        name="englishType"
                        value={data.englishType}
                        onChange={handleInputChange}
                        fullWidth
                        validators={['required']}
                        errorMessages={['This field is required']}
                        size="small"

                    >
                        <MenuItem key={0} value={"American"}>American English</MenuItem>
                        <MenuItem key={1} value={"British"}>British English</MenuItem>
                    </SelectValidator>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Things I need from you: *
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededBrandGuidelines} name="thingsNeededBrandGuidelines" onChange={handleInputChange} />} label="Brand Guidelines" />
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededLogo} name="thingsNeededLogo" onChange={handleInputChange} />} label="Logo" />
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededMedia} name="thingsNeededMedia" onChange={handleInputChange} />} label="Media/Imagery" />
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededResources} name="thingsNeededResources" onChange={handleInputChange} />} label="Any resources to use" />
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededUpcomingEvents} name="thingsNeededUpcomingEvents" onChange={handleInputChange} />} label="Upcoming Events" />
                        <FormControlLabel control={<Checkbox checked={data.thingsNeededKeywords} name="thingsNeededKeywords" onChange={handleInputChange} />} label="Keywords" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Description of Next Steps: *
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.nextStepsResearch} name="nextStepsResearch" onChange={handleInputChange} />} label="Once I receive everything from you, I'll go ahead and do my research on the company and start some written work" />
                        <FormControlLabel control={<Checkbox checked={data.nextStepsGraphics} name="nextStepsGraphics" onChange={handleInputChange} />} label="Create the graphics" />
                        <FormControlLabel control={<Checkbox checked={data.nextStepsMonthlyPlan} name="nextStepsMonthlyPlan" onChange={handleInputChange} />} label="Send you the monthly plan" />
                        <FormControlLabel control={<Checkbox checked={data.nextStepsPendingAproval} name="nextStepsPendingAproval" onChange={handleInputChange} />} label="Once this has been approved, we'll be ready to go" />
                        <FormControlLabel control={<Checkbox checked={data.nextStepsMonthlyCall} name="nextStepsMonthlyCall" onChange={handleInputChange} />} label="This Process we will do every month and confirm on our monthly call" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Address any final questions or concerns from The Client</Typography>
                    <Typography variant="caption">This is the first impression you are giving to The Client of their experience working with StraightIn and You, if they don't trust you now, they never will!</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="finalQuestions"
                        value={data.finalQuestions}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}