import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { SortOppReportViewModel } from '../ViewModels/ReportViewModel';
import OppReportTableRow from './OppReportTableRow';
import ResponsiveTable from '../Utilities/ResponsiveTable';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof SortOppReportViewModel;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'client', numeric: false, disablePadding: true, label: 'Client' },
    { id: 'account', numeric: false, disablePadding: true, label: 'Account' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Opportunity' },
    { id: 'companyName', numeric: false, disablePadding: false, label: 'Company' },
    { id: 'link', numeric: false, disablePadding: false, label: 'Link' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date Logged' },
    { id: 'reportStartDate', numeric: false, disablePadding: false, label: 'Report From' },
    { id: 'reportEndDate', numeric: false, disablePadding: false, label: 'Report To' },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        }
    })
);

interface ITableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof SortOppReportViewModel) => void;
    order: Order;
    orderBy: string;
}

function SortTableHead(props: ITableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof SortOppReportViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IReportProps {
    opps: SortOppReportViewModel[];
}

export default function OppReportTable(props: IReportProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof SortOppReportViewModel>('name');

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SortOppReportViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <ResponsiveTable
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="report table"
        >
            <SortTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
            />
            <TableBody>
                {props.opps && stableSort(props.opps, getComparator(order, orderBy))
                    .map((row: SortOppReportViewModel) => {
                        return (<OppReportTableRow key={row.id} opp={row} />);
                    })
                }
            </TableBody>
        </ResponsiveTable>
    );
}
