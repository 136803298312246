export default class CommissionGroupViewModel {
    id: number = 0;
    groupName: string = '';
    performanceBonus: number = 0;
    retentionBonus: number = 0;
    lossBonus: number = 0;
    gainBonus: number = 0;
    billingTarget: number | null = 0;
    renewalsTarget: number | null = 0;
    active: boolean = false;

}