import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";

interface IProgressBar {
    progress: number
    progressPercentage: number
}

export default function ProgressBar(props: IProgressBar) {
    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" color={props.progressPercentage === 100 ? "success" : "primary"} {...props} value={props.progressPercentage} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" >{`${props.progress}/500`}</Typography>
                </Box>
            </Box>
        </Fragment>
    );
}