import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { SelectValidator, TextValidator } from "react-material-ui-form-validator";
import { DropdownDataViewModel } from "../ViewModels/ReturnViewModel";
import CreateRenewalViewModel from "./ViewModels/CreateRenewalViewModel";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PercentIcon from '@mui/icons-material/Percent';

interface IProps {
    renewal: CreateRenewalViewModel;
    setRenewal: (renewal: CreateRenewalViewModel) => void;
}

export default function RenewalDataEntry(props: IProps) {
    const [agreements, setAgreements] = useState<DropdownDataViewModel[]>([]);
    const [users, setUsers] = useState<DropdownDataViewModel[]>([]);
    const [packages, setPackages] = useState<DropdownDataViewModel[]>([]);
    const [discount, setDiscount] = useState<string>("")
    const [totalValue, setTotalValue] = useState<string>("")
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetch('Renewal/GetDropdowns')
            .then(response => response.json())
            .then(data => {
                setAgreements(data.value.agreements);
                setUsers(data.value.users);
                setPackages(data.value.packages);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (props.renewal.packageId) {
            let packagePrice = packages.find(w => w.value == props.renewal.packageId)?.data;
            const discount = ((packagePrice - props.renewal.priceOffered) / packagePrice) * 100;
            setDiscount(discount.toFixed(2));
        }
    }, [props.renewal.priceOffered, props.renewal.packageId]);

    useEffect(() => {
        if (props.renewal.packageId && props.renewal.priceOffered === 0) {
            let packagePrice = packages.find(w => w.value === props.renewal.packageId)?.data;
            if (!packagePrice) {
                packagePrice = 0;
            }
            props.setRenewal({
                ...props.renewal,
                priceOffered: packagePrice
            });
        }
    }, [props.renewal.packageId]);

    useEffect(() => {
        if (props.renewal.agreementId && props.renewal.priceOffered) {
            let contractLength = agreements.find(w => w.value == props.renewal.agreementId)?.data;
            if (!contractLength) {
                contractLength = 1;
            }
            let total = contractLength * props.renewal.priceOffered;
            setTotalValue(total.toFixed(2));
        }
    }, [props.renewal.agreementId, props.renewal.priceOffered]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.setRenewal({
            ...props.renewal,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Fragment>
            {loaded &&
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <SelectValidator
                            label="Sales Person"
                            name="salesPerson"
                            variant="outlined"
                            fullWidth
                            value={props.renewal.salesPerson}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={handleChange}
                        >
                            {users.map((m) => (
                                <MenuItem key={m.value} value={m.data}>{m.label}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={3}>
                        <SelectValidator
                            label="Package"
                            name="packageId"
                            variant="outlined"
                            fullWidth
                            value={props.renewal.packageId}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={handleChange}
                        >
                            {packages.map((m) => (
                                <MenuItem key={m.value} value={m.value}>{m.label}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={3} >
                        <TextValidator
                            label="Price Offered"
                            name="priceOffered"
                            variant="outlined"
                            fullWidth
                            value={props.renewal.priceOffered}
                            validators={['required', 'isNumber']}
                            errorMessages={['This field is required', 'Needs to be a price with no decimal']}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CurrencyPoundIcon fontSize="small" /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextValidator
                            label="Discount"
                            name="discount"
                            variant="outlined"
                            fullWidth
                            value={discount}
                            inputProps={
                                {
                                    readOnly: true
                                }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start"><PercentIcon fontSize="small" /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                showToolbar={false}
                                inputFormat="dd/MM/yyyy"
                                mask="dd/MM/yyyy"
                                label="Start Date"
                                value={props.renewal.startDate}
                                onChange={(newValue: any) => {
                                    props.setRenewal({
                                        ...props.renewal,
                                        startDate: newValue as Date
                                    });
                                }}
                                renderInput={(params: any) => <TextField fullWidth variant="outlined" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextValidator
                            label="Renewal Date"
                            name="renewalDate"
                            variant="outlined"
                            fullWidth
                            value={props.renewal.renewalDate}
                            validators={['required', 'isNumber', 'maxNumber:28', 'minNumber:1']}
                            errorMessages={['This field is required', '1 - 28', '1 - 28', '1 - 28']}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectValidator
                            label="Contract Length"
                            name="agreementId"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={props.renewal.agreementId}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={handleChange}
                        >
                            {agreements.map((m) => (
                                <MenuItem key={m.value} value={m.value}>{m.label}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={3} >
                        <TextValidator
                            label="Contract Value"
                            name="totalValue"
                            variant="outlined"
                            fullWidth
                            value={totalValue}
                            inputProps={
                                {
                                    readOnly: true
                                }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CurrencyPoundIcon fontSize="small" /></InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </Fragment>
    );
}