import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { Filters, SortOppReportViewModel } from '../ViewModels/ReportViewModel';
import authService from '../api-authorization/AuthorizeService';
import OppReportTable from './OppReportTable';

interface IDailyProps {
    date: Date;
    filters: Filters;
    onOppsLoaded: (opps: SortOppReportViewModel[]) => void;
    reportLoaded: boolean;
    setReportLoaded: (loaded: boolean) => void;
}

export default function DailyOppReport(props: IDailyProps) {
    const [opps, setOpps] = React.useState<SortOppReportViewModel[]>([]);
    const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

    React.useEffect(() => {
        const getData = async () => {
            props.setReportLoaded(false);

            const token = await authService.getAccessToken();

            fetch(`Report/GetOpportunityReports?type=daily&date=${props.date.toJSON()}&client=${props.filters.client}&clientId=${props.filters.clientId}&executive=${props.filters.execId}&status=${props.filters.statusId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setOpps(data);
                    props.setReportLoaded(true);
                    props.onOppsLoaded(data);
                })
                .catch(error => { props.setReportLoaded(true); });
        }
        if (!firstLoad) {
            getData();
        }
        else {
            setFirstLoad(false);
        }
    }, [props.date, props.filters]);


    return (
        <React.Fragment>
            {!props.reportLoaded ? <LinearProgress color="primary" /> :
                <Grid container>
                    <Grid item xs={12}>
                        <OppReportTable opps={opps} />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}