import { Fragment, useEffect, useState } from 'react';
import CommissionGroupEditorDialog from './CommissionGroupEditorDialog';
import CommissionGroupTable from './CommissionGroupTable';
import CommissionGroupViewModel from './ViewModels/CommissionGroupViewModel';
import authService from '../api-authorization/AuthorizeService';
import ReturnViewModel from '../ViewModels/ReturnViewModel';


export default function CommissionGroupContainer() {
    const [editorOpen, setEditorOpen] = useState(false);
    const [cgForEdit, setCGForEdit] = useState<CommissionGroupViewModel>(new CommissionGroupViewModel());
    const [loaded, setLoaded] = useState(false);
    const [commissionGroups, setCommissionGroups] = useState<CommissionGroupViewModel[]>([]);

    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`CommissionGroup/GetCommissionGroups`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoaded(true);
                if (data.success) {
                    setCommissionGroups(data.value);
                }
                setLoaded(true);
            })
            .catch(error => {
                console.log(error);
                setLoaded(true);
            });
    }
    const getCG = async (id: number) => {
        const token = await authService.getAccessToken();

        fetch(`CommissionGroup/GetCommissionGroupForEdit?commissionGroupId=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (data.success) {
                    setCGForEdit(data.value);
                }
            })
            .catch(error => {
                console.log(error);
                console.log("test");
            });
    }
    const setEditId = (id: number) => {
        getCG(id);
        setEditorOpen(true);
    }
    const editorClose = () => {
        setEditorOpen(false);
    }
    const handleCGChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCGForEdit({
            ...cgForEdit,
            [event.target.name]: event.target.value
        });
    }
    const updateCommissionGroup = async () => {
        const token = await authService.getAccessToken();

        fetch('CommissionGroup/UpdateCommissionGroup', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(cgForEdit)
        })
            .then(response => response.text())
            .then(data => {
                editorClose();
                getData();
            })
            .catch(error => {
                console.log(error);
            });
    }
    const editActive = async (id: number, active: boolean) => {
        const token = await authService.getAccessToken();

        fetch(`CommissionGroup/UpdateActive?commissionGroupId=${id}&active=${active}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (data.success) {
                    getData();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <Fragment>
            {loaded &&
                <CommissionGroupTable
                editCommissionGroup={setEditId}
                commissionGroups={commissionGroups}
                editActive={editActive}
                />
            }
            <CommissionGroupEditorDialog
                editorOpen={editorOpen}
                editorClose={editorClose}
                commissionGroup={cgForEdit}
                handleCommissionGroupChange={handleCGChange}
                submit={updateCommissionGroup}
            />
        </Fragment>
    );
}
