import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { SortOppReportViewModel } from '../ViewModels/ReportViewModel';
import Button from '@mui/material/Button';

interface IReportProps {
    opp: SortOppReportViewModel;
}

export default function OppReportTableRow(props: IReportProps) {

    return (
        <TableRow tabIndex={-1} >
            <TableCell>{props.opp.client}</TableCell>
            <TableCell>{props.opp.account}</TableCell>
            <TableCell>{props.opp.name}</TableCell>
            <TableCell>{props.opp.companyName}</TableCell>
            <TableCell><Button color="secondary" href={props.opp.link}>Go To</Button></TableCell>
            <TableCell>{new Date(props.opp.date).toLocaleDateString("en-GB")}</TableCell>
            <TableCell>{new Date(props.opp.reportStartDate).toLocaleDateString("en-GB")}</TableCell>
            <TableCell>{new Date(props.opp.reportEndDate).toLocaleDateString("en-GB")}</TableCell>
        </TableRow>
    );
}