import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import UserDropdown from '../Dropdowns/UserDropdown';
import DropdownViewModel from '../ViewModels/DropdownViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface ITeamLeaderProps {
    users: DropdownViewModel[];
    campaignId: number;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function OpManagerDialog(props: ITeamLeaderProps) {
    const classes = useStyles();
    const [opManager, setOpManager] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    const onChange = (value: number | string, name: string, client: string) => {
        setOpManager(value as string);
    }

    const onClose = () => {
        if (!submitting) {
            setOpManager("");
            props.close(false);
        }
    }

    const submit = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();

        fetch(`Campaign/SetCampaignOpManager?campaignId=${props.campaignId}&opManagerId=${opManager}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                props.close(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Set Operations Manager</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Set the operations manager for this client.
                        {error}
                </DialogContentText>
                <UserDropdown
                    id={opManager}
                    name="opManager"
                    label="Operations Manager"
                    users={props.users}
                    onChange={onChange}
                    required={false}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                <Button onClick={submit} color="primary" disabled={submitting || opManager === ""}>
                    Submit
                    {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}