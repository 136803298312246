import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import { LinkedInAccountListViewModel } from '../ViewModels/LinkedInAccountViewModel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import Chip from '@mui/material/Chip';
import DenseTableCell from '../Utilities/DenseTableCell';
import grey from '@mui/material/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%'
        },
        default: {
            width: '100%'
        },
        noCallDue: {

        },
        callDue: {
            backgroundColor: '#ff00001f',
        },
        onboarding: {
            backgroundColor: '#0000ff1f',
        },
    })
);

interface IAccountListProps {
    account: LinkedInAccountListViewModel;
    date: Date;
    refreshList: () => void;
}

export default function AccountTableRow(props: IAccountListProps) {
    const classes = useStyles();
    const id = props.account.id;
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts/Account',
                state: { accountId: id }
            }}
            {...props} />
    ));

    const statuses = [
        { status: "Live", class: classes.green },
        { status: "At Risk", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "Suspended", class: classes.red }
    ];

    const statusClass = props.account.status.length > 0 ? statuses.find(f => f.status === props.account.status)!.class : classes.default;

    const data = new Date(props.account.actualStart).getTime() > props.date.getTime() || (props.account.agreementEnd && new Date(props.account.agreementEnd!).getTime() < props.date.getTime()) ?
        <DenseTableCell align="center" colSpan={10}>Inactive</DenseTableCell> :
        <React.Fragment>
            <DenseTableCell align="right">{props.account.connections}</DenseTableCell>
            <DenseTableCell align="right">{props.account.messages}</DenseTableCell>
            <DenseTableCell align="right">{props.account.acceptanceRate}%</DenseTableCell>
            <DenseTableCell align="right">{props.account.opportunities}</DenseTableCell>
            <DenseTableCell align="right">{props.account.followUps}</DenseTableCell>
            <DenseTableCell align="right">{props.account.withdrawnDates}</DenseTableCell>
            <DenseTableCell align="right">{props.account.pageInviteDates}</DenseTableCell>
            <DenseTableCell align="right">{props.account.freeInMails}</DenseTableCell>
            <DenseTableCell align="right">{props.account.paidInMails}</DenseTableCell>
            <DenseTableCell><Chip className={statusClass} label={props.account.status} /></DenseTableCell>
        </React.Fragment>;

    return (
        <TableRow className={props.account.status === "Onboarding" ? classes.onboarding : (props.account.noCallNoteForTwoWeeks ? classes.callDue : classes.noCallDue)}>
            <DenseTableCell>{props.account.noCallNoteForTwoWeeks ? <img src="Phone.png" width="36px" /> : ''}</DenseTableCell>
            <DenseTableCell>{props.account.name + " - " + props.account.campaignName}</DenseTableCell>
            <DenseTableCell>
                <Tooltip title={"Price: " + props.account.price}>
                    <div>{props.account.packageName}</div>
                </Tooltip>
            </DenseTableCell>
            <DenseTableCell>{props.account.country}</DenseTableCell>
            <DenseTableCell>{props.account.renewalDate}</DenseTableCell>
            <DenseTableCell>{props.account.agreementLength}</DenseTableCell>
            <DenseTableCell>{props.account.agreementEnd ? new Date(props.account.agreementEnd).toLocaleDateString("en-GB") : 'No End Date'}</DenseTableCell>
            {data}
            <DenseTableCell align="right"><Button variant="contained" color="secondary" size="small" component={viewButton} >View</Button></DenseTableCell>
        </TableRow>
    );
}