import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';


export default function ManagementReportsPage() {

    const EODLink =
        forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
            <RouterLink to={"/EOD"} ref={ref} {...itemProps} />
        ));

    const ExecutiveAvailabilityLink =
        forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
            <RouterLink to={"/ExecutiveAvailability"} ref={ref} {...itemProps} />
        ));

       

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h4">Management Reports Page</Typography>
            </Grid>
            <Grid item xs={2}>
                <Button fullWidth variant="contained" component={EODLink}>End of Day Report</Button>
            </Grid>
            <Grid item xs={2}>
                <Button fullWidth variant="contained" component={ExecutiveAvailabilityLink}>Executive Availability Report</Button>
            </Grid>
        </Grid>
    );
}