import TeamAvailabilityViewModel from './ViewModels/TeamAvailabilityViewModel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Fragment } from 'react';
//import ExecutiveAvailabilityViewModel from './ViewModels/ExecutiveAvailabilityViewModel';


interface ITeamAvailabilityTableRow {
    team: TeamAvailabilityViewModel;
}

export default function TeamAvailabilityTableRow(props: ITeamAvailabilityTableRow) {


    const weeklyTotal = props.team.weeklyAccountsTotal.map((item) =>
        <TableCell><b>{item}</b></TableCell>
    );

    const execs = props.team.executives.map((exec) =>
        <TableRow>
            <TableCell>{exec.executiveName}</TableCell>
            {exec.weeklyAccounts.map((item, index) =>
                <TableCell sx={item < 15 ? { backgroundColor: '#ea4335', color: "white" } : item > 18 ? { backgroundColor: '#fbbc04', color: "white" } : { backgroundColor: '#34a853', color: "white" }}>{item + " - £" + exec.weeklyValue[index]}</TableCell>
            )}
        </TableRow>
    );

    return (
        <Fragment>
            <TableRow>
                <TableCell><b>{props.team.teamName}</b></TableCell>
                {weeklyTotal}
            </TableRow>
            {execs}
        </Fragment>
    );
}