import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import CategoryTableRow from './CategoryTableRow';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function CategoryTable() {
    const [categoryList, setCategoryList] = React.useState<DropdownViewModel[]>([]);
    const [newCategory, setNewCategory] = React.useState<string>("");


    React.useEffect(() => {
        init();
    }, []);

    const init = () => {
        fetch('ApprovalRequest/GetRequestCategories')
            .then(response => response.json())
            .then(data => {
                console.log("Approval request");
                console.log(data);
                if (data.success) {
                    setCategoryList(data.value);
                }
            });
    };
    const deleteCategory = (id: string | number) => {
        fetch(`ApprovalRequest/DeleteCategory?id=${id}`)
            .then(response => response.json())
            .then(data => {
                init();
            });
    }
    const addCategory = () => {
        fetch(`ApprovalRequest/AddCategory?category=${newCategory}`)
            .then(response => response.json())
            .then(data => {
                init();
                setNewCategory("");
            });
    }
    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewCategory(event.target.value);
    }
    const items = categoryList.map((item, index) =>
        <CategoryTableRow key={index} category={item} deleteCategory={deleteCategory} />
    );
    return (
        <Grid container spacing={1} alignItems="center" >
            <Grid item xs={12}>
                <TableContainer style={{ maxHeight: "75vh" }}>
                    <Table aria-label="Approval Categories" stickyHeader >
                        <TableHead >
                            <TableRow>
                                <TableCell>Request Category</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    label="New Category"
                    name="newCategory"
                    variant="outlined"
                    fullWidth
                    value={newCategory}
                    size="small"
                    onChange={handleCategoryChange}
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    size="small"
                    color="primary"
                    onClick={addCategory}
                    variant="outlined"
                    fullWidth
                >
                    Add
                </Button>
            </Grid>

        </Grid>

    );
}