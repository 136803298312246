import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import RegisterConfirmation from './components/Admin/RegisterConfirmation';
import CampaignList from './components/Campaigns/CampaignList';
import TeamList from './components/Teams/TeamList';
import CreateCampaign from './components/Campaigns/CreateCampaign';
import AccountList from './components/Accounts/AccountList';
import CreateAccount from './components/Accounts/CreateAccount';
import ReportDashboard from './components/Reports/ReportDashboard';
import Account from './components/Accounts/AccountPage/Account';
import UpdateUser from './components/Admin/UpdateUser';
import AdminDashboard from './components/Admin/AdminDashboard';
import PowerBI from './components/PowerBITest/PowerBI';
import Commissions from './components/Commissions/Commissions';
import Campaign from './components/Campaigns/CampaignPage/Campaign';
import CustomerSatisfactionSurvey from './components/CustomerSatisfactionSurvey/CustomerSatisfactionSurvey';
import Profile from './components/Profile/Profile';
import SuperAdminPowerBI from './components/PowerBITest/SuperAdminPowerBI';
import Dashboard from './components/PowerBITest/Dashboard/DashboardContainer';
import OnboardingContainer from './components/Onboarding/OnboardingContainer';
import UserManagementContainer from './components/Users/UserManagementContainer';
import AccountOnboardingContainer from './components/AccountOnboarding/AccountOnboardingContainer';
import ClientDiscoveryForm from './components/ClientDiscoveryForm/ClientDiscoveryForm';
import DataAnalysisContainer from './components/DataAnalysis/DataAnalysisContainer';
import ExecutiveAvailabilityContainer from './components/ExecutiveAvailability/ExecutiveAvailabilityContainer';
import ManagementReportsPage from './components/ManagementReports/ManagementReportsPage';
import ClientCatchUpForm from './components/ClientCatchUpForm/ClientCatchUpForm';
import PermissionsDashboard from './components/Permissions/PermissionsDashboard';
import PermissionsProvider from './components/Contexts/PermissionsContext';
import ApprovalRequestAdmin from './components/ApprovalRequests/ApprovalRequestAdmin';
import CommissionGroupContainer from './components/CommissionGroupEditor/CommissionGroupContainer';
declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}


const theme = createTheme(adaptV4Theme({
    palette: {
        primary: { main: /*'#0077B5'*/ '#0077b5' },
        secondary: {
            main: /*'#FF7052'*/'#ed6f35',
            contrastText: '#fff'
        },
        background: { default: '#ffffff' }
    }
}));

export default function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <PermissionsProvider>
                    <Layout>
                        <Switch>
                            <AuthorizeRoute exact path='/' component={Home} />
                            <AuthorizeRoute exact path='/Clients' component={CampaignList} />
                            <AuthorizeRoute exact path='/Clients/Client' component={Campaign} />
                            <AuthorizeRoute exact path='/Clients/Create' component={CreateCampaign} />
                            <AuthorizeRoute exact path='/Accounts' component={AccountList} />
                            <AuthorizeRoute exact path='/Accounts/Account' component={Account} />
                            <AuthorizeRoute exact path='/Accounts/Create' component={CreateAccount} />
                            <AuthorizeRoute exact path='/Reports' component={ReportDashboard} />
                            <AuthorizeRoute exact path='/OpportunityReports' component={ReportDashboard} />
                            <AuthorizeRoute exact path='/Admin' component={AdminDashboard} />
                            <AuthorizeRoute exact path='/Admin/EditUser' component={UpdateUser} />
                            <AuthorizeRoute exact path='/Permissions' component={PermissionsDashboard} />
                            <AuthorizeRoute exact path='/PowerBI' component={PowerBI} />
                            <AuthorizeRoute exact path='/Teams' component={TeamList} />
                            <AuthorizeRoute exact path='/Commissions' component={Commissions} />
                            <AuthorizeRoute exact path='/CommissionGroups' component={CommissionGroupContainer} />
                            <AuthorizeRoute exact path='/SuperAdminPowerBI' component={SuperAdminPowerBI} />
                            <AuthorizeRoute exact path='/Profile' component={Profile} />
                            <AuthorizeRoute exact path='/Dashboard' component={Dashboard} />
                            <AuthorizeRoute exact path='/Holding' component={OnboardingContainer} />
                            <AuthorizeRoute exact path='/Users' component={UserManagementContainer} />
                            <AuthorizeRoute exact path='/Onboarding' component={AccountOnboardingContainer} />
                            <AuthorizeRoute exact path='/EOD' component={DataAnalysisContainer} />
                            <AuthorizeRoute exact path='/ManagementReports' component={ManagementReportsPage} />
                            <AuthorizeRoute exact path='/ExecutiveAvailability' component={ExecutiveAvailabilityContainer} />
                            <AuthorizeRoute exact path='/DataCapture/ClientDiscoveryForm' component={ClientDiscoveryForm} />
                            <AuthorizeRoute exact path='/DataCapture/ClientCatchUpForm' component={ClientCatchUpForm} />
                            <AuthorizeRoute exact path='/ApprovalRequests' component={ApprovalRequestAdmin} />
                            <Route path='/RegisterConfirmation/:userId/:code' component={RegisterConfirmation} />
                            <Route path='/CustomerSatisfactionSurvey/:surveyId' component={CustomerSatisfactionSurvey} />
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} ><ApiAuthorizationRoutes /></Route>
                        </Switch>
                    </Layout>
                </PermissionsProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
