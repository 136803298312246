import Grid from '@mui/material/Grid';
import AccountListViewModel from './ViewModels/AccountListViewModel';
import AccountTable from './AccountTable';

interface IAccountListProps {
    roles: string | string[];
    refresh: (refresh: boolean) => void;
    pendingAccounts: AccountListViewModel[];
}
export default function AccountsList(props: IAccountListProps) {
    

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
                <AccountTable accounts={props.pendingAccounts} refresh={props.refresh} roles={props.roles} />
            </Grid>
        </Grid>
    );
}