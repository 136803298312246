import * as React from 'react';
import TextField from '@mui/material/TextField';
import { TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@mui/material/Autocomplete';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

interface IDropdownProps {
    id: string;
    label: string;
    name: string;
    small?: boolean;
    users: DropdownViewModel[];
    onChange: (value: string | number, name: string, client: string) => void;
    required: boolean;
}

export default function UserDropdown(props: IDropdownProps) {
    const [search, setSearch] = React.useState('');
    const [value, setvalue] = React.useState('');

    const handleChange = (event: any, newValue: any) => {
        if (props) {
            const client = props.users && props.users.find(f => f.value === newValue);
            props.onChange(client?.id ?? "", props.name, client?.value ?? '');
            setvalue(client?.value ?? '');
        }
    }

    const items = props.users.map((item) => item.value);
    return (
        <React.Fragment>
            {
                props.required ?
                    <Autocomplete
                        size={ props.small ? "small":undefined}
                        id="userDropdown"
                        value={value}
                        options={items}
                        onChange={handleChange}
                        inputValue={search}
                        onInputChange={(event: any, newInputValue: any) => {
                            setSearch(newInputValue);
                        }}
                        renderOption={(p: any, option: string) => {
                            const user = props.users.find(f => f.value == option);

                            return (
                                <MenuItem {...p}>
                                    <Avatar alt="avatar" src={user !== undefined ? `https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${user.id}/Avatar` : ""} style={{ marginRight: "5px" }}>
                                        {option.substring(0, 2)}
                                    </Avatar>
                                    {option}
                                </MenuItem>);
                        }}
                        renderInput={(params) => {
                            const user = props.users.find(f => f.value == value);
                            return (<React.Fragment>
                                <TextValidator
                                    {...params}
                                    variant="outlined"
                                    placeholder="Search..."
                                    label={props.label}
                                    name={props?.name}
                                    margin="normal"
                                    fullWidth
                                    value={value}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            value === ""||props.small?
                                                undefined
                                                :
                                                <InputAdornment position="start">
                                                    <Avatar alt="avatar" src={user !== undefined ? `https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${user.id}/Avatar` : ""} style={{ marginRight: "5px" }}>
                                                        {value.substring(0, 2)}
                                                    </Avatar>
                                                </InputAdornment>
                                        ),
                                    }}
                                />
                            </React.Fragment>);
                        }}
                    />
                    :
                    <Autocomplete
                        size={props.small ? "small" : undefined}
                        id="userDropdown"
                        value={value}
                        options={items}
                        onChange={handleChange}
                        inputValue={search}
                        onInputChange={(event: any, newInputValue: any) => {
                            setSearch(newInputValue);
                        }}
                        renderOption={(p: any, option: string) => {
                            const user = props.users.find(f => f.value == option);
                            
                            return (
                                <MenuItem {...p}>
                                    <Avatar alt="avatar" src={user !== undefined ? `https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${user.id}/Avatar` : ""} style={{ marginRight: "5px" }}>
                                        {option.substring(0, 2)}
                                        </Avatar>
                                    {option}
                                </MenuItem>);
                        }}
                        renderInput={(params) => {
                            const user = props.users.find(f => f.value == value);
                            return (<TextField
                                {...params}
                                label={props.label}
                                name={props.name}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={value}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        value === "" || props.small ?
                                            undefined
                                            :
                                            <InputAdornment position="start">
                                                <Avatar alt="avatar" src={user !== undefined ? `https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${user.id}/Avatar` : ""} style={{ marginRight: "5px" }}>
                                                    {value.substring(0, 2)}
                                                </Avatar>
                                            </InputAdornment>
                                    ),
                                }}
                            />);
                        }}
                    />
            }
        </React.Fragment>
    );
}