export default class ClientDiscoveryFormViewModel {
    public campaignName = '';
    public isVelocity = false;
    public isBoost = false;
    public isAds = false;
    public isContent = false;
    public isOther = false;
    public complete = false;
    public campaignType = '';
    public clientURL = '';
    public websiteViewed = '';

    public clientEmail: string = '';
    public finalQuestions: string = '';
    public briefedBy: string = '';
    public companyName: string = '';
    public paymentLinkCompleted: string = '';
    public targetAudience: string = '';
    public thingsNeededBrandGuidelines: boolean = false;
    public thingsNeededLogo: boolean = false;
    public thingsNeededMedia: boolean = false;
    public thingsNeededResources: boolean = false;
    public thingsNeededUpcomingEvents: boolean = false;
    public thingsNeededKeywords: boolean = false;
    public nextStepsResearch: boolean = false;
    public nextStepsGraphics: boolean = false;
    public nextStepsMonthlyPlan: boolean = false;
    public nextStepsPendingAproval: boolean = false;
    public nextStepsMonthlyCall: boolean = false;

    public linkedInUrl: string = '';
    public linkedInAdvertisingObj: string = '';
    public adFormat: string = '';
    public websiteTracking: string = '';

    // Ads section
    public lengthGoing: string = '';
    public mainProductOrService: string = '';

    public targetLocations: string = '';
    public experienceLength: string = '';
    public companySize: string = '';

    public companyTurnOver: string = '';
    public education: string = '';
    public skills: string = '';
    public hobbiesAndInterests: string = '';
    public ageAndGender: string = '';

    public linkedInPageFeedback: string = '';

    public nativeLeadGenForms: boolean = false;
    public websiteConversions: boolean = false;
    public imageOrLogoIncluded: boolean = false;
    public conversionTracking: string = '';
    public performanceReview: string = '';
    public websiteSpeed: string = '';

    // Rest
    public clientBase = '';
    public vatNumber = '';
    public reasonForCall = '';
    public mainPointOfContact = '';
    public segmentation = '';
    public articleNotContent = '';
    public clientDeepDive = '';
    public leadGeneration = '';
    public orderValueAndCycle = '';
    public initialObjections = '';
    public desiredOutcome = '';
    public targetGeography = '';
    public targetIndustry = '';
    public targetSizeSelf = false;
    public targetSize1_10 = false;
    public targetSize11_50 = false;
    public targetSize51_200 = false;
    public targetSize201_500 = false;
    public targetSize501_1000 = false;
    public targetSize1001_5000 = false;
    public targetSize5001_10000 = false;
    public targetSize10000plus = false;
    public exampleClients = '';
    public avoidClients = '';
    public competitors = '';
    public targetJobTitle = '';
    public painPoint = '';
    public dreamClient = '';
    public leadOutcome = '';
    public calendarLink = '';
    public messageTone = '';
    public packageElementConversationManagement = false;
    public packageElementCRMIntegration = false;
    public packageElementProspectEngagement = false;
    public packageElementCompanyPageFollowers = false;
    public packageElementArticle = false;
    public monthlyArticleContact = '';
    public adsReferral = "";
    public emailCampaign = "";

    public velocityPurchase = false;
    public velocityWarmUp = false;
    public velocityForwarding = false;
    public velocityAltEmail = false;
    public emailDomain = '';
    public blacklist = '';
    public velocityQuestions = '';

    public startDate = '';
    public accountName = '';
    public logInUser = '';
    public logInPass = '';
    public telephone = '';
    public nextStepsLogInDetails = false;
    public nextStepsSearchSetUp = false;
    public nextStepsProfileReview = false;
    public nextStepsGoLive = false;
    public nextStepsReview = false;
    public recurringCall = '';
    public communicationChannel = '';
    public customerSurvey = '';
    public nextStepQuestions = '';

    public whichLinkedIn = '';
    public adminAccess = '';
    public contentTone = '';
    public topics = '';
    public factsAndReasons = '';
    public keyPoints = '';
    public projectGoals = '';
    public contentExclusions = '';
    public englishType = '';

    public accessCompanyPage = false;
    public firstMonth = false;
    public assetDesign = false;
    public requiredEngagement = false;
}