import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { UserAccountListViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import ExecutiveDialog from '../ExecutiveDialog';
import ExecutiveListItem from './ExecutiveListItem';
import Grow from '@mui/material/Grow';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';

interface IListProps {
    accountId: number;
    users: DropdownViewModel[];
}

export default function ExecutivesList(props: IListProps) {
    const [openExecutiveDialog, setExecutiveOpen] = React.useState(false);
    const [execs, setExecs] = React.useState<UserAccountListViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetExecutivesOnAccount?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setExecs(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Exec list error: ' + error);
                setLoaded(true);
            });
    }, [props.accountId]);
    React.useEffect(() => { getData(); }, [getData]);

    const openExecutive = () => {
        setExecutiveOpen(!openExecutiveDialog);
    }

    const handleDialogClose = (refresh: boolean) => {
        setExecutiveOpen(false);

        if (refresh) {
            getData();
        }
    }

    const items = execs.map((exec, index) => {
        return <ExecutiveListItem key={'exec' + index} exec={exec} refresh={getData} />;
    });

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Executives</Typography>
                </Grid>
                <Grow in={loaded}
                    {...(loaded ? { timeout: 1000 } : {})}>
                    <Grid item xs={12}>
                        <Button variant="outlined" fullWidth color="secondary" onClick={openExecutive}>Add Executive</Button>
                    </Grid>
                </Grow>
            </Grid>
            {!loaded ? <LinearProgress /> :
                <Grow in={loaded}
                    {...(loaded ? { timeout: 2000 } : {})}>
                    <div>
                        <Grid container spacing={2}>
                            {items}
                        </Grid>
                        <ExecutiveDialog accountId={props.accountId} open={openExecutiveDialog} close={handleDialogClose} users={props.users}/>
                    </div>
                </Grow>
            }
        </React.Fragment>
    );
}