import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ApprovalRequestContainer from './ApprovalRequestContainer';
import CategoryTable from './CategoryTable';


export default function ApprovalRequestAdmin() {
    
    return (
        <Grid container spacing={1} sx={{ paddingTop: '8px' }}>
            <Grid item xs={2}>
                <Paper square sx={{ padding: '8px' }}>
                    <CategoryTable/>
                </Paper>
            </Grid>
            <Grid item xs={10}>
                <Paper square sx={{ padding: '8px' }}>
                    <ApprovalRequestContainer />
                </Paper>
            </Grid>
        </Grid>
    );
}
