import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CommissionViewModel from '../ViewModels/CommissionViewModel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface IReportProps {
    account: CommissionViewModel;
}

export default function CommissionTableRow(props: IReportProps) {
    return (
        <TableRow tabIndex={-1} >
            <TableCell>{props.account.exec}</TableCell>
            <TableCell>{props.account.account}</TableCell>
            <TableCell align="right">£{props.account.amount.toFixed(2)}</TableCell>
            <TableCell>{props.account.period}</TableCell>
            <TableCell>{props.account.endOfPeriodDate !== null ? new Date(props.account.endOfPeriodDate).toLocaleDateString() : ''}</TableCell>
            <TableCell>{props.account.type}</TableCell>
            <TableCell>{props.account.notes}</TableCell>
            <TableCell>{props.account.finalised ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon/>}</TableCell>
        </TableRow>
    );
}