import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UserAccountViewModel } from '../ViewModels/LinkedInAccountViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm } from 'react-material-ui-form-validator';
import UserDropdown from '../Dropdowns/UserDropdown';
import UtilityDatepicker from '../Utilities/DatePicker';
import DropdownViewModel from '../ViewModels/DropdownViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IUserProps {
    accountId: number;
    open: boolean;
    close: (refresh: boolean) => void;
    users: DropdownViewModel[];
}

export default function ExecutiveDialog(props: IUserProps) {
    const classes = useStyles();
    const [user, setUser] = React.useState<UserAccountViewModel>({ accountId: props.accountId, userId: '', startDate: new Date(), endDate: new Date() });

    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    const onDropdownChange = (value: number | string, name: string, client: string) => {
        setUser({
            ...user,
            [name]: value as string
        });
    }

    const changeDate = (newDate: Date | null, name: string) => {
        setUser({
            ...user,
            [name]: newDate!
        });
    }

    const changeEndDate = (date: Date | null) => {
        setUser({
            ...user,
            endDate: date
        });
    }

    const onClose = () => {
        if (!submitting) {
            setUser({ accountId: props.accountId, userId: '', startDate: new Date(), endDate: null });
            props.close(false);
        }
    }


    const submit = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();

        fetch('Account/AssignExecutive', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(user)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                props.close(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <ValidatorForm onSubmit={submit}>
                <DialogTitle id="form-dialog-title">Assign Executive</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserDropdown users={props.users} id={user.userId} label="Executive" name="userId" onChange={onDropdownChange} required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <UtilityDatepicker
                                label="Start Date"
                                name="startDate"
                                date={user.startDate}
                                onChange={changeDate}
                                disableFuture={false}
                                disablePast={false}
                                disabled={false }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UtilityDatepicker
                                label="End Date"
                                name="endDate"
                                date={user.endDate}
                                onChange={changeEndDate}
                                disableFuture={false}
                                disablePast={false}
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                    <Button type="submit" color="primary" disabled={submitting}>
                        Submit
                        {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}