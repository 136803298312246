import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import DenseTableCell from '../../Utilities/DenseTableCell';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import grey from '@mui/material/colors/grey';
import { CampaignAccountListViewModel } from '../../ViewModels/CampaignViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%'
        },
        default: {
            width: '100%'
        },
    })
);

interface IAccountListProps {
    account: CampaignAccountListViewModel;
    user: any;
}

export default function AccountTableRow(props: IAccountListProps) {
    const classes = useStyles();
    const id = props.account.id;
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Accounts/Account',
                state: { accountId: id }
            }}
            {...props} />
    ));

    
    const statuses = [
        { status: "Live", class: classes.green },
        { status: "At Risk", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "Suspended", class: classes.red }
    ];

    const statusClass = props.account.status.length > 0 ? statuses.find(f => f.status === props.account.status)!.class : classes.default;
    
    return (
        <TableRow>
            <DenseTableCell>{props.account.name}</DenseTableCell>
            <DenseTableCell>{props.account.mainExecutive}</DenseTableCell> 
            {(new Date(props.account.actualStart).getTime() > new Date().getTime() || ((props.account.agreementEnd) && new Date(props.account.agreementEnd!).getTime() < new Date().getTime())) ?
                <DenseTableCell align="center" >Inactive</DenseTableCell> :            
                <DenseTableCell><Chip className={statusClass} label={props.account.status} /></DenseTableCell>
            }
            {
                ((props.user?.role !== "Executive" && props.user?.role !== "Creator") || (props.account.mainExecutiveId === props.user?.sub)) ?
                    <DenseTableCell align="right"><Button variant="contained" color="secondary" size="small" component={viewButton} >View</Button></DenseTableCell>
                    :
                    <DenseTableCell />
            }
        </TableRow>
    );
}