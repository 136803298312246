import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CreateLinkedInAccountViewModel } from '../ViewModels/LinkedInAccountViewModel';
import authService from '../api-authorization/AuthorizeService';
import { Redirect, useLocation } from 'react-router';
import CountryDropdown from '../Dropdowns/CountryDropdown';
import MuiPhoneNumber from 'material-ui-phone-number';
import RenewalDataEntry from '../Renewals/RenewalDataEntry';
import CreateRenewalViewModel from '../Renewals/ViewModels/CreateRenewalViewModel';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    })
);

interface LocationState {
    campaignId: number;
}

export default function CreateAccount() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { campaignId } = location.state || {
        campaignId: 0
    };

    const [account, setAccount] = React.useState(new CreateLinkedInAccountViewModel());
    const [success, setSuccess] = React.useState(false);
    const [serverError, setError] = React.useState('');

    React.useEffect(() => {
        if (campaignId !== account.campaignId) {
            setAccount({
                ...account,
                campaignId: campaignId
            });
        }
    }, [campaignId, account]);

    const updateRenewal = (renewal: CreateRenewalViewModel) => {
        setAccount({
            ...account,
            renewal
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccount({
            ...account,
            [event.target.name]: event.target.valueAsNumber || event.target.value
        });
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        const data = JSON.stringify(account);
        console.log(data);

        fetch('Account/CreateAccount', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(account)
        })
            .then(response => response.text())
            .then(data => {
                if (data === 'Ok') {
                    setSuccess(true);
                } else {
                    setError('A server error has occurred, please try again.');
                }
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    function onChangePh(value: any) {
        setAccount({
            ...account,
            contactPhone: value
        })
    }

    ValidatorForm.addValidationRule('telephoneRegex', (value) => {
        if (value === "") {
            return true;
        }
        return /^0[0-9]{9,10}$/.test(value);
    });

    ValidatorForm.addValidationRule('emailRegex', (value) => {
        if (value === "") {
            return true;
        }
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    });

    return (
        <React.Fragment>
            {success && <Redirect to='/Clients' />}
            <ValidatorForm onSubmit={submit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Create Account</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Name"
                                name="name"
                                variant="outlined"
                                fullWidth
                                value={account.name}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Username"
                                name="username"
                                variant="outlined"
                                fullWidth
                                value={account.username}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Password"
                                name="password"
                                variant="outlined"
                                fullWidth
                                value={account.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Contact Email"
                                name="contactEmail"
                                variant="outlined"
                                fullWidth
                                value={account.contactEmail}
                                validators={['required', 'emailRegex']}
                                errorMessages={['This field is required', 'Must be a valid email']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPhoneNumber
                                variant="outlined"
                                fullWidth
                                defaultCountry={'gb'}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                label='Contact Phone'
                                value={account.contactPhone}
                                onChange={onChangePh}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CountryDropdown id={account.ref_CountryId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <RenewalDataEntry renewal={account.renewal} setRenewal={updateRenewal} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth color="primary" variant="contained" type="submit">Create Account</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </ValidatorForm>
        </React.Fragment>
    );
}