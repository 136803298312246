import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import CampaignTable from './CampaignTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import CampaignListViewModel from './ViewModels/CampaignListViewModel';
import RefIndustryExtendedViewModel from './ViewModels/RefIndustryExtendedViewModel';
import { PermissionCheck } from '../Contexts/PermissionCheck';
import { PermissionsContext } from '../Contexts/PermissionsContext';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 650,

        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        pagination: {
            paddingTop: theme.spacing(2),
        }
    }),

);

export default function CampaignList() {
    const classes = useStyles();

    const [campaigns, setCampaigns] = React.useState<CampaignListViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [pageMax, setPageMax] = React.useState(0);
    const [industries, setIndustries] = React.useState<RefIndustryExtendedViewModel[]>([]);
    const [filterIndustry, setFilterIndustry] = React.useState<string | null>();
    const [industryLabels, setIndustryLabels] = React.useState<string[]>([]);
    const { checkHasPermission } = useContext(PermissionsContext);
    const [roles, setRoles] = React.useState<string[] | string>("");

    React.useEffect(() => {
        fetch('Campaign/GetClientIndustryList')
            .then(response => response.json())
            .then(data => {
                setIndustries(data);
            });

        initialise();
    }, []);

    const initialise = async () => {
        setLoaded(false);
        const user = await authService.getUser();
        setRoles(user.role);
    }

    React.useEffect(() => {
        let newList = industries.map((options) => options.industry);
        newList.unshift("No industry set");
        setIndustryLabels(newList);
    }, [industries])

    React.useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [campaigns, loaded]);

    React.useEffect(() => {
        if (!filterIndustry || industryLabels.findIndex(f => f === filterIndustry) > -1) {
            if (page > 0)
                setPage(0);
            else
                getData();
        }
    }, [filterIndustry]);

    React.useEffect(() => {
        getData();
    }, [page]);

    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue - 1);
    };

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        const industry = !filterIndustry ? '' : filterIndustry;
        if (checkHasPermission("Actions.SeeAllAccounts")) {
            fetch(`Campaign/GetCampaigns?page=${page}&search=${search}&industry=${industry}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setCampaigns(data.rows);
                    setPageMax((Math.trunc(data.count / 25) + (data.count % 25 > 0 ? 1 : 0)));
                    setLoaded(true);
                })
                .catch(error => { setLoaded(true) });
        } else {
            fetch(`Campaign/GetUserCampaignsList?userId=${user.sub}&page=${page}&search=${search}&industry=${industry}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setCampaigns(data.rows);
                    setPageMax((Math.trunc(data.count / 25) + (data.count % 25 > 0 ? 1 : 0)));
                    setLoaded(true);
                })
                .catch(error => { setLoaded(true) });
        }
    };

    const createButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Create'
            }}
            {...props} />
    ));

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }

    const list = <CampaignTable campaigns={campaigns} roles={roles} refresh={getData} />;

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm='auto'>
                        <Typography variant="h2">Clients</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12} sm='auto' >
                                {industries.length > 0 &&
                                    <Autocomplete
                                        onChange={(event: any, newValue: string | null) => {
                                            setFilterIndustry(newValue);
                                        }}
                                        value={filterIndustry}
                                        options={industryLabels}
                                        autoHighlight
                                        autoComplete
                                        autoSelect
                                        fullWidth
                                        sx={{ mt: 1, minWidth: 300 }}
                                        renderInput={(params: any) =>
                                            <TextField {...params}
                                                label="Select Industry"
                                                variant="outlined"
                                                value={filterIndustry}
                                                size="small"
                                            />}
                                    />}
                            </Grid>
                            <Grid item xs={8} sm='auto'>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={search}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onFilterChange}
                                    onKeyDown={keyPress}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={4} sm='auto'>
                                <Button aria-label={"Search Clients"} onClick={getData} variant="contained" color="secondary" disabled={!loaded}>Search</Button>
                            </Grid>
                            <PermissionCheck permission="Actions.CreateClient">
                                <Grid item>
                                    <Button fullWidth variant="contained" color="secondary" component={createButton} disabled={!loaded}>Create Client</Button>
                                </Grid>
                            </PermissionCheck>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {!loaded && <LinearProgress />}
                {list}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.pagination}>
                    <Grid item>
                        <Pagination page={page + 1} onChange={handlePageChange} count={pageMax} showFirstButton showLastButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}