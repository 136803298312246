import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { TextValidator } from "react-material-ui-form-validator";
import { IClientDiscoveryFormTabProps } from "../ClientDiscoveryForm";

export default function ClientDiscoveryAds({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Section 3 - Ads</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        As you can imagine the better your LinkedIn Company Page is, the more likely you are to generate new business on the platform.

                        As it says here 30% more views go to pages with complete information so I did a quick review before our call.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Linkedin Objective: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        There are two main ways you can generate leads through LinkedIn Ads as you can see here, I'll just go through them.

                        To be frank, it really depends on how confident you are with your website's conversion rate with the second option.

                        Which one sounds like a better option for you?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.nativeLeadGenForms} name="nativeLeadGenForms" onChange={handleInputChange} />} label="Native Lead Gen Forms" />
                        <FormControlLabel control={<Checkbox checked={data.websiteConversions} name="websiteConversions" onChange={handleInputChange} />} label="Website Conversions" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Linkedin Ad Creatives: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        I have an in-house designer who will come up with 3 initial ad designs for you but to do this we'll need you to fill out our design brief which I'll send over after the call.

                        So I'll send a separate email from the main follow-up with the brief attached and I'll also require you to attach whatever we may require such as images, logos etc.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={data.imageOrLogoIncluded} name="imageOrLogoIncluded" onChange={handleInputChange} />} label="Please send any images or logos you would like included" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Conversion Tracking: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        So as you can see I've taken a look at your website and found events we could potentially track through the LinkedIn Ads traffic that comes to your site.

                        Are there any here you aren't too bothered about or are you happy for me to track all of them?

                        As I've mentioned we'll just require either your website login details or your developer's information so I can install a code to track these events. I'll make sure to remind you in my email follow-up.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="conversionTracking"
                        value={data.conversionTracking}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Performance Review: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        So to have a successful LinkedIn Ads campaign we need to be ticking three boxes to put it simply. Those tick boxes are:

                        LinkedIn Ads performance
                        LinkedIn Page performance
                        Your own sales performance
                        Does that make sense?

                        Now in terms of the LinkedIn Ads performance, we're solely responsible for this. Our job is to send relevant and targeted traffic to your website & page in the hope that it converts into leads.

                        With the LinkedIn Page performance, I know we've already been through this but you need to have a fully optimised page and post regularly to boost the performance of our campaign.

                        And lastly, with your Sales performance, it's important that you can convert the leads our campaign is generating at a good rate. So if you don't mind me asking, what's the current process when you receive an enquiry?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="performanceReview"
                        value={data.performanceReview}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Website Speed: *</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        It's worth having a quick chat about your website as well although it isn't a priority as the majority of leads will happen via LinkedIn.

                        As I'm sure you know, most searches these days are done on a mobile device. Also, as we're working with a PPC platform, it's important that the traffic we're paying for actually stays on the site.

                        So when I've checked our site speed, it gives me a score out of 100 for desktop and mobile. So on the desktop, yours came back /100. And on mobile devices, it's /100. (PUT THIS BELOW)

                        Would you be happy to make changes to your website in order to improve the performance of our campaign further down the line I have any suggestions?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="websiteSpeed"
                        value={data.websiteSpeed}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}