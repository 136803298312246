import TableRow from '@mui/material/TableRow';
import AccountOnboardingViewModel from './ViewModels/AccountOnboardingViewModel';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import { ChangeEvent, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Save from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import authService from '../api-authorization/AuthorizeService';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import DenseTableCell from '../Utilities/DenseTableCell';
import { PermissionsContext } from '../Contexts/PermissionsContext';
import { PermissionCheck } from '../Contexts/PermissionCheck';

interface IAccountOnboardingProps {
    account: AccountOnboardingViewModel;
    handleAccount: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
    handleStatus: (id: number, status: string) => void;
}

export default function AccountOnboardingRow(props: IAccountOnboardingProps) {
    const [editHappened, setEditHappened] = React.useState<boolean>(false);
    const [statusColour, setStatusColour] = React.useState<"error" | "success" | "warning" | "info">("info");
    const [initBriefed, setInitBriefed] = React.useState<string>("");
    const { checkHasPermission } = useContext(PermissionsContext);


    React.useEffect(() => {
        if (props.account.status === "Not Started") {
            setStatusColour("info");
        }
        else if (props.account.status === "In Progress") {
            setStatusColour("warning");
        }
        else if (props.account.status === "Stuck") {
            setStatusColour("error");
        }
        else if (props.account.status === "Complete") {
            setStatusColour("success");
        }

    }, [props.account.status])
    React.useEffect(() => {
        setInitBriefed(props.account.briefed)
    }, [])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.handleAccount(event, props.account.id)

        setEditHappened(true);
    }

    const id = props.account.id;
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Accounts/Account',
                state: { accountId: id }
            }}
            {...props} />
    ));
    const clientId = props.account.campaignId;
    const viewClientButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Client',
                state: { campaignId: clientId }
            }}
            {...props} />
    ));

    const submit = async () => {
        let status = "";
        if (props.account.briefed === "Not Started" && props.account.profileReview === "Not Started" && props.account.searchStatus === "Not Started" && props.account.paymentTaken === "Not Charged") {
            status = "Not Started"
        }
        else if (props.account.briefed === "Complete" && props.account.profileReview === "Complete" && props.account.searchStatus === "Complete" && props.account.paymentTaken === "Payment Taken") {
            status = "Complete"
        }
        else if (props.account.briefed === "Stuck" || props.account.profileReview === "Stuck" || props.account.searchStatus === "Stuck" || props.account.paymentTaken === "Payment Failed") {
            status = "Stuck"
        }
        else {
            status = "In Progress";
        }

        const vm = props.account;
        vm.status = status;
        const token = await authService.getAccessToken();
        if (initBriefed !== props.account.briefed) {
            fetch(`AccountOnboarding/ManualUpdateBriefed?briefed=${props.account.briefed}&accountId=${props.account.id}`)
                .then(response => response.json());
        }
        fetch('AccountOnboarding/UpdateAccountOnboarding', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(props.account)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    props.handleStatus(props.account.id, status);
                }
            })


        setEditHappened(false);
    }

    return (
        <TableRow>
            <DenseTableCell><Button variant="outlined" color="secondary" size="small" component={viewClientButton} sx={{ width: '200px' }}>{props.account.campaignName}</Button></DenseTableCell>
            <DenseTableCell><Button variant="outlined" color="secondary" size="small" component={viewButton} sx={{ width: '200px' }}>{props.account.name}</Button></DenseTableCell>
            <PermissionCheck permission="Pages.Onboarding">
                <DenseTableCell>{props.account.mainExecutiveName ?? "No Main Executive Set"}</DenseTableCell>
            </PermissionCheck>
            <DenseTableCell>{props.account.startDate}</DenseTableCell>
            <DenseTableCell>
                <Grid container spacing={1}>
                    <Grid item>
                        <Chip
                            label={props.account.status}
                            color={statusColour}
                            size="medium"
                            sx={{ width: '120px' }}
                            icon={
                                props.account.status === "Complete" ?
                                    <DoneIcon />
                                    :
                                    props.account.status === "Stuck" ?
                                        <WarningIcon />
                                        :
                                        props.account.status === "In Progress" ?
                                            <PendingIcon />
                                            :
                                            undefined
                            }
                        />
                    </Grid>
                </Grid>
            </DenseTableCell>
            <DenseTableCell>
                <ValidatorForm onSubmit={submit}>
                    <Grid container spacing={1}>
                        {checkHasPermission("") ?
                            <Grid item>
                                <SelectValidator
                                    disabled={!checkHasPermission("Pages.Onboarding")}
                                    name="briefed"
                                    label="Briefed"
                                    value={props.account.briefed}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    size="small"
                                    sx={{ width: '160px' }}
                                >
                                    {initBriefed === "Not Started" &&
                                        <MenuItem key={0} value="Not Started">Not Started</MenuItem>
                                    }
                                    {(initBriefed === "Not Started" || initBriefed === "In Progress") &&
                                        <MenuItem key={1} value="In Progress">In Progress</MenuItem>
                                    }
                                    <MenuItem key={2} value="Complete">Complete</MenuItem>

                                </SelectValidator>
                            </Grid>
                            :
                            <Grid item>
                                <TextValidator
                                    name="briefed"
                                    label="Briefed"
                                    value={props.account.briefed}
                                    readOnly
                                    size="small"
                                    sx={{ width: '140px' }}
                                />
                            </Grid>
                        }
                        <Grid item>
                            {props.account.editable && props.account.paymentTaken ?
                                <SelectValidator
                                    disabled={!checkHasPermission("Pages.Onboarding")}
                                    name="paymentTaken"
                                    label="Payment Taken"
                                    value={props.account.paymentTaken}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    size="small"
                                    sx={{ width: '160px' }}
                                >
                                    <MenuItem key={0} value="Not Charged">Not Charged</MenuItem>
                                    <MenuItem key={1} value="Payment Failed">Payment Failed</MenuItem>
                                    <MenuItem key={2} value="Payment Taken">Payment Taken</MenuItem>
                                </SelectValidator>
                                :
                                <TextValidator
                                    name="paymentTaken"
                                    label="Payment Taken"
                                    value={props.account.briefed}
                                    readonly
                                    size="small"
                                    sx={{ width: '160px' }}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {props.account.editable && props.account.profileReview ?
                                <SelectValidator
                                    name="profileReview"
                                    label="Profile Review"
                                    value={props.account.profileReview}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    size="small"
                                    sx={{ width: '140px' }}
                                >
                                    <MenuItem key={0} value="Not Started">Not Started</MenuItem>
                                    <MenuItem key={1} value="In Progress">In Progress</MenuItem>
                                    <MenuItem key={2} value="Stuck">Stuck</MenuItem>
                                    <MenuItem key={3} value="Complete">Complete</MenuItem>
                                </SelectValidator>
                                :
                                <TextValidator
                                    name="profileReview"
                                    label="Profile Review"
                                    value={props.account.briefed}
                                    readonly
                                    size="small"
                                    sx={{ width: '140px' }}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {props.account.editable ?
                                <SelectValidator
                                    name="searchStatus"
                                    label="Search Set Up"
                                    value={props.account.searchStatus}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    size="small"
                                    sx={{ width: '140px' }}
                                >
                                    <MenuItem key={0} value="Not Started">Not Started</MenuItem>
                                    <MenuItem key={1} value="In Progress">In Progress</MenuItem>
                                    <MenuItem key={2} value="Stuck">Stuck</MenuItem>
                                    <MenuItem key={3} value="Complete">Complete</MenuItem>
                                </SelectValidator>
                                :
                                <TextValidator
                                    name="searchStatus"
                                    label="Search Set Up"
                                    value={props.account.briefed}
                                    readonly
                                    size="small"
                                    sx={{ width: '140px' }}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {props.account.editable &&
                                <Grid item>
                                    <IconButton size="small" disabled={!editHappened} onClick={submit} color="primary"><Save /></IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </DenseTableCell>
        </TableRow >
    );
}