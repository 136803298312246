import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateMessageViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import authService from '../../api-authorization/AuthorizeService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

interface ICreateProps {
    campaignId: number;
    refresh: () => void;
}

export default function CreateMessage(props: ICreateProps) {
    const classes = useStyles();
    const [message, setMessage] = React.useState(new CreateMessageViewModel(props.campaignId));
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage({
            ...message,
            [event.target.name]: event.target.value
        });
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch('Campaign/CreateMessage', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(message)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);
                props.refresh();
                setMessage(new CreateMessageViewModel(props.campaignId));
            })
            .catch(error => {
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="newmessage-content"
                id="newmessage-header"
            >
                <Typography className={classes.heading}>Add pinned note</Typography>
            </AccordionSummary>
            <ValidatorForm onSubmit={submit}>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label="Title"
                                name="title"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={message.title}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label="Message"
                                name="content"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                multiline
                                value={message.content}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                    <Button size="small" type="submit" variant="outlined" color="primary" disabled={saving}>
                        Save
                        {saving && <CircularProgress color='secondary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </AccordionActions>
            </ValidatorForm>
        </Accordion >
    );
}