import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import HoldingArea from './Holding/HoldingArea';
import { PermissionCheck } from '../Contexts/PermissionCheck';

export default function OnboardingContainer() {
    const [roles, setRoles] = React.useState<string[] | string>("");

    React.useEffect(() => {
        const initialise = async () => {

            const user = await authService.getUser();
            setRoles(user.role);
        }
        initialise();
    }, []);


    const createButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Create'
            }}
            {...props} />
    ));


    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4">Holding</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                            <PermissionCheck permission="Actions.CreateClient">
                                <Grid item>
                                    <Button fullWidth variant="contained" color="secondary" component={createButton} >Create Client</Button>
                                </Grid>
                            </PermissionCheck>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(roles && (roles.includes('SuperAdmin') || roles.includes('Admin') || roles.includes('Creator'))) &&
                <Grid item xs={12} style={{ paddingTop: "5px" }}>
                    <HoldingArea roles={roles} />
                </Grid>
            }
        </Grid>
    );
}