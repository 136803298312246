import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

interface IDatePickerProps {
    date: Date | null;
    name: string;
    label: string;
    disablePast: boolean;
    disableFuture: boolean;
    monthOnly?: boolean;
    onChange: (date: Date | null, name: string) => void;
    disabled: boolean;
}

export default function Datepicker(props: IDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                views={props.monthOnly ? ["year", "month"] : ["year", "month", "day"]}
                openTo={props.monthOnly ? "month" : undefined}
                inputFormat={props.monthOnly ? "MMMM yyyy" : "dd/MM/yyyy"}
                mask={props.monthOnly ? "MMMM yyyy" : "dd/MM/yyyy"}
                label={props.label}
                value={props.date}
                onChange={(newValue: any) => {
                    props.onChange(newValue as Date, props.name);
                }}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                renderInput={(params) => <TextField margin="normal" fullWidth variant="outlined" {...params} />}
                disabled={props.disabled}
            />
        </LocalizationProvider>
    );
}