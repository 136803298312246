import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Fragment } from 'react';
import CommissionGroupTableRow from './CommissionGroupTableRow';
import CommissionGroupViewModel from './ViewModels/CommissionGroupViewModel';

interface IProps {
    editCommissionGroup: (id: number) => void
    commissionGroups: CommissionGroupViewModel[]
    editActive: (id: number, active: boolean) => void

}
export default function CommissionGroupTable(props: IProps) {
    const items = props.commissionGroups.map((item, index) =>
        <CommissionGroupTableRow key={item.id} commissionGroup={item} editCommissionGroup={props.editCommissionGroup} editActive={props.editActive} />
    );

    return (
        <Fragment>
            <TableContainer >
                <Table aria-label="commission group list" stickyHeader >
                    <TableHead >
                        <TableRow>
                            <TableCell>Group Name</TableCell>
                            <TableCell>Performance Bonus</TableCell>
                            <TableCell>Retention Bonus</TableCell>
                            <TableCell>Loss Bonus</TableCell>
                            <TableCell>Gain Bonus</TableCell>
                            <TableCell>Billing Target</TableCell>
                            <TableCell>Renewals Target</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}