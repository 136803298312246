import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ClientTableRow from './ClientTableRow';
import TableContainer from '@mui/material/TableContainer';
import ClientListViewModel from './ViewModels/ClientListViewModel';
import authService from '../../api-authorization/AuthorizeService';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import AccountListViewModel from './ViewModels/AccountListViewModel';

interface IClientTableProps {
    clients: ClientListViewModel[];
    refresh: () => void;
    roles: string | string[];
    pendingAccounts: AccountListViewModel[];
}

export default function ClientTable(props: IClientTableProps) {
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => { getData(); }, []);

    const items = props.clients.map((item, index) =>
        <ClientTableRow key={index} campaign={item} refresh={props.refresh} teamLeaders={users} roles={props.roles} pendingAccounts={props.pendingAccounts.filter(f => f.clientId === item.id)} />
    );

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }
    return (
        <TableContainer style={{ maxHeight: "75vh" }}>
            <Table aria-label="client list" stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell>Client</TableCell>                          
                        <TableCell>Client Manager</TableCell>
                        <TableCell>Pending Accounts</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </Table>
        </TableContainer>
    );
}