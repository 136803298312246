import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { LockViewModel } from '../ViewModels/LinkedInAccountViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IProps {
    accountId: number;
    date: Date;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function LockActivityDialog(props: IProps) {
    const classes = useStyles();
    const [followUp, setFollowUp] = React.useState<LockViewModel>({ accountId: props.accountId, date: props.date });
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    
    const onClose = () => {
        if (!submitting) {
            setFollowUp({ accountId: props.accountId, date: props.date});
            props.close(false);
        }
    }

    const submit = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();
        const follow = followUp;
        follow.date = props.date;

        fetch(`Account/LockActivity`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(follow)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                props.close(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <ValidatorForm onSubmit={submit}>
                <DialogTitle id="form-dialog-title">Lock Activity</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Lock activity for {new Date(props.date).toLocaleDateString("en-GB")}.
                        {error}
                    </DialogContentText>                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                    <Button type="submit" color="primary" disabled={submitting}>
                        Confirm
                    {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}