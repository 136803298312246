import SaleHandoverQuestionsViewModel from "../Campaigns/ViewModels/SaleHandoverQuestionsViewModel";
import CreateRenewalViewModel from "../Renewals/ViewModels/CreateRenewalViewModel";


export default class CampaignViewModel {
    public id: number = 0;
    public campaignName: string = '';
    public partner: string = '';
    public contactName: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public startDate: Date = new Date();
    public campaignType: string = '';
    public teamLeader: string = '';
    public teamLeaderId: string = '';
    public opManager: string = '';
    public opManagerId: string = '';
    public accounts: CampaignAccountListViewModel[] = [];
    public countryId: number = 0;
    public country: string = '';
    public industryExtended: string = '';
    public priceOffered: number = 0;
    public discount: number = 0;
    public salesPerson: string = '';
    public contractLength: string = '';
    public packageMonthlyValue: number = 0;
    public clientDiscoveryComplete: boolean = false;
    public copywriter: string = '';
    public copywriterId: string = '';
    public clientDirector: string = '';
    public clientDirectorId: string = '';
}

export class CampaignAccountListViewModel {
    public id: number = 0;
    public name: string = '';
    public packageName: string = '';
    public status: string = '';
    public mainExecutiveId: string = '';
    public mainExecutive: string = '';
    public actualStart: Date = new Date();
    public agreementEnd: Date | null = null;
}

export class CreateCampaignViewModel {
    public campaignName: string = '';
    public contactName: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public campaignTypeId: number = 0;
    public ref_CountryId: number = 0;
    public numberOfAccounts: number = 0;
    public clientIndustry: number = 0;
    public saleHandoverQuestions: SaleHandoverQuestionsViewModel = new SaleHandoverQuestionsViewModel();
    public accounts: CreateCampaignNewAccountViewModel[] = [];
}

export class UserCampaignListViewModel {
    public name: string = '';
    public campaigns: CampaignViewModel[] = [];
}

export class CreateCampaignNewAccountViewModel {
    public uid: number = 0;
    public accountName: string = '';
    public renewal: CreateRenewalViewModel = new CreateRenewalViewModel();
    constructor(newUid: number) {
        this.uid = newUid;
        this.accountName = 'New Account ' + newUid;
    }
}