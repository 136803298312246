import * as React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation } from 'react-router';
import authService from '../api-authorization/AuthorizeService';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ClientDiscoveryInitial from './ClientDiscoveryInitial';
import ClientDiscoveryDeepDive from './ClientDiscoveryDeepDive';
import ClientDiscoveryVelocity from './ClientDiscoveryVelocity';
import ClientDiscoveryFinal from './ClientDiscoveryFinal';
import ClientDiscoveryAds2 from './Ads/ClientDiscoveryAds2';
import ClientDiscoveryAds3 from './Ads/ClientDiscoveryAds3';
import ClientDiscoveryAds4 from './Ads/ClientDiscoveryAds4';
import ClientDiscoveryAds5 from './Ads/ClientDiscoveryAds5';
import ContentMarketing from './Content/ContentMarketing';


import ClientDiscoveryFormViewModel from './Viewmodels/ClientDiscoveryFormViewModel';
import ReturnViewModel from '../ViewModels/ReturnViewModel';

export interface IClientDiscoveryFormTabProps {
    data: ClientDiscoveryFormViewModel;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function ClientDiscoveryForm() {
    const location = useLocation<{ campaignId: number }>();
    const { campaignId } = location.state || { campaignId: 0 };
    const [clientDiscoveryForm, setClientDiscoveryForm] = React.useState(new ClientDiscoveryFormViewModel());
    const [step, setStep] = React.useState(0);
    const [isUpdated, setIsUpdated] = React.useState(false);
    const [queueSave, setQueueSave] = React.useState(false);
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [error, setError] = React.useState<string[]>([]);
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
        const autosave = setInterval(() => setQueueSave(true), 10000);
        return () => {
            clearInterval(autosave);
        }
    }, []);

    React.useEffect(() => {
        if (isUpdated && queueSave) {
            save(false);
            setQueueSave(false);
        }
    }, [queueSave]);

    const getData = async () => {
        setLoading(false);
        const token = await authService.getAccessToken();

        fetch(`Form/LoadDiscovery?id=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                setLoading(true);
                if (!data.success) {
                    setOpenErrorDialog(true);
                    setError(data.messages);
                    return;
                }

                setClientDiscoveryForm(data.value);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = event.target;
        setClientDiscoveryForm(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value ?? "",
        }));
        setIsUpdated(true);
    };

    const handleNavigation = (change: number) => {
        setStep((newStep) => newStep + change);
    };

    const save = async (nextPage: boolean) => {
        setSaving(true);
        const token = await authService.getAccessToken();
        let data = clientDiscoveryForm;
        if (step === STEP_COMPONENTS.length - 1) {
            
            data.complete = true;
        }
        if (nextPage) {
            handleNavigation(1);
        }
        fetch('Form/SaveDiscovery', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data: ReturnViewModel) => {
                if (!data.success) {
                    console.log(data.messages);
                    setOpenErrorDialog(true);
                    setError(data.messages);
                }
                setSaving(false);
                setIsUpdated(false);
            })
            .catch(error => {
                console.log(error);
                setOpenErrorDialog(true);
                setError(error);
            });
    }

    const report = () => {
        fetch(`PDFGenerator/ClientDiscoveryReport?clientId=${campaignId}`)
            .then(response => response.blob())
            .then(data => {
                console.log(data)
                if (data.type === "application/problem+json") {
                    console.log('error', 'An internal server error occured');
                }
                var dateTime = new Date();
                var formattedTime = dateTime.toLocaleDateString();
                var url = window.URL.createObjectURL(data);
                var a = document.createElement('a');
                a.href = url;
                a.download = clientDiscoveryForm.campaignName + "_ClientDiscovery_" + formattedTime + ".pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const discoveryButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Clients/Client',
                state: { campaignId }
            }}
            {...props} />
    ));

    const STEP_COMPONENTS = React.useMemo(() => {
        const steps = [ClientDiscoveryInitial];

        steps.push(ClientDiscoveryDeepDive);

        if (clientDiscoveryForm.isVelocity) {
            steps.push(ClientDiscoveryVelocity);
        }

        if (clientDiscoveryForm.isContent) {
            steps.push(ContentMarketing);
        }

        if (clientDiscoveryForm.isAds) {
            steps.push(ClientDiscoveryAds2);
            steps.push(ClientDiscoveryAds3);
            steps.push(ClientDiscoveryAds4);
            if (clientDiscoveryForm.isBoost) {
                steps.push(ClientDiscoveryAds5);
            }
        }

        steps.push(ClientDiscoveryFinal);
        return steps;
    }, [clientDiscoveryForm.isVelocity, clientDiscoveryForm.isContent, clientDiscoveryForm.isAds]);

    const CurrentStepComponent = STEP_COMPONENTS[step];
    const isLastStep = step === STEP_COMPONENTS.length;

    return (
        <Container maxWidth="md" fixed sx={{ paddingTop: "12px" }}>
            {loading &&
                <ValidatorForm onSubmit={() => save(true)} autoComplete="off" instantValidate={true}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Client Discovery - {clientDiscoveryForm.campaignName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {isLastStep ?
                                <Typography sx={{ m: 2 }} align="center" variant="h6">Discovery Form Complete!</Typography>
                                :
                                <CurrentStepComponent
                                    data={clientDiscoveryForm}
                                    handleInputChange={handleInputChange}
                                />
                            }
                        </Grid>
                        <Grid item>
                            {step > 0 && !isLastStep && <Button variant="contained" onClick={() => handleNavigation(-1)}>Back</Button>}
                            {isLastStep &&
                                <Button variant="outlined" color="primary" fullWidth onClick={report}>Download PDF</Button>
                            }
                        </Grid>
                        <Grid item>
                            {isLastStep ?
                                <Button variant="contained" color="primary" fullWidth component={discoveryButton}>Return to Client</Button>
                                :
                                <Button variant="contained" color="primary" disabled={saving} fullWidth type="submit">Save & Continue</Button>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            }
            <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)} maxWidth="xs">
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenErrorDialog(false)} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
