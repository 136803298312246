import GetRenewalsViewModel from './ViewModels/GetRenewalsViewModel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

interface IRenewalsTableRow {
    renewal: GetRenewalsViewModel;
    editRenewal: (id: string) => void;
    userRole?: any;
}

export default function RenewalsTableRow(props: IRenewalsTableRow) {


    const edit = () => {
        props.editRenewal(props.renewal.id);
    }

    return (
        <TableRow selected={props.renewal.active}>
            <TableCell>{props.renewal.startDate}</TableCell>
            <TableCell>{props.renewal.endDate}</TableCell>
            <TableCell>{props.renewal.renewalDate}</TableCell>
            <TableCell>{props.renewal.contractLength} months</TableCell>
            <TableCell>{props.renewal.package}</TableCell>
            <TableCell>{props.renewal.monthlyValue}</TableCell>
            <TableCell>{props.renewal.contractValue}</TableCell>
            <TableCell>{props.renewal.discount}</TableCell>
            <TableCell>{props.renewal.salesPerson}</TableCell>
            {(props.userRole !== "Executive" && props.userRole !== "Creator") &&
                <TableCell><IconButton color="primary" onClick={edit}><EditIcon /></IconButton></TableCell>
            }
        </TableRow>
    );
}