import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LinkedInAccountListViewModel } from '../ViewModels/LinkedInAccountViewModel';
import AccountTableRow from './AccountTableRow';
import DenseTableCell from '../Utilities/DenseTableCell';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import { TableContainer, Tooltip, Typography } from '@mui/material';

interface IAccountTableProps {
    accounts: LinkedInAccountListViewModel[];
    date: Date;
    refreshList: () => void;
}

export default function AccountTable(props: IAccountTableProps) {
    const items = props.accounts.map((item, index) =>
        <AccountTableRow key={index} account={item} refreshList={props.refreshList} date={props.date} />
    );

    return (
        <TableContainer style={{ maxHeight: "70vh" }}>
            <ResponsiveTable aria-label="account list" style={{ padding: 8 }} >
                <TableHead>
                    <TableRow>
                        <DenseTableCell />
                        <DenseTableCell>Account</DenseTableCell>
                        <DenseTableCell>Package</DenseTableCell>
                        <DenseTableCell>Country</DenseTableCell>
                        <DenseTableCell>Renewal Date</DenseTableCell>
                        <DenseTableCell>Agreement Length</DenseTableCell>
                        <DenseTableCell>Agreement End</DenseTableCell>
                        <DenseTableCell><Tooltip title="Connections">
                            <Typography variant="subtitle2" gutterBottom>Con.</Typography>
                        </Tooltip></DenseTableCell>
                        <DenseTableCell><Tooltip title="Messages">
                            <Typography variant="subtitle2" gutterBottom>Mes.</Typography>
                        </Tooltip></DenseTableCell>
                        <DenseTableCell>
                            <Tooltip title="Agreement Rate">
                                <Typography variant="subtitle2" gutterBottom>Agr. Rate</Typography>
                            </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell>
                            <Tooltip title="Oppurtinities">
                                <Typography variant="subtitle2" gutterBottom>Opp.</Typography>
                            </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell><Tooltip title="Follow Ups">
                            <Typography variant="subtitle2" gutterBottom>Fol. Ups</Typography>
                        </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell><Tooltip title="Withdrawn">
                            <Typography variant="subtitle2" gutterBottom>Wit.</Typography>
                        </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell><Tooltip title="Page Invites">
                            <Typography variant="subtitle2" gutterBottom>Page Inv.</Typography>
                        </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell><Tooltip title="Open Profile Instant Messages">
                            <Typography variant="subtitle2" gutterBottom>Open Profile IM</Typography>
                        </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell><Tooltip title="Instant Message Credit">
                            <Typography variant="subtitle2" gutterBottom>IM Credit</Typography>
                        </Tooltip>
                        </DenseTableCell>
                        <DenseTableCell>Status
                        </DenseTableCell>
                        <DenseTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </ResponsiveTable>
        </TableContainer>
    );
}